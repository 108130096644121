import fetchApi from './fetch-api';

export default async function fetchUserCount() {
  try {
    const response = await fetchApi({
      method: 'GET',
      path: '/user/count'
    });

    const { count } = response.data;

    return count;
  } catch (err) {
    return 0;
  }
}
