<template>
  <div class="home-page">
    <div class="container content">
      <div class="row">
        <div class="col">
          <div class="section">
            <div class="heading">
              Recently Posted Shares
            </div>
            <div class="subheading">
              The most recently posted shares near {{ geoAddress }}
            </div>
          </div>
        </div>
      </div>
      <div class="row shares">
        <div
          v-for="share in recentShares"
          :key="share.id"
          class="col col-sm-12 col-md-6"
        >
          <a
            target="_top"
            :href="`${WEB_URL}/?share=${share.id}`"
          >
            <sma-share-card
              class="share"
              :share="share"
            />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col see-more">
          <a
            target="_top"
            class="button"
            :href="`${WEB_URL}/find`"
          >See More</a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="section">
            <div class="heading">
              Most Affordable Shares
            </div>
            <div class="subheading">
              The most affordable shares near {{ geoAddress }}
            </div>
          </div>
        </div>
      </div>
      <div class="row shares">
        <div
          v-for="share in affordableShares"
          :key="share.id"
          class="col col-sm-12 col-md-6"
        >
          <a
            target="_top"
            :href="`${WEB_URL}/?share=${share.id}`"
          >
            <sma-share-card
              class="share"
              :share="share"
            />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col see-more">
          <a
            class="button"
            target="_top"
            :href="`${WEB_URL}/find`"
          >See More</a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="section">
            <div class="heading">
              Local Flight Instructors
            </div>
            <div class="subheading">
              Instructors near {{ geoAddress }}
            </div>
          </div>
        </div>
      </div>
      <div class="row shares">
        <div
          v-for="instructor in searchedInstructors"
          :key="instructor.id"
          class="col col-sm-12 col-md-6"
        >
          <a
            target="_top"
            :href="`${WEB_URL}/instructors`"
          >
            <sma-instructor-card
              :key="instructor.id"
              :instructor="instructor"
            />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col see-more">
          <a
            class="button"
            target="_top"
            :href="`${WEB_URL}/instructors`"
          >See More</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaInstructorCard from '@/components/InstructorCard';
import SmaShareCard from '@/components/ShareCard';

import {
  FETCH_AFFORDABLE_SHARES,
  FETCH_GEO_LOCATION,
  FETCH_RECENT_SHARES,
  SEARCH_INSTRUCTORS,
  SET_GEO_LOCATION
} from '@/store/action-types';

export default {
  name: 'SmaHome',

  components: {
    SmaInstructorCard,
    SmaShareCard
  },

  data() {
    return {
      form: {
        location: '',
        lat: '',
        lng: '',
        q: ''
      },
      WEB_URL: process.env.WEB_URL
    };
  },

  computed: {
    ...mapGetters([
      'affordableShares',
      'geoLatLng',
      'geoAddress',
      'recentShares',
      'searchedInstructors'
    ])
  },

  async created() {
    const { city } = this.$route.query;
    if (city) {
      await this.$store.dispatch(SET_GEO_LOCATION, city);
    } else {
      await this.$store.dispatch(FETCH_GEO_LOCATION);
    }

    this.form.location = this.geoAddress;
    this.form.lat = this.geoLatLng.lat;
    this.form.lng = this.geoLatLng.lng;

    await Promise.all([
      this.$store.dispatch(FETCH_RECENT_SHARES, {
        address: this.geoAddress,
        limit: 4
      }),
      this.$store.dispatch(FETCH_AFFORDABLE_SHARES, {
        address: this.geoAddress,
        limit: 4
      }),
      this.$store.dispatch(SEARCH_INSTRUCTORS, {
        lat: this.geoLatLng.lat,
        lng: this.geoLatLng.lng,
        sort: 'dist',
        sortDir: 'asc',
        limit: 4,
        fresh: true
      })
    ]);
  },

  methods: {
    shareClicked(share) {

    },

    instructorClicked(instructor) {

    }
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  min-height: 100%;
  background: url("~@/assets/bg.png") 100% 75vw/100% auto repeat-y;
  z-index: 1;
}

.slider-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: -15%;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: url("~@/assets/cloud-cover.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
  }
}

.row {
  z-index: 20;
}

.background {
  width: 100%;
  height: auto;
}

.button {
  color: $sma-white;
  background-color: $sma-yellow;
  padding: 0.5rem 2rem;
  border-radius: 10rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  text-shadow: 1px 1px 2px #000;

  @media screen and (max-width: $breakpoint-sm) {
    background: rgba(0, 0, 200, 0.1);
    padding: 0 1em 1em;
  }

  .heading {
    text-align: center;
    color: $sma-white;
    font-size: 2rem;
    margin-top: 3rem;
    font-weight: 600;
  }

  .subheading {
    text-align: center;
    color: $sma-white;
    margin-bottom: 1rem;
    font-weight: 600;
  }
}

.search-input {
  max-width: 75%;
  margin: 0 auto 15rem;

  @media only screen and (max-width: $breakpoint-sm) {
    max-width: 100%;
    margin: 0 auto 0;
  }

  .input {
    width: 100%;
    background: #fff;
    border-radius: 10em;
    font-size: 1.25em;
    padding: 0.5em 1em;
  }

  .dropdown {
    color: $sma-dark-gray;
    font-size: 0.875rem;
    padding: 0.5rem;
    padding-right: 2rem;
  }

  .divider {
    margin: 0.25rem;
    width: 1px;
    background-color: $sma-light-gray;
  }

  .button {
    padding: 0.375rem 0;
    width: 100%;
    margin-top: 0.3rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 3rem;
    }
  }

  .or {
    color: $sma-white;
    padding: 0;
    margin: 0.5rem 0 0;
    text-align: center;

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 0.5rem 0 0.5rem;
    }
  }
}

.see-more {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .button {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

.box {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: $sma-white;
  border-radius: 0.25rem;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  border: 0 solid $sma-yellow;
  border-left-width: 0.25rem;
  margin: 1rem 0;
  color: $sma-dark-gray;

  .heading {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .subheading {
    display: block;
    text-align: left;
    margin: 1rem 0;
    font-size: 0.875rem;

    /* Added to fix IE 11 bug with text wrapping */
    white-space: normal;
  }

  .button {
    display: block;
    text-align: center;
    color: $sma-white;
    background-color: $sma-yellow;
    padding: 0.5rem 2rem;
    border-radius: 10rem;
    margin: 1rem auto;
    width: 50%;
  }

  &.why-us {
    border-color: $sma-blue;

    .button {
      background-color: $sma-blue;
    }
  }
}

.section {
  margin-top: 5rem;

  .heading {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .subheading {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.share {
  flex: 1;
  margin: 0.5rem 0;
}

.container {
  padding-bottom: 5rem;
}

.center {
  text-align: center;
}
</style>

<style>
.home-page .slider-pagination {
  display: none;
}

.slider-item > div {
  width: 100%;
}

/* Added to fix IE 11 wrapping issue */
.col-flex {
  flex: 1;
}
</style>
