const COLORS = {
  share: '#1d1c55',
  instructor: '#3399ce',
  user: '#f8b708'
};

const STROKE_WIDTH = 2;
const STROKE_COLOR = '#ffffff';

const PROPS = Object.keys(COLORS);

/**
 * @param {{ share?: number, instructor?: number, user?: number }} counts
 *
 * @return {string}
 */
export default function createSearchIcon(counts) {
  let numCircles = 0;

  PROPS.forEach((prop) => {
    numCircles += counts[prop] ? 1 : 0;
  });

  if (numCircles === 0) {
    return null;
  }

  let svg = '<svg viewBox="-100 -100 200 200" xmlns="http://www.w3.org/2000/svg">';

  let i = 0;
  PROPS.forEach((prop) => {
    const count = counts[prop];

    if (count === 0) {
      return;
    }

    let x = 0;
    let y = 0;
    let r = 50;

    if (numCircles > 1) {
      const angle = ((2 * Math.PI) / numCircles) * i;

      x = r * 0.9 * Math.cos(angle);
      y = r * 0.9 * Math.sin(angle);
    }

    x = Math.floor(x);
    y = Math.floor(y);
    r = Math.floor(r);

    svg += `<circle cx="${x}" cy="${y}" r="${r}" stroke="${STROKE_COLOR}" stroke-width="${STROKE_WIDTH}" fill="${COLORS[prop]}" />`;
    svg += `<text x="${x}" y="${y}" dx="${-r * 0.25}" fill="#ffffff" dy="${r * 0.35}" font-size="${r}" font-family="Roboto, Arial, sans-serif">${count}</text>`;

    i++;
  });

  svg += '</svg>';

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}
