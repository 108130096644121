<template>
  <v-select
    class="airport-select"
    :value="value"
    :options="options"
    :get-option-label="getOptionLabel"
    :placeholder="placeholder"
    @input="onInput"
    @search="onSearch"
  />
</template>

<script>
import { fetchAirports } from '@/services/api';
import debounce from '@/utils/debounce';
import VSelect from 'vue-select';

import 'vue-select/dist/vue-select.css';

export default {
  name: 'SmaAirportSelect',

  components: {
    VSelect
  },

  props: {
    value: { type: Object, default: () => null },
    placeholder: { type: String, default: undefined }
  },

  data() {
    return {
      options: []
    };
  },

  methods: {
    getOptionLabel(options) {
      return `${options.locationId} - ${options.facility}`;
    },

    onInput(value) {
      this.$emit('input', value);
    },

    onSearch: debounce(500, async function onSearch(search, loading) {
      if (search) {
        loading(true);

        let response;
        try {
          response = await fetchAirports({
            q: search,
            // limit: 5,
            simSort: true
          });
        } catch (err) {
          console.error(err);
          return;
        } finally {
          loading(false);
        }

        this.options = response.airports;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.airport-select {
  font-size: 0.875rem;
  margin: 0.5rem 0;

  ::v-deep .vs__search::placeholder {
    color: #bbb;
    font-weight: 400;
  }
}
</style>
