<template>
  <div class="seeking-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Tips For Seeking</h1>
        </div>
        <div class="col col-sm-18">
          <p>To maximize your search results when looking for a share, enter broad keywords into the search box. Looking for a Cessna 172SP? Make sure to also enter “Skyhawk” when conducting your search. In other words, always search with model number AND aircraft name.</p>
          <p>Use the Aircraft Type checkboxes to specify exactly the type aircraft you’re looking for. Looking for a multi-engine tailwheel like a Beech 18? Good luck with that, but check those boxes and see what comes up.</p>
          <p>In your search results, make sure to check user comments and watch any videos to get a better read on the plane and the owner. (COMING SOON)</p>
          <p>And if your search yields no results, fill out a Share Request and you’ll be notified if and when an aircraft matching your search becomes available. (COMING SOON!)</p>
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaTipsForSeeking',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
</style>
