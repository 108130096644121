import fetchApi from './fetch-api';
import login from './login';

export default async function createUserAccount(json) {
  /*
  await fetchApi({
    method: 'POST',
    path: '/user',
    json,
  });

  return login(json);
  */
  const data = new FormData();
  const { avatar } = json;

  data.append('user', JSON.stringify(json));

  if (avatar) {
    data.append('avatar', avatar.file);
  }

  await fetchApi({
    method: 'POST',
    path: '/user',
    data
  });

  return login({
    username: json.username,
    password: json.password
  });
}
