import fetch from './fetch-api';

export default async function fetchCounts() {
  const response = await fetch({
    method: 'GET',
    path: '/search/count'
  });

  return response.data;
}
