import {
  CREDENTIALS_INVALID,
  ERRORS_CLEARED,
  LOCATION_CHANGED,
  MODAL_HIDDEN,
  USER_CREATED,
  USER_ALREADY_EXISTS
} from '../mutation-types';

export default function createErrorsModule() {
  return {
    state: () => ({
      stack: []
    }),

    getters: {
      error: state => state.stack[0]
    },

    mutations: {
      [CREDENTIALS_INVALID]: (state) => {
        state.stack.unshift('This username or password is not valid');
      },

      [USER_ALREADY_EXISTS]: (state) => {
        state.stack.unshift('This username is already taken');
      },

      [ERRORS_CLEARED]: (state) => {
        state.stack = [];
      },

      [LOCATION_CHANGED]: (state) => {
        state.stack = [];
      },

      [MODAL_HIDDEN]: (state) => {
        state.stack = [];
      },

      [USER_CREATED]: (state) => {
        state.stack = [];
      }
    }
  };
}

export const state = {
  stack: []
};

export const getters = {
  error: state => state.stack[0]
};

export const mutations = {
  [CREDENTIALS_INVALID]: (state) => {
    state.stack.unshift('This username or password is not valid');
  },

  [USER_ALREADY_EXISTS]: (state) => {
    state.stack.unshift('This username is already taken');
  },

  [ERRORS_CLEARED]: (state) => {
    state.stack = [];
  },

  [LOCATION_CHANGED]: (state) => {
    state.stack = [];
  },

  [MODAL_HIDDEN]: (state) => {
    state.stack = [];
  },

  [USER_CREATED]: (state) => {
    state.stack = [];
  }
};
