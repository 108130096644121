import { UPDATE_HEADER_HEIGHT } from '../action-types';
import { HEADER_HEIGHT_UPDATED } from '../mutation-types';

export default function createHeaderModule() {
  return {
    state: () => ({
      headerHeight: '0'
    }),

    getters: {
      headerHeight: state => state.headerHeight
    },

    mutations: {
      [HEADER_HEIGHT_UPDATED]: (state, height) => {
        if (height !== state.headerHeight) {
          state.headerHeight = height;
        }
      }
    },

    actions: {
      [UPDATE_HEADER_HEIGHT]: (context, height) => {
        context.commit(HEADER_HEIGHT_UPDATED, height);
      }
    }
  };
}
