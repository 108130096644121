import { toShare } from '@/utils/to-models';

import fetchApi from './fetch-api';

export default async function fetchShares(options) {
  const response = await fetchApi({
    method: 'POST',
    path: `/share/${options.share.id}/enable`,
    query: {}
  });

  return {
    query: {},
    share: toShare(response.data)
  };
}
