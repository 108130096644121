import fetchApi from './fetch-api';

function fetchApiMock() {
  return {
    data: [
      {
        id: 15292,
        lat: 42.3629,
        long: -71.0064,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'GENERAL EDWARD LAWRENCE LOGAN INTL',
        owner: 'MASS PORT AUTHORITY',
        locationId: 'BOS'
      },
      {
        id: 1989,
        lat: 36.9726,
        long: -84.6261,
        state: 'KENTUCKY',
        city: 'BURNSIDE',
        facility: 'BOSS',
        owner: 'CUMBERLAND AERO SERVICE INC',
        locationId: '08KY'
      },
      {
        id: 7639,
        lat: 42.3637,
        long: -71.0692,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'MASSACHUSETTS GENERAL HOSPITAL',
        owner: 'GENERAL HOSPITAL CORPORATION',
        locationId: '0MA1'
      },
      {
        id: 15293,
        lat: 42.3629,
        long: -71.0064,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'GENERAL EDWARD LAWRENCE LOGAN INTL',
        owner: 'MASS PORT AUTHORITY',
        locationId: 'BOS'
      },
      {
        id: 1990,
        lat: 36.9726,
        long: -84.6261,
        state: 'KENTUCKY',
        city: 'BURNSIDE',
        facility: 'BOSS',
        owner: 'CUMBERLAND AERO SERVICE INC',
        locationId: '08KY'
      },
      {
        id: 7630,
        lat: 42.3637,
        long: -71.0692,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'MASSACHUSETTS GENERAL HOSPITAL',
        owner: 'GENERAL HOSPITAL CORPORATION',
        locationId: '0MA1'
      },
      {
        id: 15242,
        lat: 42.3629,
        long: -71.0064,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'GENERAL EDWARD LAWRENCE LOGAN INTL',
        owner: 'MASS PORT AUTHORITY',
        locationId: 'BOS'
      },
      {
        id: 1589,
        lat: 36.9726,
        long: -84.6261,
        state: 'KENTUCKY',
        city: 'BURNSIDE',
        facility: 'BOSS',
        owner: 'CUMBERLAND AERO SERVICE INC',
        locationId: '08KY'
      },
      {
        id: 75639,
        lat: 42.3637,
        long: -71.0692,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'MASSACHUSETTS GENERAL HOSPITAL',
        owner: 'GENERAL HOSPITAL CORPORATION',
        locationId: '0MA1'
      },
      {
        id: 1592,
        lat: 42.3629,
        long: -71.0064,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'GENERAL EDWARD LAWRENCE LOGAN INTL',
        owner: 'MASS PORT AUTHORITY',
        locationId: 'BOS'
      },
      {
        id: 19089,
        lat: 36.9726,
        long: -84.6261,
        state: 'KENTUCKY',
        city: 'BURNSIDE',
        facility: 'BOSS',
        owner: 'CUMBERLAND AERO SERVICE INC',
        locationId: '08KY'
      },
      {
        id: 76039,
        lat: 42.3637,
        long: -71.0692,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'MASSACHUSETTS GENERAL HOSPITAL',
        owner: 'GENERAL HOSPITAL CORPORATION',
        locationId: '0MA1'
      },
      {
        id: 5292,
        lat: 42.3629,
        long: -71.0064,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'GENERAL EDWARD LAWRENCE LOGAN INTL',
        owner: 'MASS PORT AUTHORITY',
        locationId: 'BOS'
      },
      {
        id: 19894,
        lat: 36.9726,
        long: -84.6261,
        state: 'KENTUCKY',
        city: 'BURNSIDE',
        facility: 'BOSS',
        owner: 'CUMBERLAND AERO SERVICE INC',
        locationId: '08KY'
      },
      {
        id: 76239,
        lat: 42.3637,
        long: -71.0692,
        state: 'MASSACHUSETTS',
        city: 'BOSTON',
        facility: 'MASSACHUSETTS GENERAL HOSPITAL',
        owner: 'GENERAL HOSPITAL CORPORATION',
        locationId: '0MA1'
      }
    ]
  };
}

export default async function fetchAirports(options) {
  const fetch = process.env.API_URL ? fetchApi : fetchApiMock;
  const query = { ...options };

  const response = await fetch({
    method: 'GET',
    path: '/airport',
    query
  });

  return {
    ...query,
    airports: response.data
  };
}
