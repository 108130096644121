<template>
  <div class="policy-page info-page">
    <div class="container">
      <h1>Privacy Policy</h1>
      <br>
      <h2>Personal Information</h2>
      <h3>Browsing Sharemyaircraft.com</h3>
      <p>You can View Listings on Sharemyaircraft.com without having a Sharemyaircraft.com Account. You also can contact us through the Sharemyaircraft.com Help Center or by emailing us directly without having to register for an account.</p>
      <h3>Your Sharemyaircraft.com Account</h3>
      <p>For some activities on Sharemyaircraft.com, like uploading listings, posting comments, flagging video/photos, or watching restricted video/photos or photos, you need a Sharemyaircraft.com Account. We ask for some personal information when you create an account, including your email address and a password, which is used to protect your account from unauthorized access.</p>
      <h3>Usage Information</h3>
      <p>When you use Sharemyaircraft.com, we may record information about your usage of the site, such as the frequency and size of data transfers, and information you display about yourself as well as information you click on in Sharemyaircraft.com (including UI elements, settings). If you are logged in, we may associate that information with your Sharemyaircraft.com Account. In order to ensure the quality of our service to you, we may place a tag (also called a "web beacon") in HTML-based customer support emails or other communications with you in order to confirm delivery.</p>
      <h3>Request to Delete Data</h3>
      <p>A user of Sharemyaircraft.com may request their data to be deleted by multiple methods. They can simply email us at <a href="mailto:support@sharemyaircaft.com">support@sharemyaircaft.com</a>. They can also call us at <a href="tel:650-336-5837">650-336-5837</a>. In addition, they can delete their account, which will delete all associated data.</p>
      <h2>Content Uploaded to Site</h2>
      <p>Any personal information or video/photo content that you voluntarily disclose online (e.g., your profile page) may be collected and used by others. If you download the Sharemyaircraft.com Uploader, your copy includes a unique application number. This number, and information about your installation of the Uploader (version number, language) will be sent to Sharemyaircraft.com when the Uploader automatically checks for updates and will be used to update your version of the Uploader.</p>
      <br>
      <h2>Uses</h2>
      <p>If you submit personal information to Sharemyaircraft.com, we may use that information to operate, maintain, and improve the features and functionality of Sharemyaircraft.com, and to process any flagging activity or other communication you send to us.</p>
      <p>We do not use your email address or other personal information to send commercial or marketing messages without your consent. We may use your email address without further consent for non-marketing or administrative purposes (such as notifying you of major Sharemyaircraft.com changes or for customer service purposes). You also can choose how often Sharemyaircraft.com sends you email updates in your Sharemyaircraft.com Account settings page.</p>
      <p>We use cookies, web beacons, and log file information to: (a) store information so that you will not have to re-enter it during your visit or the next time you visit Sharemyaircraft.com; (b) provide custom, personalized content and information; (c) monitor the effectiveness of our marketing campaigns; (d) monitor aggregate metrics such as total number of visitors and pages viewed; and (e) track your entries, submissions, and status in promotions, sweepstakes, and contests.</p>
      <br>
      <h2>Publicly Available Information</h2>
      <p>When you create a Sharemyaircraft.com Account, some information about your Sharemyaircraft.com Account and your account activity will be provided to other users of Sharemyaircraft.com. This may include the date you opened your Sharemyaircraft.com Account, the date you last logged into your Sharemyaircraft.com Account, your age (if you choose to make it public), the country and the number of video/photo you have watched.</p>
      <p>Your Sharemyaircraft.com Account name, not your email address, is displayed to other users when you engage in certain activities on Sharemyaircraft.com, such as when you upload listings or send messages through Sharemyaircraft.com. Other users can contact you by leaving a message or comment on the site.</p>
      <p>Any video/photos that you submit to Sharemyaircraft.com may be redistributed through the Internet and other media channels, and may be viewed by other Sharemyaircraft.com users or the general public.</p>
      <p>You may also choose to add personal information, which may include your name, gender, profile picture or other details, that will be visible to other users on your Sharemyaircraft.com user page. If you choose to add certain features to your Sharemyaircraft.com Account page, then these features and your activity associated with these features will be displayed to other users and may be aggregated and shared with your friends or other users. Such shared activity may include your favorite video/photos, video/photos you rated and video/photos that you have uploaded.</p>
      <br>
      <h2>Your Choices</h2>
      <p>If you have a Sharemyaircraft.com Account, you may update or correct your personal profile information, email preferences and privacy settings at any time by visiting your account profile page.</p>
      <p>You may control the information that is available to other users and your confirmed friends at any time by editing your Sharemyaircraft.com Account and the features that are included on your page.</p>
      <p>You may, of course, decline to submit personal information through Sharemyaircraft.com, in which case you can still view video/photos and explore Sharemyaircraft.com, but Sharemyaircraft.com may not be able to provide certain services to you. The privacy notices of those services govern the use of your personal information associated with them.</p>
      <br>
      <h2>Advertising on Sharemyaircraft.com</h2>
      <p>Sharemyaircraft.com strives to provide you with relevant and useful advertising and we use third party ad serving systems, to do so. We may use a range of information including cookies, web beacons, IP addresses, usage data and other non-personal information about your computer or device (such as browser type and operating system) to provide you with relevant advertising. If you are logged into your Sharemyaircraft.com Account, we may also show you advertising based on the information you have provided to us in your Sharemyaircraft.com Account.</p>
      <p>We may use cookies on the Sharemyaircraft.com to help advertisers and publishers serve and manage ads on this site and other partner websites. Advertisers may serve ads based on interests associated with non-personally identifiable online activity, such as video/photos viewed, frequency of uploading or activity on other partner sites. Advertisers may also serve ads to you based on previous activity on that advertiser's website. This non-personally identifiable information is held separately from your Sharemyaircraft.com Account data, or other personally identifying information in our possession.</p>
      <p>Other third-party ad serving companies may display ads on Sharemyaircraft.com. Sharemyaircraft.com does not provide any personal information to these third-party ad servers or ad networks without your consent. You should consult the respective privacy policies of these third-party ad servers or ad networks for more information on their practices and for instructions on how to opt-out of certain practices. Sharemyaircraft.com's privacy policy does not apply to, and we cannot control the activities of, such other advertisers or web sites. Any data obtained by third-party ad servers subsequently shared with Sharemyaircraft.com is maintained and dealt with by Sharemyaircraft.com in accordance with this privacy policy.</p>
      <br>
      <h2>Privacy Guidelines</h2>
      <p>Sharemyaircraft.com is serious about protecting our users by addressing potential privacy concerns. Our privacy guidelines apply to all users across the world. In other words, while the video/photo in question may not violate your country's privacy laws, it may still violate Sharemyaircraft.com's privacy guidelines.</p>
      <h2>How does Sharemyaircraft.com determine if content should be removed for a privacy violation?</h2>
      <p>For content to be considered for removal, an individual must be uniquely identifiable by image, voice, full name, Social Security number, bank account number or contact information (e.g. home address, email address). Examples that would not violate our privacy guidelines include gamer tags, avatar names, and address information in which the individual is not named. We also take public interest, newsworthiness, and consent into account when determining if content should be removed for a privacy violation. Sharemyaircraft.com reserves the right to make the final determination of whether a violation of its privacy guidelines has occurred.</p>
      <h2>What does uniquely identifiable mean?</h2>
      <p>To be considered uniquely identifiable, there must be enough information in the video/photo that allows others to recognize you. Please note that just because you can identify yourself within the video/photo, it does not mean you are uniquely identifiable to others. A first name without additional context or a fleeting image, for example, would not likely qualify as uniquely identifiable.</p>
      <h2>How Sharemyaircraft.com's privacy process works</h2>
      <p>If a privacy complaint is filed, Sharemyaircraft.com provides the uploader an opportunity to remove or edit the private information within their video/photo. We issue notification of the potential violation and provide the uploader 48 hours to take action on the complaint. If the uploader removes the video/photo during these 48 hours, the complaint filed will be closed. If the potential privacy violation remains on the site after 48 hours, the Sharemyaircraft.com Team will then review the complaint.</p>
      <h2>Reporting a privacy violation</h2>
      <p>To file file a privacy complaint, please email us at <a href="mailto:neil@sharemyaircraft.com">Neil@sharemyaircraft.com</a> As the complainant, your privacy is respected in this process. At no point will your identity or contact information be released to the uploader without your consent. Our communications to you about this process will be sent to your registered email address.</p>
      <h2>First-party claims required</h2>
      <p>We do not accept claims on behalf of third parties except in the following situations:</p>
      <ol>
        <li>
          <p>the individual whose privacy is being violated does not have access to a computer</p>
        </li>
        <li>
          <p>you are the parent or legal guardian of the individual whose privacy is being violated</p>
        </li>
        <li>
          <p>you act as a legal representative for the individual whose privacy is being violated</p>
        </li>
        <li>
          <p>you are a family member of someone who was featured in a video/photo but has since passed away</p>
        </li>
      </ol>
      <p>We will not accept privacy complaints filed on behalf of:</p>
      <ul>
        <li>
          <p>other family members (e.g., husband, wife, cousin, brother, sister)</p>
        </li>
        <li>
          <p>co-workers or employees (individuals must report themselves)</p>
        </li>
        <li>
          <p>a company</p>
        </li>
      </ul>
      <h2>Tips on filing a complete privacy complaint</h2>
      <p>Please be clear and concise so that the Sharemyaircraft.com Team can identify you within the video/photo.</p>
      <p>Use the time stamp to indicate only one or two places where you clearly appear in the video/photo.</p>
      <p>In the description area, please specify what you are wearing or doing that differentiates you from others within the video/photo.</p>
      <p>Make sure you've included the video/photo URL in your report. You are not required to submit a URL if you are reporting an entire channel.</p>
      <p>If you are reporting a comment made in the comments section of a video/photo, please note this in the description area. Include the commenter's username in the username field.</p>
      <h2>Receiving notice of a privacy violation</h2>
      <p>Sharemyaircraft.com provides you, the uploader, an opportunity to remove or edit the private information within your video/photo. We email you about the potential violation and allow you 48 hours to take action on the complaint. If you remove the alleged violation from the site within the 48 hours, the complaint filed will then be closed. If the potential privacy violation remains on the site after 48 hours, the complaint will be reviewed by the Sharemyaircraft.com Team.</p>
      <p>If we remove your video/photo for a privacy violation, please do not upload another version featuring the same people. These individuals will likely file another privacy complaint or report you for harassment. We are serious about protecting our users and suspend accounts that violate people's privacy.</p>
      <br>
      <h2>How can I address the complaint?</h2>
      <p>You can remove the reported content completely from the site.</p>
      <p>If someone's full name or other personal information is listed within the title, description, or tags of your video/photo, you can edit this by information.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmaPolicy'
};
</script>

<style lang="scss" scoped>
</style>
