<template>
  <div class="insurance-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Insurance</h1>
        </div>
        <div class="col col-sm-18">
          <h2>We know what you’re thinking… How does this affect my insurance?</h2>
          <p>First, don’t let any preconceptions about insurance red tape or difficulty make you a skeptic about how easy and economical it really is to share an aircraft. Indeed, it may not even cost a penny more to insure your aircraft for up to 3 additional pilots, provided those pilots meet a certain minimum experience requirement. </p>
          <p>Another key is to also make sure your share doesn’t look like an aircraft club. That’s why we recommend not sharing with more than three other pilots, for a total of four named pilots’ maximum for your aircraft.  Most insurance companies like AVEMCO will not even change your premium and are used to this type of structure!  Call you existing company as this type of structure and insurance is done all the time!</p>

          <h2>What is the price difference for single pilot vs. multiple?</h2>
          <p>The rating for an aircraft policy is based on the airplane and the lowest-time, lowest-rated pilot, so it all depends on the pilots being added and the particular type aircraft to determine whether or not there are any charges to add pilots to the policy. </p>
          <p>For example, to get the best price on a policy insuring a Cirrus SR22, all pilots must be Instrument Rated, over 500 hours total time, with at least 100 hours in make and model. If the Cirrus owner were to add a low time pilot without an Instrument Rating, it would cost more to add that pilot, and additional training may be required.</p>
          <p>If in the above example the aircraft were a Cessna 172 instead of an SR22, the additional cost would be relatively minimal since the Cessna 172 is less complex.</p>

          <h2>If I paid my policy in advance through the end of the year, or through some other extended period, and I change insurance companies to get a new policy to share my aircraft, will I get a refund from my existing company? </h2>
          <p>Yes, all aviation policies allow you to cancel anytime you wish. Companies will typically calculate a refund short-rate, which means you’ll pay a small penalty for canceling your insurance early. The amount of this penalty is typically weighted more heavily at the beginning of the policy and less at the end. For instance, if your premium is $3,000 annually with a certain carrier and you cancel at 6 months, you would get back about $1,170 short-rate instead of the $1,500 you could expect if you were to pro-rate the difference. Terms will vary among the carriers and is detailed in every policy.</p>
          <p>Ultimately, choose the pilots you share with wisely. Find pilots with similar or higher experience levels, or that meet minimum levels per insurance company requirements, and you should realize a nearly negligible change to your insurance premiums.</p>

          <h2>Do you recommend any particular insurance companies?</h2>
          <p>You should first speak to your existing insurance company, as this type of structure and insurance is done all the time.   If they have any issues, there are many companies out there that will issue insurance for multiple pilots flying one plane.</p>
          <p>AVEMCO, is one of the largest general aviation insurance providers, and as mentioned above, under the right conditions they will not even raise your policy to add up to 4 additional pilots. 888-241-7891.</p>

          <h2>Do you recommend any particular insurance companies?</h2>
          <p>You should first speak to your existing insurance company, as this type of structure and insurance is done all the time. If they have any issues, there are many companies out there that will issue insurance for multiple pilots flying one plane.  AVEMCO, is one of the largest general aviation direct insurance providers, and as mentioned above, under the right conditions they will not even raise your policy to add up to 4 additional pilots. <a href="tel:888-241-7891">888-241-7891</a>.</p>
          <p>
            We also work with <a
              href="https://wingsinsurance.com"
              target="_blank"
            >WingsInsurance.com</a>, and specifically Ryan Konrath at <a href="tel:952-641-3152">952-641-3152</a>.  Ryan is a Cirrus preferred Insurance broker and has the ability to work with many insurance shops finding you the best policy for your new or used plane purchase!  Ryan can be reached at <a href="mailto:rkonrath@wingsinsurance.com">rkonrath@wingsinsurance.com</a>.  To expedite the process, and we can help, you should know the following:
          </p>
          <p>
            <ul>
              <li>How will the aircraft be registered with the FAA (business, LLC or personal, name and address of above</li>
              <li>N-Number</li>
              <li>Requested Insured Value</li>
              <li>What airport will the aircraft be based at</li>
              <li>Will the aircraft be hangered or tied out</li>
              <li>
                Please complete an online pilot form – <a
                  href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=6eefdfca-a5e4-41c0-91d4-f0ac5e600dca&env=na3-eu1&acct=c4824d82-45e4-4a0e-a36f-9409e5d51673&ActivateOnly=1"
                  target="_blank"
                >Click Here</a>
              </li>
              <ul>
                <li>When prompted enter your name &amp; email address</li>
              </ul>
            </ul>
          </p>
          <p>A separate email with a link will be emailed to you to complete the pilot form.</p>
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaInsurance',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
</style>
