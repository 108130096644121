<template>
  <div class="videos-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Videos</h1>
        </div>
        <div class="row">
          <div class="col col-sm-12">
            <div class="videos">
              <a
                class="video"
                @click="video(videoMain)"
              >
                <span />
                <img
                  src="https://i3.ytimg.com/vi/qa5iI1f_e3M/maxresdefault.jpg"
                >
              </a>
            </div>
          </div>
          <div class="col col-sm-12">
            <p>This video will give you a fairly quick overview of the site sharemyaircraft.com and all the features packed into the first release.  Our goal is to get more plane and pilots flying by tackling the huge inefficiency in general aviation aircraft utilization, where the average pilot flies 3.3 hours a month with his or her plane sitting on the ground 96% of the time. There are very few planes that would not benefit by being shared with another like-minded, highly-qualified pilot, and very few pilots that could not use the additional income to cover expenses like hanger fees, subscription fees, and that dreaded annual inspection, which comes once a year whether you fly 1 hour, 100 hours, or 1000 hours!  Sharemyaircraft.com can help!</p>
          </div>
        </div>
        <div class="row">
          <div class="col col-sm-8">
            <div class="videos">
              <a
                class="video"
                @click="video(video1)"
              >
                <span />
                <img
                  src="https://i3.ytimg.com/vi/qa5iI1f_e3M/maxresdefault.jpg"
                >
              </a>
            </div>
            <div class="videos">
              <a
                class="video"
                @click="video(video4)"
              >
                <span />
                <img
                  src="https://i3.ytimg.com/vi/fuj520p6Ni0/maxresdefault.jpg"
                >
              </a>
            </div>
          </div>
          <div class="col col-sm-8">
            <div class="videos">
              <a
                class="video"
                @click="video(video2)"
              >
                <span />
                <img
                  src="https://i3.ytimg.com/vi/qpChotqZGyI/maxresdefault.jpg"
                >
              </a>
            </div>
            <div class="videos">
              <a
                class="video"
                @click="video(video5)"
              >
                <span />
                <img
                  src="https://i3.ytimg.com/vi/QDcNF2uxRg0/maxresdefault.jpg"
                >
              </a>
            </div>
          </div>
          <div class="col col-sm-8">
            <div class="videos">
              <a
                class="video"
                @click="video(video3)"
              >
                <span />
                <img
                  src="https://i3.ytimg.com/vi/IOP0HoROFG4/maxresdefault.jpg"
                >
              </a>
            </div>
            <div class="videos">
              <a
                class="video"
                @click="video(video6)"
              >
                <span />
                <img
                  src="https://i3.ytimg.com/vi/_ENy1eU21qc/maxresdefault.jpg"
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHOW_MODAL } from '@/store/action-types';

export default {
  name: 'SmaVideo',

  components: {

  },

  data() {
    return {
      video1: 'https://www.youtube.com/embed/qa5iI1f_e3M?autoplay=1',
      video2: 'https://www.youtube.com/embed/qpChotqZGyI?autoplay=1',
      video3: 'https://www.youtube.com/embed/IOP0HoROFG4?autoplay=1',
      video4: 'https://www.youtube.com/embed/fuj520p6Ni0?autoplay=1',
      video5: 'https://www.youtube.com/embed/QDcNF2uxRg0?autoplay=1',
      video6: 'https://www.youtube.com/embed/_ENy1eU21qc?autoplay=1',
      videoMain: 'https://www.youtube.com/embed/videoseries?list=PL87aKvU7yP805gFRaUixExrepBwOVr7oE&autoplay=1'
    };
  },

  methods: {
    video(btnId) {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'video',
        videoURL: btnId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.shrink {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 1.2rem;
  margin-right: 1rem;
  cursor: pointer;
}

.videos img {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

a.video {
  float: left;
  position: relative;
}

a.video span {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(https://img.icons8.com/color/480/000000/youtube-play.png) no-repeat;
  background-position: 50% 38%;
  background-size: 20%;
}

@media screen and (max-width: 480px) {
  a.video span {
    background-size: 20%;
  }
}
</style>
