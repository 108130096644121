<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="help-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>FAQ's</h1>
        </div>
        <div class="col col-sm-18">
          <div
            v-for="(item, i) in questions"
            :key="i"
          >
            <div class="question">
              <div class="q">
                Q: {{ item.q }}
              </div>
              <div
                class="a"
                v-html="`A: ${item.a}`"
              />
            </div>
          </div>
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaHelp',

  components: {
    SmaSidebar
  },

  data() {
    return {
      questions: [
        {
          q: 'How much does it cost to use?',
          a: 'To really help grow General Aviation by reducing the costs associated with flying, we have decided to make it free for all. List your plane, add yourself as an instructor and use the site for free! Just sign up!'
        },
        {
          q: 'Do I have to be a pilot to Share an aircraft?',
          a: 'Short answer, yes. SMA connects like-minded pilots who are selling time in their own aircraft with pilots seeking to purchase access to a quality airplane at an affordable price. SMA is a great way to earn extra money when your aircraft would normally be on the ground, to cover the fixed expenses of aircraft ownership!'
        },
        {
          q: 'Is this like Fractional Ownership?',
          a: 'It can be, depending on the type of “SHARE.” If you are simply renting hours from an owner, then the closest description is you are renting an aircraft from a private owner and up to 3 others will all be listed on the insurance policy of the aircraft and will have a key and access to one specific aircraft. Ifyou are in an equity partnership (Co-ownership) with others, you will own the plane and share all the expenses equally, thereby eliminating all the negatives of single-pilot ownership and reducing your cost of flying all around!! In both cases, you will all know when each other are flying, and will worktogether to create an enjoyable experience for all the "Sharers." This is done quite frequently;however,it is hard to meet and find the other like-minded pilotsand implement all the right paperwork that makes everyone feel comfortable. This is where SMA comes in -IT JUST MAKES SENSE!'
        },
        {
          q: 'As an Owner, how easy is it to Implement?',
          a: 'Sharing is done all the time. The hardest part is finding other like-minded pilotsand putting in place the paperwork that makes everyone feel comfortable. This is where we shine. By making it easy to find or list a plane and specifying exactly who you would like to flyyour plane (hours, ratings etc.) you will find the perfect sharerat your local airport! Then we can help you with vetted “renting” or “co-ownership” documents which will make all parties feel comfortable! We created SMA because we share our own planes andknow the “ins and outs”of making it work! '
        },
        {
          q: 'I’m a new pilot.Can I share an aircraft?',
          a: 'Yes, new pilots can also share aircraft. The types of aircraft a new pilot can expect to fly is normally limited to simple, single engine piston aircraft like Cessna Skyhawks, Piper Cherokees, and other primary aircraft. To jump into higher-performing aircraft, pilots will have to satisfy more stringent owner and insurance prerequisites. Typically, to qualify for a Cirrus SR22, Cessna Corvallis, or other technically advanced, high performance, or multi-engine aircraft, a pilot must have logged a minimum number of hours, time-in-type, or have a minimum number of hours total time and go through transition training. And usually, the pilot must also have an instrument rating.'
        },
        {
          q: 'What types of aircraft are available on SMA?',
          a: 'SMA places no limits on aircraft types listed on the site. And on SMA, you’ll often find shares available in aircraft that one would almost never see on a rental flight line. That’s one of the beauties of sharing, the potential for access to unique and unusual aircraft. However, SMA is dedicated to helping as many pilots as possible get into the left seat of the aircraft of their dreams –whether that aircraft is a Cessna 172 or 182, a Cirrus SR22, or a Robinson R44. And if you’re searching for a certain type aircraft and don’t see it on our site, Set up a listing alert at your local airport and you will get an email when one is listed.'
        },
        {
          q: 'Can I share more than one airplane at once?',
          a: 'Of course! That is a beauty of SMA, you can fly many types and models of planes and open up your flying world!'
        },
        {
          q: 'Does SMA help work out the deal between owner and sharer?',
          a: 'No. SMA accepts no responsibility for the terms of any share. It’s completely up to aircraft owners to properly screen prospective share candidates for competency and if they meet insurance requirements. We can however provide you will the right documents to put in place, so all parties feel comfortable and the partnership works! –Why, because we do it ourselves!'
        },
        {
          q: 'How do I decide on the price/hour dry?',
          a: 'Price your share fairly and competitively. Use theHourly Rate Calculatorto tally your costs as a guideline for setting your price. Another benchmark would be the rate an FBO charges when renting a similar plane. You should be able to set your price 10-15% lower than the FBO or school since you won’t need a commercial insurance policy and through other savings. At the end of the day, it’s really about what you want to get for your plane/hour dry.'
        },
        {
          q: 'Does SMA broker shares in aircraft?',
          a: 'No, SMA only acts as a catalyst between aircraft owners who are willing to Share their aircraft, and pilots seeking to Share an aircraft. WE CONNECT LIKE-MINDED PILOTS!'
        },
        {
          q: 'Is sharing an aircraft less expensive than renting?',
          a: 'Yes. Typical share rates are 15-20% more economical than renting aircraft from your local FBO. This is primarily due to the heavy insurance required to allow "anyone" to rent a plane, and the fact that as a commercial operation, an FBO has additional maintenance requirements and costs. In addition,Sharing resembles ownership and provides for a host of other benefits over renting.'
        },
        {
          q: 'How is my insurance affected if I want to share my aircraft?',
          a: 'Insurance companies write policies for sharing all the time. It’s much easier than you might think, and it might not even affect your premiums.Insurance companies call it a “Limited Renter’s Policy”Click hereto go to our section on insurance.'
        },
        {
          q: 'Does SMA keep my personal information private?',
          a: 'SMA does not sell, share, or otherwise distribute your personal information to any third party without your consent.'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.question {
  margin-bottom: 24px;
}

.q {
  font-weight: 600;
  font-size: 1.2em;
  color: $sma-blue;
}
</style>
