<template>
  <div class="manage-favorite-airports-page">
    <div class="container">
      <div class="row">
        <sma-account-sidebar class="col col-sm-5" />
        <div class="col-sm-19">
          <div class="col center">
            <h2>New Listing Alerts</h2>
          </div>
          <div class="col center">
            Add airports below if you would like to receive an email notification when a new aircraft becomes available. If you no longer wish to receive alerts, remove airports from the list below.
          </div>
          <div class="col">
            <sma-airport-select
              v-model="airport"
              placeholder="Enter airport code or name (NO K required)"
              @input="addAirport"
            />
          </div>
          <div class="col airports">
            <div
              v-for="favAirport in me.favoriteAirports"
              :key="favAirport.id"
              class="airport"
            >
              <span class="text">{{ `${favAirport.locationId} - ${favAirport.facility}` }}</span>
              <div
                class="x"
                @click="removeAirport(favAirport)"
              >
                <fa-icon icon="times" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaAccountSidebar from '@/components/AccountSidebar';
import SmaAirportSelect from '@/components/AirportSelect';
import { FETCH_USER_PROFILE } from '@/store/action-types';

import {
  addFavoriteAirport,
  removeFavoriteAirport
} from '@/services/api';

export default {
  name: 'SmaManageFavoriteAirports',

  components: {
    SmaAccountSidebar,
    SmaAirportSelect
  },

  data() {
    return {
      airport: null
    };
  },

  computed: {
    ...mapGetters([
      'me'
    ])
  },

  methods: {
    async addAirport() {
      await addFavoriteAirport(this.airport.id);
      this.airport = null;
      await this.$store.dispatch(FETCH_USER_PROFILE);
    },

    async removeAirport(airport) {
      await removeFavoriteAirport(airport.id);
      await this.$store.dispatch(FETCH_USER_PROFILE);
    },

    closeOkayDialog() {
      this.showOkayDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 7rem;
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
}

.airports {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-left: -1rem;

  .airport {
    display: flex;
    align-items: center;
    border: 1px solid black;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 0.5rem;
    margin-left: 1rem;

    .text {
      align-content: center;
      font-size: 0.7rem;
      margin: 0 0.5rem;
      user-select: none;
    }

    .x {
      color: $sma-white;
      background: $sma-yellow;
      font-size: 1rem;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-button {
  background-color: $sma-red;
  margin-left: 2em;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}
</style>
