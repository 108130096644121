<template>
  <div class="howitworks-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>How It Works</h1>
        </div>
        <div class="col col-sm-18">
          <p>Thank you for coming to SHAREMYAIRCRAFT.COM (SMA)!  We are excited to help you share your aircraft and make valuable money OR to find your perfect share to fly, saving you valuable money!</p>
          <p>Whether you want to share an aircraft for pleasure or business, you’ll quickly learn that partnership in an aircraft is often the most efficient and economical path to the left seat of the aircraft YOU want to fly. No matter the mission, Share My Aircraft will link you with the owner of the aircraft that best fits the type of flying you want to do.</p>

          <p>Are you seeking a share of a small tailwheel aircraft like a Decathlon to tool around in on weekends? Or are you searching for a Piper Matrix available in the local area that will speed you to business meetings in a tri-state area? Or maybe you’re looking for both. This is the benefit of sharing. Pilots can get into more types of aircraft, to suit different types of flying, for less. Indeed, by sharing, you’ll do more flying, for less money, than you ever have before.</p>

          <p>And if you’re looking to share your aircraft with other like-minded pilots, SMA is the best resource to the largest pool of qualified pilots seeking an aircraft to share. We have designed an amazing site with a great user experience (UX), to help you easily post your share, find your share, or find a great flight instructor in your area, or anywhere in the USA.</p>
          <p>You can seamlessly use your desktop, smart phone, or tablet to navigate the site, and best of all, we know exactly where you are, so we can bring up the shares closest to your location first! Then you have the ability to filter shares and instructors, by make, model, location, hourly rate or any other criteria! And, by simply putting in your tail number when posting, we can pre-fill pertinent information for you, making it even easier to get started.</p>
          <p>Finally, after a share is posted, you will receive an email notification from an interested pilot, or a call if you desire, and you have the ability to activate and deactivate a share on the fly!</p>
          <p>
            <router-link
              class="link button button-blue"
              exact
              active-class="active"
              :to=" { name: 'post' }"
            >
              LET’S GET STARTED!
            </router-link>
          </p>
          <h2>Listing A Share</h2>
          <p>Listing a share is simple and easy once you register on the site through social media (Facebook) or email and password.</p>
          <ol>
            <li>Fill in your tail number and click search. VOILA, many fields will automatically propagate!</li>
            <li>Add as much or as little information as you would like!</li>
            <li>Choose your airport!</li>
            <li>Add great pictures, click submit and you are done!</li>
          </ol>
          <h2>Searching For A Share</h2>
          <p>You can easily search for a share in your local area (or any area). SMA will know where you are, so simply click SEARCH to see all the planes at an airport displayed on the google map.</p>
          <ol>
            <li>You can then expand or zoom-in on a tighter radius to see the closest planes, or you can filter the list by many options.</li>
            <li>ALSO, if you are simply looking for a specific plane, type it in the search field. For example, a CIRRUS, then click search. Only Cirrus’s will appear in the list!</li>
            <li>You can do broad searches for example: TAIL WHEEL, or ACROBATIC and these will be displayed.</li>
            <li>Once you click on the photo, all the information about the plane and owner will be displayed. You can scroll through pictures, contact the owner, or save the listing in your FAVORITES for review later on.</li>
            <li>You then can contact the poster VIA email, or a phone number if it is listed!</li>
          </ol>
          <h2>After You Find A Share/Sharer:</h2>
          <p>If you’re an owner, once you find the right share partner(s) for your aircraft, it’s an easy telephone call to your insurance agent to convert your policy to a limited renter’s policy. It’s quick, easy, and there are many companies that specialize in this.</p>
          <p>
            See our <router-link :to="{ name: 'insurance'}">
              insurance section
            </router-link> for a more detailed explanation of how sharing works with insurance. It’s easier than you probably think.
          </p>
          <p>
            They, owners will want to put in place, and sharers will want to agree to, an agreement detailing the terms of the share. We have spent a lot of time and effort putting together vetted and proven renters and CO-Ownership agreements with a leading aviation law firm.  You can purchase and modify these agreements and get more information <router-link :to="{ name: 'sharing'}">
              here
            </router-link>.
          </p>
          <p>
            Then, to help schedule and maintain your plane, you can check out Coflyt, an amazing partnership scheduling tool.  You can find out more <router-link :to="{ name: 'coflyt'}">
              here
            </router-link>.
          </p>
          <p>And once the agreement and scheduling is in place, all you have to do is collect the checks and put your aircraft to work for you. Whether your share partners buy their blocks of time in five or ten hour increments, your flying just became more economical!</p>
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaHowItWorks',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
</style>
