<template>
  <div
    v-if="activeModalName === 'forgot-password'"
    class="forgot-password-modal modal-background"
    style="z-index: 30;"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content form">
        <div v-if="tab === 0">
          <p>Please enter your email to help us reset your password</p>
          <input
            v-model="email"
            class="input email"
            type="text"
            placeholder="Email"
          >
          <div class="error">
            {{ error }}
          </div>
          <button
            class="button reset-password"
            @click="resetPassword"
          >
            Submit
          </button>
        </div>
        <div v-if="tab === 1">
          <span>Please check your email.</span>
          <button
            class="button reset-password"
            @click="okay"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { HIDE_MODAL, RESET_PASSWORD } from '@/store/action-types';

export default {
  name: 'SmaLoginModal',

  data() {
    return {
      email: '',
      tab: 0,
      error: ''
    };
  },

  computed: {
    ...mapGetters([
      'activeModalName'
    ])
  },

  methods: {
    hide() {
      this.tab = 0;
      this.error = '';
      this.email = '';
      this.$store.dispatch(HIDE_MODAL);
    },

    resetPassword() {
      if (!this.email) {
        this.error = 'Please enter your email address';
        return;
      }

      this.$store.dispatch(RESET_PASSWORD, {
        email: this.email
      })
        .then(() => {
          this.tab = 1;
        })
        .catch((err) => {
          console.error(err);

          if (err.response.status === 404) {
            this.error = 'No user found';
          }
        });
    },

    okay() {
      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  margin-top: 1em;

  span {
    margin-bottom: 1rem;
  }
}

.button {
  cursor: pointer;
  padding: 0.25rem 4rem;
  margin: 1rem auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 10rem;
  white-space: nowrap;
  background-color: $sma-white;
  text-align: center;
  display: block;

  &.reset-password {
    color: $sma-white;
    background-color: $sma-yellow;
    border-color: $sma-yellow;
  }
}

.divider {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .line {
    background-color: $sma-light-gray;
    height: 2px;
  }

  .text {
    align-self: center;
    margin-top: -0.75rem;
    padding: 0 1.5rem;
    background-color: $sma-white;
    font-size: 0.875rem;
  }
}

.input {
  width: 100%;
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.link {
  color: $sma-blue;
  font-weight: 600;
}

.forgot-password {
  align-self: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.signup {
  align-self: center;
  font-size: 0.875rem;
}

.error {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  text-align: center;
  color: $sma-red;
}
</style>
