import { toShare } from '@/utils/to-models';

import fetchApi from './fetch-api';

export default async function updateShare(options) {
  console.log(options);
  const share = {
    title: options.title,
    description: options.description,
    airport: options.airport.id,
    ratePerHour: options.ratePerHour && parseInt(`${options.ratePerHour}`.replace(/[^\d]/g, '')),
    isWet: options.isWet,
    media: options.media,
    defaultMedia: options.defaultMedia,
    businessName: options.businessName,
    sharePercentOffered: options.sharePercentOffered,
    pricePercentOffered: options.pricePercentOffered,
    desiredNumberOfPilots: options.desiredNumberOfPilots,
    clubName: options.clubName,
    clubRepName: options.clubRepName,
    clubMemberSize: options.clubMemberSize,
    clubNumAircrafts: options.clubNumAircrafts,
    clubWebsiteUrl: options.clubWebsiteUrl,
    clubEmail: options.clubEmail,
    clubPhone: options.clubPhone,
    url: options.url,
    aircraft: {
      id: options.aircraftId,
      regOwner: options.regOwner,
      make: options.make,
      model: options.model,
      year: options.year,
      engineHours: options.engineHours,
      engineManufacturer: options.engineManufacturer,
      engineModel: options.engineModel,
      classification: options.classification,
      categories: options.categories,
      instrumentRatingRequired: options.instrumentRatingRequired,
      equipment: options.equipment,
      interior: options.interior,
      exterior: options.exterior,
      avionics: options.avionics,
      comments: options.comments,
      tags: options.tags,
      cost: options.cost
    }
  };

  const data = new FormData();
  data.append('share', JSON.stringify(share));
  share.media.forEach((m) => {
    if (m.type === 'image' && m.file) {
      data.append('media', m.file);
    }
  });

  const response = await fetchApi({
    method: 'PATCH',
    path: `/share/${options.id}`,
    data
  });

  return toShare(response.data);
}
