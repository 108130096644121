<template>
  <div
    id="basic-info"
    class="row form"
  >
    <div class="col col-sm-8 horizontal">
      <label
        class="label"
        for="first-name"
      >First Name</label>
      <input
        id="first-name"
        v-model="form.firstName"
        class="input"
        type="text"
      >
    </div>
    <div class="col col-sm-8 horizontal">
      <label
        class="label"
        for="last-name"
      >Last Name</label>
      <input
        id="last-name"
        v-model="form.lastName"
        class="input"
        type="text"
      >
    </div>
    <div class="col col-sm-8 horizontal">
      <label
        class="label"
        for="email"
      >Email</label>
      <input
        id="email"
        v-model="form.email"
        class="input"
        type="text"
      >
    </div>
    <div
      v-if="form.provider === 'local'"
      class="col col-sm-12 horizontal"
    >
      <label
        class="label"
        for="password"
      >Password</label>
      <input
        id="password"
        v-model="form.password"
        class="input"
        type="password"
      >
    </div>
    <div
      v-if="form.provider === 'local'"
      class="col col-sm-12 horizontal"
    >
      <label
        class="label"
        for="cpassword"
      >Confirm Password</label>
      <input
        id="cpassword"
        v-model="form.cpassword"
        class="input"
        type="password"
      >
    </div>

    <div class="col">
      <label
        class="label"
        for="airport"
      >Home Airport</label>
      <sma-airport-select
        id="airport"
        v-model="form.airport"
      />
    </div>

    <div class="col">
      <div class="is-discoverable single-line">
        <label
          class="label"
          for="isDiscoverable"
        >Do you want to be discoverable on the map?</label>
        <client-only>
          <toggle-button
            id="isDiscoverable"
            v-model="form.isDiscoverable"
            :labels="{ checked: 'Yes', unchecked: 'No' }"
            :css-colors="true"
            :width="75"
            :height="36"
            :font-size="14"
          />
        </client-only>
      </div>
    </div>

    <div class="col">
      <label
        class="label"
        for="bio"
      >Bio</label>
      <textarea
        id="bio"
        v-model="form.bio"
        class="input"
        placeholder="Tell us something about yourself as a pilot."
      />
    </div>

    <div class="col">
      <fieldset>
        <legend class="label">
          Credentials (check all that apply)
        </legend>
        <div class="row">
          <div
            v-for="credentialOption in credentialOptions"
            :key="credentialOption"
            class="col col-sm-12 horizontal checkbox"
          >
            <input
              :id="`credential_${credentialOption.replace(/[^\w]+/g, '-').toLowerCase()}`"
              class="input"
              type="checkbox"
              :value="credentialOption"
              :checked="(form.credentials || []).includes(credentialOption)"
              @change="(e) => {
                const fieldset = e.target.closest('fieldset');
                const checkboxes = fieldset.querySelectorAll('input[type=\'checkbox\']');
                const credentials = Array.from(checkboxes).reduce((creds, checkbox) => {
                  if (checkbox.checked) {
                    creds.push(checkbox.value);
                  }

                  return creds;
                }, []);
                form.credentials = credentials;
              }"
            >
            <label
              class="label"
              :for="`credential_${credentialOption.replace(/[^\w]+/g, '-').toLowerCase()}`"
            >
              {{ credentialOption }}
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="col">
      <label
        class="label"
        for="totalHours"
      >Current Approximate total flight hours</label>
      <select
        id="totalHours"
        v-model="form.totalHours"
        class="input"
        placeholder="Select an option"
      >
        <option
          value=""
          disabled
        >
          Select an option
        </option>
        <option value="0-500">
          0-500
        </option>
        <option value="500-1000">
          500-1000
        </option>
        <option value="1000-3000">
          1000-3000
        </option>
        <option value="3000+">
          3000+
        </option>
      </select>
    </div>

    <div class="col">
      <label
        class="label"
        for="wantedPlane"
      >I want to fly a...</label>
      <input
        id="wantedPlane"
        v-model="form.wantedPlane"
        class="input"
        type="text"
        placeholder="Enter make and model"
      >
    </div>

    <div class="col">
      <label
        class="label"
        for="mostExpPlane"
      >I have the most experience flying a...</label>
      <input
        id="mostExpPlane"
        v-model="form.mostExpPlane"
        class="input"
        type="text"
        placeholder="Enter make and model"
      >
    </div>

    <!-- Media -->
    <div class="col">
      <div class="row">
        <div class="col">
          <label
            class="label"
            for="avatar"
          >Avatar</label>
          <sma-image-dropzone
            id="avatar"
            class="hidden"
            @change="addAvatar"
          />
        </div>
      </div>
      <div class="row">
        <div class="col col-md-8">
          <sma-media-thumbnail
            v-if="form.avatar"
            :media="form.avatar"
            @del="removeAvatar"
            @change="updateAvatar"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaAirportSelect from '@/components/AirportSelect';
import SmaImageDropzone from '@/components/ImageDropzone';
import SmaMediaThumbnail from '@/components/MediaThumbnail';

export default {
  name: 'UserBasicCard',

  components: {
    SmaAirportSelect,
    SmaImageDropzone,
    SmaMediaThumbnail
  },

  props: {
    value: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      console,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        cpassword: '',
        avatar: '',
        provider: 'local',
        airport: null,
        isDiscoverable: null,
        bio: '',
        credentials: [],
        totalHours: '',
        wantedPlane: '',
        mostExpPlane: ''
      },
      credentialOptions: [
        'Student',
        'Private',
        'Instrument',
        'Commercial',
        'ATP',
        'CFI, CFII',
        'Tail Wheel',
        'Acrobatic',
        'SeaPlane',
        'Multi-Engine',
        'High Performance',
        'Turbo',
        'Jet',
        'Cirrus CSIP / Transition',
        'Helicopter / Rotorcraft'
      ]
    };
  },

  watch: {
    value() {
      this.update();
    },

    form: {
      handler() {
        this.submit();
      },
      deep: true
    }
  },

  created() {
    this.update();
  },

  methods: {
    submit() {
      const value = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        password: this.form.password,
        cpassword: this.form.cpassword,
        avatar: this.form.avatar,
        provider: this.form.provider,
        airport: this.form.airport,
        isDiscoverable: this.form.isDiscoverable,
        bio: this.form.bio,
        credentials: this.form.credentials,
        totalHours: this.form.totalHours,
        wantedPlane: this.form.wantedPlane,
        mostExpPlane: this.form.mostExpPlane
      };

      this.$emit('input', value);
    },

    update() {
      if (this.value) {
        this.form.firstName = this.value.firstName;
        this.form.lastName = this.value.lastName;
        this.form.email = this.value.email;
        this.form.address = this.value.address;
        this.form.password = this.value.password;
        this.form.cpassword = this.value.cpassword;
        this.form.avatar = this.value.avatar;
        this.form.provider = this.value.provider;
        this.form.airport = this.value.airport;
        this.form.isDiscoverable = this.value.isDiscoverable;
        this.form.bio = this.value.bio;
        this.form.credentials = this.value.credentials;
        this.form.totalHours = this.value.totalHours;
        this.form.wantedPlane = this.value.wantedPlane;
        this.form.mostExpPlane = this.value.mostExpPlane;
      }
    },

    addAvatar(files) {
      const file = files[0];
      if (file) {
        this.form.avatar = {
          file,
          url: URL.createObjectURL(file),
          type: 'image'
        };
      }
    },

    updateAvatar(media) {
      this.form.avatar = media;
    },

    removeAvatar() {
      this.form.avatar = null;
      this.files = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  .horizontal {
    flex-direction: row;
    align-items: center;

    .label {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.input {
  background: white;
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 4px 8px;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  font-size: 1em;
  width: 100%;
  display: block;

  &[type="checkbox"],
  &[type="radio"] {
    width: auto;
  }

  &:focus {
    border-color: $sma-blue;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;

  legend {
    padding: 0;
  }
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-top: 5rem;
}

.delete-button {
  background-color: red;
}

.media-container {
  position: relative;
}

.media {
  margin: 0.5rem 0;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

.single-line {
  align-items: center;
}

.label[for="isDiscoverable"] {
  margin-right: 16px;
}

.is-discoverable {
  margin-top: 4px;
}

#isDiscoverable::v-deep {
  .v-switch-core {
    background-color: $sma-gray;
  }

  &.toggled {
    .v-switch-core {
      background-color: $sma-blue;
    }
  }
}
</style>
