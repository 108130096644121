<template>
  <div class="documents-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Investors</h1>
        </div>
        <div class="col col-sm-18">
          <p>SMA is in the process of raising its first round of financing.</p>
          <p>If you are interested in learning more, fill in the form below and we will email you some more information.</p>
          <p>THANK YOU –</p>

          <div id="f3d8c2f2-5db8-46c8-8942-7ac3f7162bc1" />
          <link
            rel="stylesheet"
            media="screen"
            href="https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css"
          >
          <component
            :is="'script'"
            src="https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js"
            clienthub_id="f3d8c2f2-5db8-46c8-8942-7ac3f7162bc1"
            form_url="https://clienthub.getjobber.com/client_hubs/f3d8c2f2-5db8-46c8-8942-7ac3f7162bc1/public/work_request/embedded_work_request_form"
          />
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaDocuments',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
</style>
