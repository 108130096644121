import fetchApi from './fetch-api';

export default async function fetchUserProfile() {
  try {
    const response = await fetchApi({
      method: 'GET',
      path: '/user'
    });

    const profile = response.data;

    /*
    Return {
      id: profile.uid,
      first: profile.firstname,
      last: profile.lastname,
    };
    */
    return profile;
  } catch (err) {
    return null;
  }
}
