<template>
  <div class="manage-account-page">
    <div class="container">
      <div class="col">
        <div class="row">
          <sma-account-sidebar class="col col-sm-5" />
          <div class="col col-md-19">
            <div class="col center">
              <h2>Account Information</h2>
            </div>
            <sma-user-basic-card
              :value="me"
              @input="updateBasic"
            />
            <div class="error-message">
              <ul>
                <li
                  v-for="(error,i) in basic.errors"
                  :key="i"
                >
                  {{ error.message }}
                </li>
              </ul>
            </div>
            <div class="row">
              <button
                class="btn"
                @click="submitBasic"
              >
                <span v-if="!isSavingBasic">Save</span>
                <span v-else><fa-icon
                  icon="circle-notch"
                  spin
                /></span>
              </button>
              <button
                class="btn red"
                @click="showConfirmDeleteAccountDialog = true"
              >
                <span v-if="!isDeletingAccount">Delete Account</span>
                <span v-else><fa-icon
                  icon="circle-notch"
                  spin
                /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sma-dialog-modal
      :buttons="['Okay']"
      :show="showOkayDialog"
      @close="closeOkayDialog"
    >
      Saved.
    </sma-dialog-modal>
    <sma-dialog-modal
      :buttons="['Yes', 'No']"
      :show="showConfirmDeleteAccountDialog"
      @close="delUser"
    >
      Are you sure?
    </sma-dialog-modal>
    <sma-dialog-modal
      :buttons="['Yes', 'No']"
      :show="showConfirmDeleteInstructorDialog"
      @close="delInstructor"
    >
      Are you sure?
    </sma-dialog-modal>
  </div>
</template>

<script>
import SmaAccountSidebar from '@/components/AccountSidebar';
import SmaDialogModal from '@/components/DialogModal';
import SmaUserBasicCard from '@/components/UserBasicCard';

import {
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_INSTRUCTOR_PROFILE,
  DELETE_USER_ACCOUNT,
  DELETE_INSTRUCTOR_ACCOUNT
} from '@/store/action-types';

import {
  getEmailErrors,
  getPasswordErrors,
  getConfirmedPasswordErrors,
  getRateErrors,
  getTagsErrors,
  getAirportErrors,
  getAvatarErrors,
  getAboutErrors
} from '@/utils/validators';

export default {
  name: 'SmaManageAccount',

  components: {
    SmaAccountSidebar,
    SmaDialogModal,
    SmaUserBasicCard
  },

  data() {
    return {
      tab: 'basic',
      basic: {
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        password: '',
        cpassword: '',
        airport: null,
        isDiscoverable: null,
        bio: '',
        credentials: [],
        totalHours: '',
        wantedPlane: '',
        mostExpPlane: '',
        avatar: null,
        provider: 'local',
        errors: []
      },
      instructor: {
        businessName: '',
        rate: '',
        about: '',
        airport: null,
        tags: [],
        errors: []
      },
      isSavingBasic: false,
      isDeletingAccount: false,
      isSavingInstructor: false,
      isDeletingInstructor: false,
      showOkayDialog: false,
      showConfirmDeleteAccountDialog: false,
      showConfirmDeleteInstructorDialog: false
    };
  },

  computed: {
    me() {
      const { me } = this.$store.getters;
      if (me) {
        return {
          firstName: me.firstname,
          lastName: me.lastname,
          email: me.email,
          address: me.address,
          provider: me.provider,
          rate: me.ratePerHour,
          about: me.about,
          airport: me.airport,
          avatar: me.avatar,
          tags: me.tags,
          isInstructor: me.isInstructor,
          businessName: me.businessName,
          isDiscoverable: me.isDiscoverable,
          bio: me.bio,
          credentials: me.credentials,
          totalHours: me.totalHours,
          wantedPlane: me.wantedPlane,
          mostExpPlane: me.mostExpPlane
        };
      }
      return {};
    }
  },

  methods: {
    updateBasic(basic) {
      Object.assign(this.basic, basic);
    },

    updateInstructor(instructor) {
      Object.assign(this.instructor, instructor);
    },

    getBasicErrors() {
      let errors = [].concat(
        getEmailErrors(this.basic.email)
      );

      if (this.basic.password) {
        errors = errors.concat(
          getPasswordErrors(this.basic.password),
          getConfirmedPasswordErrors(this.basic.password, this.basic.cpassword)
        );
      }

      return errors;
    },

    getInstructorErrors() {
      const errors = [].concat(
        getRateErrors(this.instructor.rate),
        getTagsErrors(this.instructor.tags),
        getAirportErrors(this.instructor.airport),
        getAvatarErrors(this.instructor.avatar),
        getAboutErrors(this.instructor.about)
      );

      return errors;
    },

    submitBasic() {
      this.basic.errors = this.getBasicErrors();
      if (this.basic.errors.length > 0) { return; }

      this.isSavingBasic = true;

      console.log(JSON.parse(JSON.stringify(this.basic)));

      this.$store.dispatch(UPDATE_USER_PROFILE, {
        firstname: this.basic.firstName,
        lastname: this.basic.lastName,
        email: this.basic.email,
        address: this.basic.address,
        password: this.basic.password,
        avatar: this.basic.avatar,
        isDiscoverable: this.basic.isDiscoverable,
        bio: this.basic.bio,
        credentials: this.basic.credentials,
        totalHours: this.basic.totalHours,
        wantedPlane: this.basic.wantedPlane,
        mostExpPlane: this.basic.mostExpPlane,
        airport: this.basic.airport
      })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isSavingBasic = false;
          this.showOkayDialog = true;
        });
    },

    submitInstructor() {
      this.instructor.errors = this.getInstructorErrors();
      if (this.instructor.errors.length > 0) { return; }

      this.isSavingInstructor = true;

      this.$store.dispatch(UPDATE_INSTRUCTOR_PROFILE, {
        businessName: this.instructor.businessName,
        ratePerHour: this.instructor.rate,
        about: this.instructor.about,
        avatar: this.instructor.avatar,
        tags: this.instructor.tags,
        airport: this.instructor.airport
      })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isSavingInstructor = false;
          this.showOkayDialog = true;
        });
    },

    delUser(confirm) {
      this.showConfirmDeleteAccountDialog = false;

      if (confirm === 'Yes') {
        this.isDeletingAccount = true;

        this.$store.dispatch(DELETE_USER_ACCOUNT)
          .catch((err) => {
            console.error(err);
          })
          .then(() => {
            this.$router.push({
              name: 'home'
            });
          })
          .finally(() => {
            this.isDeletingAccount = false;
          });
      }
    },

    delInstructor(confirm) {
      this.showConfirmDeleteInstructorDialog = false;

      if (confirm === 'Yes') {
        this.isDeletingInstructor = true;

        this.$store.dispatch(DELETE_INSTRUCTOR_ACCOUNT)
          .catch((err) => {
            console.error(err);
          })
          .then(() => {
            this.tab = 'basic';
            this.$store.dispatch(FETCH_USER_PROFILE);
          })
          .finally(() => {
            this.isDeletingInstructor = false;
          });
      }
    },

    closeOkayDialog() {
      this.showOkayDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 7rem;
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
}

.tabs {
  .button {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    background-color: $sma-yellow;
    color: $sma-white;
    border: 1px solid $sma-white;

    &.inactive {
      background-color: $sma-white;
      color: $sma-yellow;
      border: 1px solid $sma-yellow;
    }
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-button {
  background-color: $sma-red;
  margin-left: 2em;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}
</style>
