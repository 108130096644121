import { fetchCounts, search } from '@/services/api';

import { FETCH_COUNTS, SEARCH } from '../action-types';
import { COUNTS_FETCHED, INSTRUCTORS_SEARCHED, SEARCHED, SHARES_SEARCHED, USERS_ADDED } from '../mutation-types';

export default function createSearchModule() {
  return {
    state: () => ({
      searchResults: [],
      serachAirportCounts: [],
      totalCounts: null
    }),

    getters: {
      searchResults: state => state.searchResults,

      searchCount: state => state.serachAirportCounts.reduce(
        (total, count) => total + (count.share || 0) + (count.instructor || 0) + (count.user || 0),
        0
      ),

      serachAirportCounts: state => state.serachAirportCounts,

      totalCounts: state => state.totalCounts
    },

    mutations: {
      [SEARCHED]: (state, { response: { results, airportCounts }, page }) => {
        if (page > 1) {
          state.searchResults = [...state.searchResults, ...results];
        } else {
          state.searchResults = results;
        }
        state.serachAirportCounts = airportCounts;
      },

      [COUNTS_FETCHED]: (state, counts) => {
        state.totalCounts = counts;
      }
    },

    actions: {
      [SEARCH]: async ({ commit }, query) => {
        const response = await search(query);

        commit(SHARES_SEARCHED, {
          shares: response.results
            .filter(result => result.type === 'share')
            .map(result => result.data),
          page: query.page || 1
        });

        commit(INSTRUCTORS_SEARCHED, {
          instructors: { instructors:
            response.results
              .filter(result => result.type === 'instructor')
              .map(result => result.data)
          },
          fresh: (query.page || 1) === 1
        });

        commit(USERS_ADDED,
          response.results
            .filter(result => result.type === 'user')
            .map(result => result.data));

        commit(SEARCHED, { response, page: query.page || 1 });
      },

      [FETCH_COUNTS]: async ({ commit }) => {
        const counts = await fetchCounts();
        commit(COUNTS_FETCHED, counts);
      }
    }
  };
}

export const state = {
  searchResults: [],
  serachAirportCounts: [],
  totalCounts: null
};

export const getters = {
  searchResults: state => state.searchResults,

  searchCount: state => state.serachAirportCounts.reduce(
    (total, count) => total + (count.share || 0) + (count.instructor || 0) + (count.user || 0),
    0
  ),

  serachAirportCounts: state => state.serachAirportCounts,

  totalCounts: state => state.totalCounts
};

export const mutations = {
  [SEARCHED]: (state, { response: { results, airportCounts }, page }) => {
    if (page > 1) {
      state.searchResults = [...state.searchResults, ...results];
    } else {
      state.searchResults = results;
    }
    state.serachAirportCounts = airportCounts;
  },

  [COUNTS_FETCHED]: (state, counts) => {
    state.totalCounts = counts;
  }
};

export const actions = {
  [SEARCH]: async ({ commit }, query) => {
    const response = await search(query);

    commit(SHARES_SEARCHED, {
      shares: response.results
        .filter(result => result.type === 'share')
        .map(result => result.data),
      page: query.page || 1
    });

    commit(INSTRUCTORS_SEARCHED, {
      instructors: { instructors:
        response.results
          .filter(result => result.type === 'instructor')
          .map(result => result.data)
      },
      fresh: (query.page || 1) === 1
    });

    commit(USERS_ADDED,
      response.results
        .filter(result => result.type === 'user')
        .map(result => result.data));

    commit(SEARCHED, { response, page: query.page || 1 });
  },

  [FETCH_COUNTS]: async ({ commit }) => {
    const counts = await fetchCounts();
    commit(COUNTS_FETCHED, counts);
  }
};
