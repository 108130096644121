<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="wp-content"
    v-html="content"
  />
</template>

<script>
export default {
  name: 'WpContent',

  props: {
    content: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
.wp-content::v-deep {
  @include meta.load-css('../../node_modules/@wordpress/block-library/build-style/common.css');
  @include meta.load-css('../../node_modules/@wordpress/block-library/build-style/style.css');
  @include meta.load-css('../../node_modules/@wordpress/block-library/build-style/theme.css');
}
</style>
