<template>
  <div class="admin-page">
    <div
      class="container"
    >
      <div class="col-sm-24">
        <h3 class="col-sm-24">
          Download CSV
        </h3>
        <div class="col-sm-24">
          <a
            class="button"
            :href="`${apiUrl}/admin/export/users`"
          >Users</a>
        </div>
        <div class="col-sm-24">
          <a
            class="button"
            :href="`${apiUrl}/admin/export/instructors`"
          >Instructors</a>
        </div>
        <div class="col-sm-24">
          <a
            class="button"
            :href="`${apiUrl}/admin/export/shares`"
          >Shares</a>
        </div>
      </div>
      <div class="col-sm-24">
        <h3 class="col-sm-24">
          Delete User
        </h3>
        <div class="row">
          <div class="col-sm-6" />
          <div class="col col-sm-6">
            <label for="user-id">User ID:</label>
            <input
              id="user-id"
              v-model="userId"
              class="input"
              type="text"
            >
          </div>
          <div class="col col-sm-6">
            <button
              class="button"
              @click="deleteUser"
            >
              DELETE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ADMIN_DELETE_USER_ACCOUNT } from '@/store/action-types';

export default {
  name: 'SmaAdmin',

  data() {
    return {
      apiUrl: process.env.API_URL
    };
  },

  computed: {
    ...mapGetters(['me'])
  },

  methods: {
    async deleteUser() {
      await this.$store.dispatch(ADMIN_DELETE_USER_ACCOUNT, parseInt(this.userId));
      this.userId = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  display: block;
  text-align: center;
  color: $sma-white;
  background-color: $sma-yellow;
  padding: 0.5rem 2rem;
  border-radius: 10rem;
  margin: 1rem auto;
  width: 50%;
  border: 0;
}

.container {
  margin-bottom: 60px;
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 4px 8px;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  font-size: 1em;
  width: 100%;
  display: block;

  &:focus {
    border-color: $sma-blue;
  }
}
</style>
