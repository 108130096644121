<template>
  <div class="dropzone">
    <input
      ref="fileInput"
      class="dropzone-input hidden"
      type="file"
      accept=".png,.jpg,.jpeg,image/png,imgage/jpeg"
      :disabled="resizing"
      v-bind="$attrs"
      @change.prevent.stop="onChange"
      @dragover.prevent.stop
      @drop.prevent.stop="onDrop"
    >
    <div class="overlay">
      <div
        v-if="resizing"
        class="text"
      >
        <fa-icon
          icon="circle-notch"
          spin
        />
        <br>
        Resizing...
      </div>
      <div
        v-else
        class="text"
      >
        <slot>Click or drag an image here.</slot>
      </div>
    </div>
  </div>
</template>

<script>
import resizeImage from '@/utils/resize-image';

export default {
  name: 'SmaImageDropzone',

  inheritAttrs: false,

  data() {
    return {
      resizing: false
    };
  },

  methods: {
    onChange(e) {
      this.handleChange(e.target.files);
    },

    onDrop(e) {
      this.handleChange(e.dataTransfer.files);
    },

    async handleChange(fileList) {
      const files = Array.from(fileList);

      let accept = true;
      files.forEach((file) => {
        if (!/^image\/(jpeg|png)$/.test(file.type) || !/\.(png|jpe?g)$/.test(file.name)) {
          accept = false;
        }
      });

      if (!accept) { return; }

      this.resizing = true;

      let resizedImages;
      try {
        resizedImages = await Promise.all(files.map(resizeImage));
      } catch (err) {
        console.error(err);
        this.$emit('change', files);
        return;
      } finally {
        this.resizing = false;
      }

      this.$emit('change', resizedImages);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropzone {
  border: 1px dashed $sma-gray;
  position: relative;

  input {
    width: 100%;
    opacity: 0;
    min-height: 8rem;
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      text-align: center;
    }
  }
}
</style>
