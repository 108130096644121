<template>
  <div class="post-confirm-page">
    <div class="container">
      <div class="row">
        <sma-progress
          class="col"
          :step="4"
          :steps="steps"
        />
      </div>
      <div class="row">
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Registered Owner:</strong> {{ postInProgress.regOwner }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Title:</strong> {{ postInProgress.title }}</p>
        </div>
        <div class="col col-sm-8">
          <p><strong>Description:</strong> {{ postInProgress.description }}</p>
        </div>
        <div
          v-if="postInProgress.type !== 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Make:</strong> {{ postInProgress.make }}</p>
        </div>
        <div
          v-if="postInProgress.type !== 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Model:</strong> {{ postInProgress.model }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Year:</strong> {{ postInProgress.year }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB', 'PRIVATE_OWNER_SHARE_PURCHASE'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Hourly Rate:</strong> {{ postInProgress.rate }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Engine Hours:</strong> {{ postInProgress.engineHours }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Engine Manufacturer:</strong> {{ postInProgress.engineManufacturer }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Engine Model:</strong> {{ postInProgress.engineModel }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Classification:</strong> {{ postInProgress.classification }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Categories:</strong> {{ postInProgress.categories ? postInProgress.categories.join(', ') : '' }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p>
            <strong>Instrument Rating Required:</strong> <input
              class="input checkbox"
              type="checkbox"
              :checked="postInProgress.instrumentRatingRequired"
              disabled
            >
          </p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Equipment:</strong> {{ postInProgress.equipment }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Exterior:</strong> {{ postInProgress.exterior }}</p>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Interior:</strong> {{ postInProgress.interior }}</p>
        </div>
        <div
          v-if="postInProgress.type !== 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Owner Comments:</strong> {{ postInProgress.comments }}</p>
        </div>

        <div
          v-if="postInProgress.type === 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Club Name:</strong> {{ postInProgress.clubName }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Club Representative Name:</strong> {{ postInProgress.clubRepName }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Member Size:</strong> {{ postInProgress.clubMemberSize }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Number of Aircrafts:</strong> {{ postInProgress.clubNumAircrafts }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Club Website URL:</strong> {{ postInProgress.clubWebsiteUrl }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Club Email Address:</strong> {{ postInProgress.clubEmail }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'FLIGHT_CLUB'"
          class="col col-sm-8"
        >
          <p><strong>Club Phone Number:</strong> {{ postInProgress.clubPhone }}</p>
        </div>

        <div
          v-if="postInProgress.type === 'PRIVATE_OWNER_SHARE_PURCHASE'"
          class="col col-sm-8"
        >
          <p><strong>Share Percent Offered:</strong> {{ postInProgress.sharePercentOffered }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'PRIVATE_OWNER_SHARE_PURCHASE'"
          class="col col-sm-8"
        >
          <p><strong>Price Offered per Share:</strong> {{ postInProgress.pricePercentOffered }}</p>
        </div>

        <div
          v-if="postInProgress.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'"
          class="col col-sm-8"
        >
          <p><strong>Approximate Expected Cost:</strong> ${{ postInProgress.cost }}</p>
        </div>
        <div
          v-if="postInProgress.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'"
          class="col col-sm-8"
        >
          <p><strong>Desired Number of Pilots:</strong> {{ postInProgress.desiredNumberOfPilots }}</p>
        </div>


        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(postInProgress.type)"
          class="col col-sm-8"
        >
          <p><strong>Aircraft Details:</strong> {{ (postInProgress.tags) ? postInProgress.tags.join(', ') : '' }}</p>
        </div>
        <div class="col col-sm-8">
          <p><strong>Airport:</strong> {{ (postInProgress.airport) ? postInProgress.airport.facility : '' }}</p>
        </div>
        <div class="col">
          <p><strong>Media</strong></p>
        </div>
        <div class="row">
          <sma-media-thumbnail
            v-for="(media,i) in postInProgress.media"
            :key="media.url"
            class="col col-sm-6 aspect-ratio-3-2"
            :default-media="i === postInProgress.defaultMedia"
            :media="media"
            :del-button="false"
          />
        </div>
      </div>
      <div class="row nav">
        <div class="col col-12">
          <button
            class="btn yellow inv"
            @click="back"
          >
            Previous step
          </button>
        </div>
        <div class="col col-12">
          <button
            class="btn yellow"
            @click="submit"
          >
            <span v-if="!posting">Submit</span>
            <span v-else><fa-icon
              icon="circle-notch"
              spin
            /></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';

import SmaMediaThumbnail from '@/components/MediaThumbnail';
import SmaProgress from '@/components/Progress';
import { SUBMIT_POST_IN_PROGRESS } from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';

export default {
  name: 'SmaPostConfirm',

  components: {
    SmaMediaThumbnail,
    SmaProgress
  },

  data() {
    return {
      steps: [
        ['General', 'Information'],
        ['Your plane', 'location'],
        ['Add Photos', '& Video'],
        ['Preferred Flight', 'Instructor'],
        ['Preview &', 'Confirm']
      ],
      posting: false
    };
  },

  computed: {
    ...mapGetters([
      'postInProgress'
    ])
  },

  created() {
    if (!isEmpty(this.$store.state.post.inProgress)) {
      // Object.assign(this.form, this.$store.state.post.inProgress);
    } else {
      this.$router.replace({ name: 'post' });
    }
  },

  methods: {
    getBackgroundStyle,

    back() {
      this.$router.replace({
        name: 'post-preferred-instructor'
      });
    },

    async submit() {
      if (this.posting) {
        return;
      }

      this.posting = true;

      await this.$store.dispatch(SUBMIT_POST_IN_PROGRESS, this.postInProgress);

      this.$router.push({
        name: 'manage-listings'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.post-confirm-page {
  padding: 2rem;
}

.row {
  margin-bottom: 2rem;
}

.media {
  margin: 0.5rem 0;
}

[default-media] {
  box-shadow: 0 0 20px 10px $sma-blue;
}

.nav .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .button {
    align-self: center;
    margin-top: 3rem;
  }
}

p {
  color: $sma-gray;

  & strong {
    color: $sma-black;
  }
}
</style>
