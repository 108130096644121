import Vue from 'vue';
import Vuex from 'vuex';

// import modules from './modules';

import createAccountPreferencesModule from './modules/account-preferences';
import createErrorsModule from './modules/errors';
import createFeedbackModule from './modules/feedback';
import createGeoModule from './modules/geo';
import createHeaderModule from './modules/header';
import createHomeMapModule from './modules/home-map';
import createInstructorsModule from './modules/instructors';
import createMapsModule from './modules/maps';
import createModalsModule from './modules/modals';
import createPostModule from './modules/post';
import createRouterModule from './modules/router';
import createSearchModule from './modules/search';
import createSharesModule from './modules/shares';
import createUsersModule from './modules/users';
import createWpModule from './modules/wp';

Vue.use(Vuex);

export default function createStore() {
  return new Vuex.Store({
    modules: {
      accountPreferences: createAccountPreferencesModule(),
      errors: createErrorsModule(),
      feedback: createFeedbackModule(),
      geo: createGeoModule(),
      header: createHeaderModule(),
      homeMap: createHomeMapModule(),
      instructors: createInstructorsModule(),
      maps: createMapsModule(),
      modals: createModalsModule(),
      post: createPostModule(),
      router: createRouterModule(),
      search: createSearchModule(),
      shares: createSharesModule(),
      users: createUsersModule(),
      wp: createWpModule()
    }
  });
}
