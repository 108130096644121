<template>
  <div class="feedback-page info-page">
    <div class="container">
      <h1>Give us your Feedback</h1>
      <form
        action="#"
        method="POST"
        autocomplete="off"
        @submit="contactSubmit"
      >
        <div class="row">
          <div class="col col-sm-12">
            <label for="name">Full Name</label>
            <input
              id="name"
              v-model="name"
              name="name"
              type="text"
              required
            >
            <label for="real-email">Email Address</label>
            <input
              id="email"
              v-model="honeypot"
              name="email"
              type="email"
              tabindex="-1"
            >
            <input
              id="real-email"
              v-model="email"
              name="real-email"
              type="email"
              required
            >
          </div>
          <div class="col col-sm-12">
            <label for="name">Share your Feedback</label>
            <textarea
              id="reason"
              v-model="reason"
              name="reason"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          class="submit-btn btn yellow"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { HIDE_MODAL, SHOW_MODAL, SUBMIT_FEEDBACK } from '@/store/action-types';

export default {
  name: 'SmaFeedback',

  data() {
    return {
      name: '',
      email: '',
      reason: '',
      honeypot: ''
    };
  },

  methods: {
    contactSubmit(e) {
      e.preventDefault();
      if (this.name !== '' && this.email !== '' && this.reason !== '' && this.honeypot === '') {
        this.$store.dispatch(SUBMIT_FEEDBACK, {
          name: this.name,
          email: this.email,
          reason: this.reason
        });
        this.$store.dispatch(SHOW_MODAL, {
          name: 'feedback'
        });
        const that = this;
        setTimeout(() => { that.$store.dispatch(HIDE_MODAL); }, 4000);
        this.name = '';
        this.email = '';
        this.reason = '';
        document.querySelector('form').reset();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  margin: 0.5rem 0 0.25rem;
  font-size: 0.875rem;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;

  :focus {
    border-color: $sma-blue;
  }
}

textarea {
  height: 97px;
}

.submit-btn {
  cursor: pointer;
  width: 150px;
  margin: 0.25rem auto;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}
</style>
