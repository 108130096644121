<template>
  <div class="notfound-page" />
</template>

<script>
export default {
  name: 'SmaNotFound'
};
</script>

<style lang="scss" scoped>
</style>
