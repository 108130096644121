import fetchApi from './fetch-api';

export default async function updateSubscription(id, { cancelAtPeriodEnd }) {
  await fetchApi({
    method: 'PATCH',
    path: `/user/subscription/${id}`,
    json: {
      cancelAtPeriodEnd
    }
  });
}
