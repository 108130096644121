import {
  addFavoriteInstructor,
  createInstructorAccount,
  deleteInstructorAccount,
  fetchFavoriteInstructors,
  fetchInstructors,
  removeFavoriteInstructor,
  updateInstructorProfile
} from '@/services/api';

import sleep from '@/utils/sleep';
import {
  ADD_FAVORITE_INSTRUCTOR,
  CREATE_INSTRUCTOR,
  DELETE_INSTRUCTOR_ACCOUNT,
  FETCH_FAVORITE_INSTRUCTORS,
  FETCH_USER_PROFILE,
  INSTRUCTOR_FOLLOWUP,
  INSTRUCTOR_SIGNUP,
  SHOW_MODAL,
  REMOVE_FAVORITE_INSTRUCTOR,
  SEARCH_INSTRUCTORS,
  UPDATE_INSTRUCTOR_PROFILE
} from '../action-types';
import {
  FAVORITE_INSTRUCTORS_FETCHED,
  FAVORITE_INSTRUCTOR_ADDED,
  FAVORITE_INSTRUCTOR_REMOVED,
  INSTRUCTORS_SEARCHED,
  INSTRUCTOR_FOLLOWEDUP
} from '../mutation-types';

export default function createInstructorsModule() {
  return {
    state: () => ({
      searched: [],
      searchedCount: 0,
      favorite: [],
      records: {},
      instructorFollowup: false
    }),

    getters: {
      getInstructorById: state => id => state.records[id],
      favoriteInstructors: (state, getters) => state.favorite.map(getters.getInstructorById),
      searchedInstructors: (state, getters) => state.searched.map(getters.getInstructorById),
      searchedInstructorsCount: state => state.searchedCount
    },

    mutations: {
      [FAVORITE_INSTRUCTOR_ADDED]: (state, instructor) => {
        state.favorite.push(instructor.id);
      },

      [FAVORITE_INSTRUCTOR_REMOVED]: (state, instructor) => {
        state.favorite = state.favorite.filter(id => id !== instructor.id);
      },

      [FAVORITE_INSTRUCTORS_FETCHED]: (state, action) => {
        action.instructors.forEach((instructor) => {
          state.records[instructor.id] = instructor;
        });

        state.favorite = action.instructors.map(instructor => instructor.id);
      },

      [INSTRUCTORS_SEARCHED]: (state, action) => {
        const { fresh, instructors } = action;

        instructors.instructors.forEach((instructor) => {
          state.records[instructor.id] = instructor;
        });

        const searched = instructors.instructors.map(instructor => instructor.id);

        if (fresh) {
          state.searched = searched;
        } else {
          state.searched = state.searched.concat(searched);
        }

        state.searchedCount = instructors.length;
      },

      [INSTRUCTOR_FOLLOWEDUP]: async (state, followup) => {
        state.instructorFollowup = followup;
      }
    },

    actions: {
      [FETCH_FAVORITE_INSTRUCTORS]: async (context, options) => {
        const instructors = await fetchFavoriteInstructors(options);

        if (instructors) {
          context.commit(FAVORITE_INSTRUCTORS_FETCHED, instructors);
        }
      },

      [ADD_FAVORITE_INSTRUCTOR]: async (context, options) => {
        await addFavoriteInstructor(options);
        context.commit(FAVORITE_INSTRUCTOR_ADDED, options);
      },

      [SEARCH_INSTRUCTORS]: async (context, options) => {
        const { fresh, ...query } = options;
        const instructors = await fetchInstructors(query);

        if (instructors) {
          context.commit(INSTRUCTORS_SEARCHED, { fresh, instructors });
        }
      },

      [CREATE_INSTRUCTOR]: async (context, options) => {
        await createInstructorAccount(options);
        await sleep(1000);
        await context.dispatch(FETCH_USER_PROFILE);
      },

      [UPDATE_INSTRUCTOR_PROFILE]: async (context, options) => {
        await updateInstructorProfile(options);
        await sleep(1000);
        await context.dispatch(FETCH_USER_PROFILE);
      },

      [DELETE_INSTRUCTOR_ACCOUNT]: async (context) => {
        await deleteInstructorAccount();
        await context.dispatch(FETCH_USER_PROFILE);
      },

      [REMOVE_FAVORITE_INSTRUCTOR]: async (context, options) => {
        await removeFavoriteInstructor(options);
        context.commit(FAVORITE_INSTRUCTOR_REMOVED, options);
      },

      [INSTRUCTOR_FOLLOWUP]: async (context, followup) => {
        context.commit(INSTRUCTOR_FOLLOWEDUP, followup);
      },

      [INSTRUCTOR_SIGNUP]: (context) => {
        const { me } = context.getters;

        if (me) {
          // if (!me.subscriptions[process.env.INSTRUCTOR_SUBSCRIPTION_PRICE_ID].isActive) {
          //   context.dispatch(SHOW_MODAL, {
          //     name: 'subscribe',
          //     subType: 'instructor'
          //   });
          // } else {
          //   context.dispatch(SHOW_MODAL, {
          //     name: 'instructor-signup'
          //   });
          // }
          context.dispatch(SHOW_MODAL, {
            name: 'instructor-signup'
          });
        } else {
          context.dispatch(INSTRUCTOR_FOLLOWUP, true);
          context.dispatch(SHOW_MODAL, {
            name: 'signup',
            onComplete: function instructoOnComplete() {
              setTimeout(() => {
                context.dispatch(SHOW_MODAL, {
                  name: 'instructor-signup'
                });
              }, 0);
            }
          });
        }
      }
    }
  };
}


export const state = {
  searched: [],
  searchedCount: 0,
  favorite: [],
  records: {},
  instructorFollowup: false
};

export const getters = {
  getInstructorById: state => id => state.records[id],
  favoriteInstructors: (state, getters) => state.favorite.map(getters.getInstructorById),
  searchedInstructors: (state, getters) => state.searched.map(getters.getInstructorById),
  searchedInstructorsCount: state => state.searchedCount
};

export const mutations = {
  [FAVORITE_INSTRUCTOR_ADDED]: (state, instructor) => {
    state.favorite.push(instructor.id);
  },

  [FAVORITE_INSTRUCTOR_REMOVED]: (state, instructor) => {
    state.favorite = state.favorite.filter(id => id !== instructor.id);
  },

  [FAVORITE_INSTRUCTORS_FETCHED]: (state, action) => {
    action.instructors.forEach((instructor) => {
      state.records[instructor.id] = instructor;
    });

    state.favorite = action.instructors.map(instructor => instructor.id);
  },

  [INSTRUCTORS_SEARCHED]: (state, action) => {
    const { fresh, instructors } = action;

    instructors.instructors.forEach((instructor) => {
      state.records[instructor.id] = instructor;
    });

    const searched = instructors.instructors.map(instructor => instructor.id);

    if (fresh) {
      state.searched = searched;
    } else {
      state.searched = state.searched.concat(searched);
    }

    state.searchedCount = instructors.length;
  },

  [INSTRUCTOR_FOLLOWEDUP]: async (state, followup) => {
    state.instructorFollowup = followup;
  }
};

export const actions = {
  [FETCH_FAVORITE_INSTRUCTORS]: async (context, options) => {
    const instructors = await fetchFavoriteInstructors(options);

    if (instructors) {
      context.commit(FAVORITE_INSTRUCTORS_FETCHED, instructors);
    }
  },

  [ADD_FAVORITE_INSTRUCTOR]: async (context, options) => {
    await addFavoriteInstructor(options);
    context.commit(FAVORITE_INSTRUCTOR_ADDED, options);
  },

  [SEARCH_INSTRUCTORS]: async (context, options) => {
    const { fresh, ...query } = options;
    const instructors = await fetchInstructors(query);

    if (instructors) {
      context.commit(INSTRUCTORS_SEARCHED, { fresh, instructors });
    }
  },

  [CREATE_INSTRUCTOR]: async (context, options) => {
    await createInstructorAccount(options);
    await sleep(1000);
    await context.dispatch(FETCH_USER_PROFILE);
  },

  [UPDATE_INSTRUCTOR_PROFILE]: async (context, options) => {
    await updateInstructorProfile(options);
    await sleep(1000);
    await context.dispatch(FETCH_USER_PROFILE);
  },

  [DELETE_INSTRUCTOR_ACCOUNT]: async (context) => {
    await deleteInstructorAccount();
    await context.dispatch(FETCH_USER_PROFILE);
  },

  [REMOVE_FAVORITE_INSTRUCTOR]: async (context, options) => {
    await removeFavoriteInstructor(options);
    context.commit(FAVORITE_INSTRUCTOR_REMOVED, options);
  },

  [INSTRUCTOR_FOLLOWUP]: async (context, followup) => {
    context.commit(INSTRUCTOR_FOLLOWEDUP, followup);
  },

  [INSTRUCTOR_SIGNUP]: (context) => {
    const { me } = context.getters;

    if (me) {
      // if (!me.subscriptions[process.env.INSTRUCTOR_SUBSCRIPTION_PRICE_ID].isActive) {
      //   context.dispatch(SHOW_MODAL, {
      //     name: 'subscribe',
      //     subType: 'instructor'
      //   });
      // } else {
      //   context.dispatch(SHOW_MODAL, {
      //     name: 'instructor-signup'
      //   });
      // }
      context.dispatch(SHOW_MODAL, {
        name: 'instructor-signup'
      });
    } else {
      context.dispatch(INSTRUCTOR_FOLLOWUP, true);
      context.dispatch(SHOW_MODAL, {
        name: 'signup',
        onComplete: function instructoOnComplete() {
          setTimeout(() => {
            context.dispatch(SHOW_MODAL, {
              name: 'instructor-signup'
            });
          }, 0);
        }
      });
    }
  }
};
