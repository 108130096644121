<template>
  <div
    v-if="activeModalName === 'share'"
    class="share-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="container content">
        <div class="row">
          <div class="col col-sm-12 slider-outer">
            <div
              class="slide-pre"
              @click="slidePre"
            >
              <fa-icon
                icon="chevron-circle-left"
                class="icon fa-2x"
              />
            </div>
            <client-only>
              <slider
                ref="slider"
                class="image aspect-ratio-1-1"
                :pages="sliderPages"
                :sliderinit="sliderInit"
              >
                <div slot="loading">
                  loading...
                </div>
              </slider>
            </client-only>
            <div
              class="slide-next"
              @click="slideNext"
            >
              <fa-icon
                icon="chevron-circle-right"
                class="icon fa-2x"
              />
            </div>
          </div>
          <div class="top col col-sm-12">
            <h2 class="airport-id">
              Airport: {{ share.airport.locationId }}
            </h2>
            <h2>{{ title }}</h2>
            <h4
              v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
              class="tail-number"
            >
              {{ share.tailNumber }}
            </h4>
            <div v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'PRIVATE_OWNER_SHARE_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)">
              <div v-if="!editing">
                ${{ share.ratePerHour && share.ratePerHour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} per hour
                ({{ share.isWet ? 'wet' : 'dry' }})
              </div>
              <div v-else>
                $<input
                  v-model="form.ratePerHour"
                  class="input price"
                > per hour
                (<select
                  :value="form.isWet ? 'true' : 'false'"
                  @change="(e) => {
                    const el = e.target;
                    const valueStr = el.options[el.options.selectedIndex].value;
                    form.isWet = valueStr === 'true';
                  }"
                >
                  <option value="true">
                    Wet
                  </option>
                  <option value="false">
                    Dry
                  </option>
                </select>)
              </div>
            </div>
            <div class="buttons">
              <button
                class="email"
                @click="clickMail"
              >
                <fa-icon
                  icon="envelope"
                  class="icon"
                /> Send Email
              </button>
              <span v-if="me">
                <button
                  v-if="!isFavorited"
                  class="favorite"
                  @click="addFavorite"
                >
                  <fa-icon
                    icon="heart"
                    class="icon"
                  /> Add to Favorites
                </button>
                <button
                  v-else
                  class="favorite"
                  @click="removeFavorite"
                >
                  <fa-icon
                    icon="ban"
                    class="icon"
                  /> Remove from Favorites
                </button>
              </span>
              <button
                class="copytoclipboard"
                @click="copyToClipboard"
              >
                <fa-icon
                  icon="copy"
                  class="icon"
                /> Copy Link to Clipboard
              </button>
            </div>
            <div>
              <div>
                <div><strong>Share Type:</strong></div>
                <div v-if="share.type === 'PRIVATE_OWNER_RENTAL'">
                  PRIVATE OWNER RENTAL
                </div>
                <div v-else-if="share.type === 'PRIVATE_OWNER_SHARE_PURCHASE'">
                  PRIVATE OWNER SHARE PURCHASE
                </div>
                <div v-else-if="share.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'">
                  PARTNERSHIP TO PURCHASE NEW OR USED AIRCRAFT
                </div>
                <div v-else-if="share.type === 'FBO_RENTAL'">
                  FLIGHT SCHOOL RENTAL
                </div>
                <div v-else-if="share.type === 'FLIGHT_CLUB'">
                  FLYING CLUB
                </div>
              </div>
            </div>
            <div
              v-if="!editing"
              class="description"
            >
              {{ share.description }}
            </div>
            <div
              v-else
              class="description"
            >
              <textarea
                v-model="form.description"
                class="input"
              />
            </div>

            <div
              v-if="!editing"
              class="url"
            >
              <a :href="share.url">
                {{ share.url }}
              </a>
            </div>
            <div
              v-else
              class="url"
            >
              <input
                v-model="form.url"
                type="text"
                class="input"
              >
            </div>
          </div>
          <div
            v-if="isOwner"
            class="col"
          >
            <div class="buttons">
              <button
                v-if="!editing"
                @click="edit"
              >
                <fa-icon
                  icon="edit"
                  class="icon"
                /> Edit
              </button>
              <button
                v-else
                @click="cancel"
              >
                <fa-icon
                  icon="ban"
                  class="icon"
                /> Cancel
              </button>
              <button
                v-if="!share.isActive"
                class="disable"
                @click="enable"
              >
                <fa-icon
                  icon="check"
                  class="icon"
                /> Enable
              </button>
              <button
                v-else
                class="disable"
                @click="disable"
              >
                <fa-icon
                  icon="times"
                  class="icon"
                /> Disable
              </button>
              <button
                class="delete"
                @click="remove"
              >
                <fa-icon
                  icon="trash-alt"
                  class="icon"
                /> Delete
              </button>
            </div>
          </div>
          <div
            v-if="editing"
            class="col row"
          >
            <div
              v-for="(media,i) in form.media"
              :key="media.url"
              class="col col-sm-6"
            >
              <sma-media-thumbnail
                class="aspect-ratio-3-2"
                :default-media="i === form.defaultMedia"
                :media="media"
                @click="setDefault(i)"
                @change="setMedia(i, $event)"
                @del="removeMedia(i)"
              />
            </div>
            <sma-media-dropzone
              class="col col-sm-6"
              @change="addMedia"
            />
            <div class="drop-text">
              <p>The highlighted image will be your default image for this listing. Click any image to make that your default image.</p>
              <p>
                Please note, pictures will upload as they are formatted on your device.<br>
                Please make sure pictures are taken and uploaded right-side up.
              </p>
            </div>
          </div>
          <div
            v-if="editing"
            class="error col col-sm-24"
          >
            <div class="fail">
              <ul>
                <li
                  v-for="(error,i) in errors"
                  :key="i"
                >
                  {{ error.message }}
                </li>
              </ul>
            </div>
            <button
              button="button"
              class="btn btn-yellow"
              @click="submit"
            >
              <span v-if="!isUpdating">Update</span>
              <span v-else><fa-icon
                icon="circle-notch"
                spin
              /></span>
            </button>
          </div>
          <div class="col">
            <h3>Contact Information</h3>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                Lister name:
              </div>
              <div class="value">
                {{ share.owner.first }} {{ share.owner.last }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div
              v-if="share.businessName"
              class="pair"
            >
              <div class="name">
                Business Name:
              </div>
              <div class="value">
                {{ share.businessName }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                Address:
              </div>
              <div class="value">
                {{ address }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                Airport:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.airport.facility }}
              </div>
              <div
                v-else
                class="value"
              >
                <sma-airport-select
                  v-model="form.airport"
                  class="input"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <h3>General Information</h3>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Registered Owner:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.regOwner }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.regOwner"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-24"
          >
            <div class="pair">
              <div class="name">
                Title:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.title }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.title"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type !== 'FLIGHT_CLUB'"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Make:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.make }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.make"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type !== 'FLIGHT_CLUB'"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Model:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.model }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.model"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Year:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.year }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.year"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Engine Hours:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.engineHours && share.engineHours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.engineHours"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Engine Manufacturer:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.engineManufacturer }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.engineManufacturer"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Engine Model:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.engineModel }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.engineModel"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Classification:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.classification }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.classification"
                  class="input"
                >
              </div>
            </div>
          </div><div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Categories:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.categories ? share.categories.join(', ') : '' }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.categories"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Instrument Rating Required:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                <span v-if="share.instrumentRatingRequired">
                  Yes
                </span>
                <span v-else>
                  No
                </span>
              </div>
              <div v-else>
                <input
                  v-model="form.instrumentRatingRequired"
                  class="input checkbox"
                  type="checkbox"
                  :disabled="!editing"
                >
              </div>
            </div>
          </div>

          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Equipment:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.equipment }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.equipment"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Exterior:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.exterior }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.exterior"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Avionics:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.avionics }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.avionics"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Interior:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.interior }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.interior"
                  class="input"
                >
              </div>
            </div>
          </div>

          <div
            v-if="share.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'"
            class="col col-sm-12"
          >
            <div class="pair split">
              <div class="name">
                Approximate Expected Cost:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                ${{ share.cost && share.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.cost"
                  class="input"
                  type="number"
                >
              </div>
            </div>
          </div>

          <div
            v-if="share.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'"
            class="col col-sm-12"
          >
            <div class="pair split">
              <div class="name">
                Desired Number of Pilots:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.desiredNumberOfPilots }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.desiredNumberOfPilots"
                  class="input"
                  type="number"
                >
              </div>
            </div>
          </div>

          <div
            v-if="share.type === 'PRIVATE_OWNER_SHARE_PURCHASE'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Share Percent Offered:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.sharePercentOffered }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.sharePercentOffered"
                  class="input"
                >
              </div>
            </div>
          </div>

          <div
            v-if="share.type === 'PRIVATE_OWNER_SHARE_PURCHASE'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Price Offered per Share:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                ${{ share.pricePercentOffered && share.pricePercentOffered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.pricePercentOffered"
                  class="input"
                  type="number"
                >
              </div>
            </div>
          </div>

          <div
            v-if="share.type !== 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Additional Notes:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.comments }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.comments"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(share.type)"
            class="col col-sm-24"
          >
            <div class="pair">
              <div class="name">
                Aircraft Details:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.tags ? share.tags.join(', ') : '' }}
              </div>
              <div
                v-else
                class="row"
              >
                <div class="col col-sm-6">
                  <!-- Spacer -->
                </div>
                <div class="col col-sm-18">
                  <div class="row">
                    <div
                      v-for="tagName in tagNames"
                      :key="tagName"
                      class="col col-sm-8"
                    >
                      <input
                        :id="tagName"
                        v-model="form.tags[tagName]"
                        class="input"
                        type="checkbox"
                      >
                      <label
                        class="label"
                        :for="tagName"
                      >
                        {{ tagName }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="share.type === 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Club Name:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.clubName }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.clubName"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type === 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Club Representative Name:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.clubRepName }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.clubRepName"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type === 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Club Member Size:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.clubMemberSize }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.clubMemberSize"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type === 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Number of Aircrafts:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.clubNumAircrafts }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.clubNumAircrafts"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type === 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Club Website URL:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.clubWebsiteUrl }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.clubWebsiteUrl"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type === 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Club Email Address:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.clubEmail }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.clubEmail"
                  class="input"
                >
              </div>
            </div>
          </div>
          <div
            v-if="share.type === 'FLIGHT_CLUB'"
            class="col col-sm-24"
          >
            <div class="pair split">
              <div class="name">
                Club Phone Number:
              </div>
              <div
                v-if="!editing"
                class="value"
              >
                {{ share.clubPhone }}
              </div>
              <div
                v-else
                class="value"
              >
                <input
                  v-model="form.clubPhone"
                  class="input"
                >
              </div>
            </div>
          </div>

          <div
            v-if="editing"
            class="error col col-sm-24"
          >
            <div class="fail">
              <ul>
                <li
                  v-for="(error,i) in errors"
                  :key="i"
                >
                  {{ error.message }}
                </li>
              </ul>
            </div>
            <button
              button="button"
              class="btn btn-yellow"
              @click="submit"
            >
              <span v-if="!isUpdating">Update</span>
              <span v-else><fa-icon
                icon="circle-notch"
                spin
              /></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaAirportSelect from '@/components/AirportSelect';
import SmaMediaDropzone from '@/components/MediaDropzone';
import SmaMediaThumbnail from '@/components/MediaThumbnail';
import {
  ADD_FAVORITE_SHARE,
  REMOVE_FAVORITE_SHARE,
  HIDE_MODAL,
  DELETE_SHARE,
  UPDATE_SHARE,
  ENABLE_SHARE,
  DISABLE_SHARE
} from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';
import {
  getTailNumberErrors,
  getTitleErrors,
  getDescriptionErrors,
  getMakeErrors,
  getModelErrors,
  getRateErrors,
  getBusinessNameErrors,
  getClubNameErrors,
  getClubRepNameErrors,
  getClubMemberSizeErrors,
  getClubNumAircraftsErrors,
  getClubWebsiteUrlErrors,
  getClubEmailErrors,
  getClubPhoneErrors,
  getUrlErrors
} from '@/utils/validators';

export default {
  name: 'SmaShareModal',

  components: {
    SmaAirportSelect,
    SmaMediaDropzone,
    SmaMediaThumbnail
  },

  data() {
    return {
      editing: false,
      form: {
        regOwner: '',
        title: '',
        description: '',
        url: '',
        ratePerHour: '',
        isWet: true,
        airport: '',
        make: '',
        model: '',
        year: '',
        engineHours: '',
        engineManufacturer: '',
        engineModel: '',
        classification: '',
        categories: '',
        instrumentRatingRequired: false,
        equipment: '',
        interior: '',
        exterior: '',
        avionics: '',
        comments: '',
        tags: {},
        media: [],
        defaultMedia: null,
        clubName: '',
        clubRepName: '',
        clubMemberSize: '',
        clubNumAircrafts: '',
        clubWebsiteUrl: '',
        clubEmail: '',
        clubPhone: ''
      },
      isUpdating: false,
      errors: [],
      tagNames: [
        'Single Engine',
        'Amphibious/Float',
        'Light Sport',
        'Multi-Engine',
        'Acrobatic',
        'Turbo',
        'Bi-Plane',
        'Hot-Air Balloon',
        'Jet',
        'UltraLight',
        'Helicopter',
        'Tail Wheel',
        'Homebuilt',
        'Experimental'
      ],
      sliderInit: {
        currentPage: 0,
        thresholdDistance: 100,
        thresholdTime: 100,
        loop: true,
        direction: 'horizontal',
        infinite: 1,
        slidesToScroll: 1,
        timingFunction: 'ease',
        duration: 300
      }
    };
  },

  computed: {
    ...mapGetters([
      'activeModal',
      'activeModalName',
      'getShareById',
      'error',
      'me'
    ]),

    sliderPages() {
      return this.share.media.map(media => ({
        media,
        component: {
          props: ['item', 'sliderinit', 'pages'],
          components: { SmaMediaThumbnail },
          computed: {
            media() {
              return this.item.media;
            }
          },
          template: '<sma-media-thumbnail :media="media" :delButton="false" :rotateButtons="false" class="aspect-ratio-1-1" />'
        }
      }));
    },

    share() {
      const tmp = this.$store.state.shares.reactHack; // eslint-disable-line no-unused-vars

      const share = this.$store.state.shares.records[this.activeModal.shareId];

      return share;
    },

    title() {
      return [this.share.year, this.share.make, this.share.model]
        .filter(x => x)
        .join(' ');
    },

    address() {
      return `${this.share.airport.city}, ${this.share.airport.state}`;
    },

    isFavorited() {
      const faves = this.$store.getters.favoriteShares;

      return !!faves.find(item => item.id === this.share.id);
    },

    isOwner() {
      return this.me && this.me.id === this.share.owner.id;
    }
  },

  watch: {
    activeModal(activeModal) {
      if (activeModal && activeModal.shareId) {
        this.$router.replace({
          query: { ...this.$route.query, share: activeModal.shareId }
        });
      } else {
        const query = { ...this.$route.query };
        delete query.share;

        this.$router.replace({
          query
        });
      }
    },

    activeModalName() {
      this.cancel();
    }
  },

  methods: {
    hide() {
      this.email = '';
      this.$store.dispatch(HIDE_MODAL);
    },

    getBackgroundStyle() {
      const media = this.share && this.share.media;
      const url = media && media[0] && media[0].url;

      return {
        ...getBackgroundStyle(url),
        width: '100%'
      };
    },

    getEmailHref() {
      console.log(this.share.owner);
      const { email } = this.share.owner;

      if (email) {
        return `mailto:${email}?subject=Share inquiry from sharemyaircraft.com`;
      }

      return '#';
    },

    clickMail() {
      const mailto = this.getEmailHref();
      function nav() {
        window.location.href = mailto;
      }

      setTimeout(nav, 1000);

      this.$ga.event({
        eventCategory: 'Share',
        eventAction: 'sendMail',
        eventLabel: `${process.env.WEB_URL}/?share=${this.share.id}`,
        hitCallback: nav
      });
    },

    copyToClipboard() {
      const textArea = document.createElement('textarea');

      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      textArea.value = `${process.env.WEB_URL}?share=${this.share.id}`;

      document.body.appendChild(textArea);

      textArea.focus();
      textArea.select();

      document.execCommand('copy');

      document.body.removeChild(textArea);
    },

    addFavorite() {
      this.$store.dispatch(ADD_FAVORITE_SHARE, this.share);
    },

    removeFavorite() {
      this.$store.dispatch(REMOVE_FAVORITE_SHARE, this.share);
    },

    addMedia(media) {
      this.form.media = this.form.media.concat(media);

      for (let j = 0; j < this.form.media.length; j++) {
        if (this.form.media[j].type === 'image') {
          this.form.defaultMedia = j;
          break;
        }
      }
    },

    setMedia(i, media) {
      this.$set(this.form.media, i, media);
    },

    removeMedia(i) {
      this.form.media.splice(i, 1);

      if (this.form.defaultMedia === 1) {
        for (let j = 0; j < this.form.media.length; j++) {
          if (this.form.media[j].type === 'image') {
            this.form.defaultMedia = j;
            break;
          }
        }
      }
    },

    setDefault(i) {
      if (this.form.media[i].type === 'image') {
        this.form.defaultMedia = i;
      }
    },

    edit() {
      this.form = { ...this.share };

      this.form.tags = {};
      this.share.tags.forEach((tag) => {
        this.form.tags[tag] = true;
      });
      this.form.categories = this.share.categories.join(', ');
      this.form.media = Array.from(this.share.media);

      this.editing = true;
    },

    getErrors() {
      let errors = [];

      if (this.form.type !== 'FLIGHT_CLUB') {
        errors = errors.concat(
          getDescriptionErrors(this.form.description),
          getMakeErrors(this.form.make),
          getModelErrors(this.form.model),
          getUrlErrors(this.form.url)
        );
      }

      if (this.form.type === 'PRIVATE_OWNER_SHARE_PURCHASE') {
        errors = errors.concat(
          getTailNumberErrors(this.form.tailNumber),
          getTitleErrors(this.form.title)
        );
      } else if (this.form.type === 'FBO_RENTAL') {
        errors = errors.concat(
          getTailNumberErrors(this.form.tailNumber),
          getBusinessNameErrors(this.form.businessName),
          getTitleErrors(this.form.title),
          getRateErrors(this.form.ratePerHour)
        );
      } else if (this.form.type === 'PRIVATE_OWNER_RENTAL') {
        errors = errors.concat(
          getTailNumberErrors(this.form.tailNumber),
          getTitleErrors(this.form.title),
          getRateErrors(this.form.ratePerHour)
        );
      } else if (this.form.type === 'FLIGHT_CLUB') {
        errors = errors.concat(
          getClubNameErrors(this.form.clubName),
          getClubRepNameErrors(this.form.clubRepName),
          getClubMemberSizeErrors(this.form.clubMemberSize),
          getClubNumAircraftsErrors(this.form.clubNumAircrafts),
          getClubWebsiteUrlErrors(this.form.clubWebsiteUrl),
          getClubEmailErrors(this.form.clubEmail),
          getClubPhoneErrors(this.form.clubPhone)
        );
      }

      return errors;
    },

    async submit() {
      this.errors = this.getErrors();
      if (this.errors.length > 0) {
        return;
      }

      const tags = [];
      this.tagNames.forEach((tagName) => {
        if (this.form.tags[tagName]) { tags.push(tagName); }
      });

      const categories = this.form.categories.split(',');
      for (let i = 0; i < categories.length; i++) {
        categories[i] = categories[i].trim();
      }

      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;

      try {
        await this.$store.dispatch(UPDATE_SHARE, {
          ...this.form,
          id: this.share.id,
          aircraftId: this.share.aircraftId,
          tags,
          categories
        });
      } catch (err) {
        console.error(err);
        return;
      } finally {
        this.isUpdating = false;
      }

      this.cancel();
    },

    cancel() {
      this.editing = false;
      this.form = {};
    },

    async remove() {
      this.$store.dispatch(DELETE_SHARE, this.share.id)
        .catch(err => console.error(err));
      this.$store.dispatch(HIDE_MODAL);
    },

    async enable() {
      try {
        await this.$store.dispatch(ENABLE_SHARE, { share: this.share });
      } catch (err) {
        console.error(err);
      }
    },

    async disable() {
      try {
        await this.$store.dispatch(DISABLE_SHARE, { share: this.share });
      } catch (err) {
        console.error(err);
      }
    },

    slideNext() {
      this.$refs.slider.$emit('slideNext');
    },

    slidePre() {
      this.$refs.slider.$emit('slidePre');
    }
  }
};
</script>

<style lang="scss" scoped>
.airport-id {
  color: $sma-blue;
  font-size: 1rem;
}

.content {
  padding: 2rem;
}

.email {
  color: $sma-blue;
  cursor: pointer;
}

.image {
  background-color: $sma-near-white;
}

.buttons {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  button {
    cursor: pointer;
    font-size: 0.9em;
    color: $sma-blue;
    border: 0;
    background: transparent;
  }

  .icon {
    margin-right: 0.3rem;
  }

  .favorite,
  .copytoclipboard,
  .delete,
  .disable {
    margin-left: 1rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-left: 0;
    }
  }
}

.top {
  display: flex;
  flex-direction: column;
}

.description {
  font-size: 0.875rem;
  height: 100%;
  margin-top: 2em;

  & textarea {
    width: 100%;
    height: 100%;
    border: 1px solid $sma-gray;
  }
}

/*
.button {
  align-self: center;
  color: $sma-yellow;
  background-color: $sma-white;
  border: 1px solid $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-top: 3rem;
}
*/

.price {
  width: 100px;
  border-bottom: 1px solid $sma-gray;
  color: $sma-gray;
}

.pair {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  flex-wrap: wrap;
}

.name {
  font-weight: 600;
  margin-right: 0.5em;
  min-width: 220px;
  text-align: right;

  @media screen and (max-width: $breakpoint-sm) {
    min-width: 100%;
    text-align: left;
  }
}

.value {
  flex: 1;
  color: $sma-gray;

  & input {
    width: 100%;
    border-bottom: 1px solid $sma-gray;
    color: $sma-gray;
  }
}

.split .value {
  width: 100%;
}

.slider-outer {
  position: relative;
  max-width: 500px;
  max-height: 500px;
}

.slide-pre {
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 2;
  color: $sma-white;
}

.slide-next {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 2;
  color: $sma-white;
}

.airport-select {
  margin-top: -6px !important;
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}

[default-media] {
  box-shadow: 0 0 20px 10px $sma-blue;
}

.error {
  display: flex;
  flex-direction: column;
}

.drop-text {
  margin-top: 1rem;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
}

.tail-number {
  margin-top: 0;
}
</style>
