<template>
  <button
    class="search-card"
    @click="showModal"
  >
    <div class="row">
      <div class="col col-8">
        <div
          class="image aspect-ratio-1-1"
          :style="backgroundStyle"
        />
      </div>

      <div class="details col col-8">
        <div class="type">
          <!-- <fa-icon
            :icon="icon"
            class="icon"
          /> -->
          <img
            :src="icon"
            class="icon"
          >
          {{ type }}
        </div>

        <div class="title">
          {{ title }}
        </div>

        <div
          v-if="desc"
          class="desc"
        >
          {{ desc }}
        </div>

        <div
          v-if="shareType"
          class="share-type"
        >
          {{ shareType }}
        </div>

        <div
          v-if="rate"
          class="rate"
        >
          {{ rate }}
        </div>

        <div class="location">
          <fa-icon
            icon="map-marker-alt"
            class="marker"
          />
          <div class="address">
            AIRPORT: {{ search.airport }}
          </div>
        </div>

        <div class="location">
          <div class="address">
            {{ address }}
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
/* eslint-disable global-require */

import { SHOW_MODAL } from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';
import getMediaThumbnail from '@/utils/get-media-thumbnail';
import pilotDefaultAvatarUrl from '@/assets/pilot-default.jpg';

export default {
  name: 'SmaSearcheCard',

  props: {
    search: { type: Object, required: true }
  },

  computed: {
    imageUrl() {
      let media;

      switch (this.search.type) {
        case 'user':
        case 'instructor':
          media = this.search.data?.avatar || { type: 'image', url: pilotDefaultAvatarUrl };
          break;

        case 'share': {
          const share = this.search.data;

          let defaultMedia = share?.defaultMedia;

          if (defaultMedia >= share?.media?.length) {
            defaultMedia = 0;
          }

          media = share?.media?.[defaultMedia];
          break;
        }

        default:
      }

      return getMediaThumbnail(media);
    },

    backgroundStyle() {
      return getBackgroundStyle(this.imageUrl);
    },

    icon() {
      switch (this.search.type) {
        case 'user': return require('@/assets/icons/pilot.png');
        case 'instructor': return require('@/assets/icons/professional.png');
        case 'share': return require('@/assets/icons/plane.png');
        default: return null;
      }
    },

    type() {
      switch (this.search.type) {
        case 'user': return 'Pilot';
        case 'instructor': return 'Professional';
        case 'share': return 'Plane';
        default: return null;
      }
    },

    title() {
      const { data } = this.search;

      switch (this.search.type) {
        case 'user':
        case 'instructor':
          return [data.first, data.last]
            .filter(x => x)
            .join(' ');

        case 'share':
          return data.title;

        default:
          return null;
      }
    },

    desc() {
      const { data } = this.search;

      switch (this.search.type) {
        case 'user':
          return null;

        case 'instructor':
          return null;

        case 'share':
          return [data.year, data.make, data.model]
            .filter(x => x)
            .join(' ');

        default:
          return null;
      }
    },

    shareType() {
      const { data } = this.search;

      if (this.search.type === 'share') {
        switch (data.type) {
          case 'PRIVATE_OWNER_RENTAL': return 'PRIVATE OWNER RENTAL';
          case 'PRIVATE_OWNER_SHARE_PURCHASE': return 'PRIVATE OWNER SHARE PURCHASE';
          case 'PARTNERSHIP_REQUEST_TO_PURCHASE': return 'PARTNERSHIP REQUEST TO PURCHASE';
          case 'FBO_RENTAL': return 'FLIGHT SCHOOL RENTAL';
          case 'FLIGHT_CLUB': return 'FLYING CLUB';
          default:
        }
      }

      return null;
    },

    rate() {
      const { data } = this.search;

      switch (this.search.type) {
        case 'user':
          return null;

        case 'instructor':
          return `$${data.ratePerHour} / hour`;

        case 'share':
          if (data.type === 'PRIVATE_OWNER_SHARE_PURCHASE') {
            if (data.pricePercentOffered && data.sharePercentOffered) {
              return `$${data.pricePercentOffered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} for ${data.sharePercentOffered} share`;
            }
            return 'Ask for cost';
          }

          if (data.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE') {
            if (data.cost) {
              return `Approx $${data.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
            return 'Ask for cost';
          }

          if (data.type === 'FBO_RENTAL') {
            if (data.ratePerHour) {
              return `$${data.ratePerHour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} / hour wet`;
            }
            return 'Ask for rate';
          }


          if (data.ratePerHour) {
            return `$${data.ratePerHour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} / hour`;
          }
          return 'Ask for rate';

        default:
          return null;
      }
    },

    address() {
      const { data } = this.search;

      return data.airport
        ? `${data.airport.city}, ${data.airport.state}`
        : '';
    }

  },

  methods: {
    showModal() {
      switch (this.search.type) {
        case 'user':
          this.$store.dispatch(SHOW_MODAL, {
            name: 'user',
            userId: this.search.data.id
          });
          break;

        case 'instructor':
          this.$store.dispatch(SHOW_MODAL, {
            name: 'instructor',
            instructorId: this.search.data.id
          });
          break;

        case 'share':
          this.$store.dispatch(SHOW_MODAL, {
            name: 'share',
            shareId: this.search.data.id
          });
          break;

        default:
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-card {
  display: flex;
  flex-direction: column;
  background-color: $sma-white;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
  align-items: stretch;
  border: 0;
  cursor: pointer;
  padding: 8px;
}

.image {
  width: 100%;
}

.details {
  display: block;
  flex: 1;
  flex-direction: column;

  .type {
    font-size: 16px;
    color: $sma-blue;
    font-weight: 600;
    display: flex;
    align-items: center;

    .icon {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }

  .title {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 600;
  }

  .desc {
    margin-top: 8px;
    font-size: 16px;
  }

  .share-type {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 100;
  }

  .rate {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 100;
  }
}

.location {
  margin-top: 8px;
  display: flex;
  font-size: 0.875rem;

  .marker {
    color: $sma-blue;
    margin-right: 0.5rem;
    margin-top: 0.125rem;
  }

  .address {
    color: $sma-dark-gray;
  }
}
</style>
