import { request } from 'axios';

export default async function fetchLatLng({ address }) {
  const response = await request({
    url: 'https://maps.googleapis.com/maps/api/geocode/json',
    params: {
      address,
      key: process.env.GOOGLE_MAPS_API_KEY
    }
  });

  const result = response.data.results && response.data.results[0];
  const location = result && result.geometry && result.geometry.location;

  return (
    location && {
      lat: location.lat,
      lng: location.lng
    }
  );
}
