<template>
  <div class="blog-page">
    <div
      v-if="postPreviews"
      class="container"
    >
      <div class="row">
        <div class="col col-sm-24">
          <h1>Blog</h1>
        </div>
      </div>

      <div class="row post-previews">
        <!-- <router-link
          v-for="postPreview of postPreviews"
          :key="postPreview.id"
          :to="{ name: 'blog-post', params: { slug: postPreview.slug } }"
          class="col col-sm-12 col-md-8"
        >
          <article class="post-preview">
            <div class="post-preview-img-container">
              <img
                :src="getFeaturedMediaUrl(postPreview)"
                class="post-preview-img"
              >
            </div>
            <h2 class="post-preview-title">
              {{ postPreview.title.rendered }}
            </h2>
            <div class="post-preview-date">
              {{ getDate(postPreview) }}
            </div>
          </article>
        </router-link> -->
        <blog-post-preview
          v-for="postPreview of postPreviews"
          :key="postPreview.id"
          :value="postPreview"
          class="col col-sm-12 col-md-8"
        />
      </div>

      <div class="row">
        <div class="col pagination">
          <!-- First -->
          <router-link
            v-if="page > 3"
            :to="{ query: { page: 1 } }"
          >
            <fa-icon icon="angle-double-left" />
          </router-link>

          <span v-if="page > 3">...</span>

          <!-- N - 2 -->
          <router-link
            v-if="page > 2"
            :to="{ query: { page: page - 2 } }"
          >
            {{ page - 2 }}
          </router-link>

          <!-- N - 1 -->
          <router-link
            v-if="page > 1"
            :to="{ query: { page: page - 1 } }"
          >
            {{ page - 1 }}
          </router-link>

          <!-- N -->
          <span
            class="current-page"
            :to="{ query: { page: page } }"
          >
            {{ page }}
          </span>

          <!-- N + 1 -->
          <router-link
            v-if="page < totalPages"
            :to="{ query: { page: page + 1 } }"
          >
            {{ page + 1 }}
          </router-link>

          <!-- N + 2 -->
          <router-link
            v-if="page < totalPages - 1"
            :to="{ query: { page: page + 2 } }"
          >
            {{ page + 2 }}
          </router-link>

          <span v-if="page < totalPages - 2">...</span>

          <!-- Last -->
          <router-link
            v-if="page < totalPages - 2"
            :to="{ query: { page: totalPages } }"
          >
            <fa-icon icon="angle-double-right" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WP_FETCH_POST_PREVIEWS } from '@/store/action-types';
import { mapGetters } from 'vuex';
import logoUrl from '@/assets/logo.png';
import BlogPostPreview from '@/components/BlogPostPreview';

const dateFormat = new Intl.DateTimeFormat('en-US');

export default {
  name: 'SmaBlog',

  components: {
    BlogPostPreview
  },

  computed: {
    ...mapGetters({
      postPreviews: 'wpPostPreviews',
      page: 'wpPage',
      totalPages: 'wpTotalPages'
    })
  },

  watch: {
    '$route.query': {
      handler() {
        this.search();
      },
      deep: true
    }
  },

  async serverPrefetch() {
    await this.search();
  },

  async mounted() {
    if (this.postPreviews.length === 0) {
      await this.search();
    }
  },

  methods: {
    getFeaturedMediaUrl(postPreview) {
      // eslint-disable-next-line camelcase
      return postPreview?._embedded?.['wp:featuredmedia']?.[0]?.source_url || logoUrl;
    },

    getDate(postPreview) {
      return dateFormat.format(new Date(postPreview.date));
    },

    async search() {
      const page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      await this.$store.dispatch(WP_FETCH_POST_PREVIEWS, { page });
    }
  }
};
</script>

<style lang="scss" scoped>
.blog-page {
  .col {
    margin-bottom: 2rem;
  }
}

.container {
  padding: 0 2rem;
  margin-bottom: 64px;

  @media (min-width: $breakpoint-sm) {
    padding: 0 5rem;
  }
}

.post-previews {
  margin-top: 32px;
}

.post-preview {
  .post-preview-img-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  .post-preview-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.pagination {
  text-align: center;
  font-size: 24px;
  margin-top: 32px;

  .current-page {
    font-size: 1.2em;
    font-weight: 400;
  }
}
</style>
