<template>
  <div
    v-if="activeModalName === 'video'"
    class="video-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content">
        <!-- Change This -->
        <div class="col col-sm-18">
          <iframe
            :src="myVideo"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="element"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { HIDE_MODAL } from '@/store/action-types';

export default {
  name: 'SmaVideoModal',

  computed: {
    ...mapGetters([
      'activeModalName',
      'activeModal'
    ]),

    myVideo() {
      return this.activeModal.videoURL;
    }
  },

  methods: {
    hide() {
      this.username = '';
      this.password = '';

      this.$store.dispatch(HIDE_MODAL);
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  @media only screen and (max-width: $breakpoint-sm) {
    max-width: 90%;
  }
}

.button {
  display: block;
  cursor: pointer;
  padding: 0.5rem 4rem;
  margin: 0.5rem auto;
  border: 1px solid $sma-yellow;
  color: $sma-yellow;
  border-radius: 10rem;
  white-space: nowrap;
  text-align: center;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.element {
  margin-top: 1.2rem;
  width: 85vw;
  height: 75vh;
}
</style>
