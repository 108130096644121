<template>
  <div class="my-shares-page">
    <div class="container">
      <div class="row shares">
        <div class="col center">
          <h2>My Shares</h2>
        </div>
        <div
          v-for="share in myShares"
          :key="share.id"
          class="col col-flex col-sm-12 col-md-6"
        >
          <sma-share-card
            class="share"
            :share="share"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaShareCard from '@/components/ShareCard';
import { FETCH_MY_SHARES } from '@/store/action-types';

export default {
  name: 'SmaMyShares',

  components: {
    SmaShareCard
  },

  computed: {
    ...mapGetters([
      'myShares'
    ])
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$store.dispatch(FETCH_MY_SHARES, {
        page: 1,
        limit: 12
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;

  &.border {
    border: 0 solid $sma-light-gray;
    border-bottom-width: 1px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 1rem;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $sma-light-gray;
  transition: 0.4s;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: $sma-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $sma-blue;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;

  &::before {
    border-radius: 50%;
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
