<template>
  <div class="post-location-page">
    <div class="container">
      <div class="row">
        <sma-progress
          class="col"
          :step="-1"
          :steps="steps"
        />
      </div>
      <h1>SELECT A LISTING TYPE:</h1>
      <div class="row form">
        <button
          class="col button"
          @click="submit('PRIVATE_OWNER_RENTAL')"
        >
          PRIVATE OWNER RENTAL
          <div class="tooltip">
            You are sharing your aircraft with a limited number of other qualified pilots (3-4) in pre-paid blocks hours 5/10 etc.
          </div>
        </button>

        <button
          class="col button"
          @click="submit('PRIVATE_OWNER_SHARE_PURCHASE')"
        >
          PRIVATE OWNER SHARE PURCHASE
          <div class="tooltip">
            You are looking to have another pilot buy an equity share in your aircraft such as 1/3, 1/4 etc, where they will own the aircraft with you and share expenses according to their ownership percentage.
          </div>
        </button>

        <button
          class="col button"
          @click="submit('PARTNERSHIP_REQUEST_TO_PURCHASE')"
        >
          PARTNERSHIP TO PURCHASE NEW OR USED AIRCRAFT
          <div class="tooltip">
            You are interested in buying a new or used aircraft at a specific airport with a number of other pilots, thereby sharing all the expenses according to their ownership percentage.
          </div>
        </button>

        <button
          class="col button"
          @click="submit('FBO_RENTAL')"
        >
          FLIGHT SCHOOL RENTAL
          <div class="tooltip">
            You are a flight school that rents planes to qualified pilots.
          </div>
        </button>

        <button
          class="col button"
          @click="submit('FLIGHT_CLUB')"
        >
          FLYING CLUB
          <div class="tooltip">
            You are a flying club.
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaProgress from '@/components/Progress';
import { CREATE_POST_IN_PROGRESS } from '@/store/action-types';

export default {
  name: 'SmaPostType',

  components: {
    SmaProgress
  },

  data() {
    return {
      showSubDialog: false,
      steps: [
        ['General', 'Information'],
        ['Your plane', 'location'],
        ['Add Photos', '& Video'],
        ['Preferred Flight', 'Instructor'],
        ['Preview &', 'Confirm']
      ]
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'activeModal'
    ])
  },

  methods: {
    submit(type) {
      this.$store.dispatch(CREATE_POST_IN_PROGRESS, { type });

      this.$router.push({
        name: 'post-general'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.post-location-page {
  padding: 2rem;
}

h1 {
  text-align: center;
}

.row {
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .col {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    margin: 0.5rem 0;
  }
}

.button {
  align-self: center;
  color: $sma-yellow;
  background-color: $sma-white;
  border: 1px solid $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-top: 5rem;
  width: 600px;
  max-width: 100%;
  position: relative;
}

.button:hover {
  background-color: $sma-yellow;
  color: $sma-white;
}

.button .tooltip {
  opacity: 0;
  width: 300px;
  background-color: $sma-blue;
  color: $sma-white;
  text-align: center;
  position: absolute;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 10px;
  z-index: 1;
  top: -5px;
  left: 105%;
  transition: opacity 0.25s;
}

.button:hover .tooltip {
  opacity: 1;
}

.button .tooltip::after {
  content: " ";
  position: absolute;
  top: 1.5rem;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $sma-blue transparent transparent;
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}
</style>
