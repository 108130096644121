import { request } from 'axios';

import { version } from '../../../package.json';

function getContentTypeHeaders({ data, json }) {
  if (json) {
    return {
      'Content-Type': 'application/json'
    };
  }

  if (typeof data === 'string') {
    return {
      'Content-Type': 'text/plain'
    };
  }

  if (data) {
    return {
      'Content-Type': 'multipart/form-data'
    };
  }

  return null;
}

function getAuthorizationHeaders({ userToken }) {
  if (userToken) {
    return {
      Authorization: `Bearer ${userToken}`
    };
  }

  return null;
}

function getContent({ data, json }) {
  if (json) {
    return JSON.stringify(json);
  }

  return data;
}

export default async function fetchApi(options) {
  const config = {
    method: options.method,
    url: options.path,
    baseURL: process.env.API_URL,
    params: options.query,
    data: getContent(options),
    withCredentials: true,
    headers: {
      'X-Application-Version': version,
      ...getAuthorizationHeaders(options),
      ...getContentTypeHeaders(options)
    }
  };

  if (global.req) {
    config.headers.cookie = global.req.headers.cookie;
  }

  if (process.env.API_URL) {
    const response = request(config);

    return response;
  }

  const err = new Error('Mock not implemented');

  err.response = {
    status: 503
  };

  throw err;
}
