<template>
  <div class="account-sidebar tabs">
    <router-link
      v-for="button in buttons"
      :key="button.name"
      :class="{ button: true, ghost: button.name !== $route.name }"
      exact
      :to=" { name: button.name }"
    >
      {{ button.text }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SmaAccountSidebar',

  data() {
    return {
      buttons: [
        { name: 'manage-account', text: 'Account' },
        { name: 'manage-listings', text: 'My Planes' },
        { name: 'favorites', text: 'Favorites' },
        { name: 'manage-favorite-airports', text: 'New Listing Alerts' }
        // { name: 'manage-subscription', text: 'Subscription' }
      ]
    };
  },

  computed: {
    ...mapGetters([
      'me'
    ])
  },

  created() {
    if (this.me && this.me.isInstructor) {
      this.buttons.splice(1, 0, { name: 'manage-instructor', text: 'Professional Info' });
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  .button {
    align-self: center;
    color: $sma-white;
    background-color: $sma-yellow;
    border-radius: 10rem;
    padding: 0.5rem 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;

    &.ghost {
      background-color: transparent;
      border: 1px solid $sma-yellow;
      color: $sma-yellow;
    }
  }
}
</style>
