/** @type {import('vue').Component} */
const ClientOnly = {
  name: 'ClientOnly',

  render(h) {
    if (process.server) {
      return null;
    }

    return this.$slots.default;
  }
};

export default ClientOnly;
