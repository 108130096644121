<template>
  <div class="earnings-calc info-page">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>Earnings Calculator</h1>
          <p>Sharing an aircraft is common practice among pilots however difficult to initiate and consummate. Bulletin boards at the 5000 + general aviation airports are littered with notices looking for pilots to share an aircraft, a hangar or even a flight! It’s a way that many pilots can offset their fixed expenses with little or no impact to their personal flying, and in the process make new friend and have new experiences!</p>
          <p>When SHARING your plane, we can figure out the yearly income using a few basic assumptions.  Of course, these can change based on your specific situation!  So let’s assume the following:</p>
          <ul>
            <li>The average pilot flies 3.3 hours per month or approximately 40 hours/year</li>
            <li>You will SHARE your plane with 2 other pilots (so 80 hours/year)</li>
            <li>A good “Average” price/hour (this will change based on your make/model/year/avionics/etc.) is $175/hour DRY</li>
          </ul>
          <p>Post your plane and  (after meeting and Vetting the potential sharer), it’s an easy telephone call to your insurance agent to convert your policy to a limited renter’s policy. It’s quick, easy, and there are many companies that specialize in this.</p>
          <p>See our insurance section for a more detailed explanation of how sharing works with insurance. It’s easier than you probably think.  Then, owners will want to put in place, and sharers will want to agree to, a simple agreement detailing the terms of the share to best fit the specific terms of your sharing situation.</p>
          <p>Once the agreement is in place, all you have to do is collect the checks and put your aircraft to work for you. Whether your share partners buy their blocks of time in five or ten hour increments, your flying just became more economical!!</p>
        </div>

        <div class="col">
          <strong><em>Enter your estimates below:</em></strong>
        </div>

        <!-- Questionnaire -->
        <form
          class="col form col-sm-20"
          @submit.prevent="calculate"
        >
          <div class="row">
            <div class="col col-sm-6">
              <label for="rate">Hourly Rate (Dry)</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="rate"
                v-model="hourlyRate"
                class="input text"
                name="rate"
                type="number"
                step="0.01"
                placeholder="175"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-6">
              <label for="num-pilots">Number of pilots</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="num-pilots"
                v-model="numPilots"
                class="input text"
                name="num-pilots"
                type="number"
                step="1"
                placeholder="2"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-6">
              <label for="fly-time">Flyer Time (Hours per Year)</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="fly-time"
                v-model="flyTime"
                class="input text"
                name="fly-time"
                type="number"
                step="1"
                placeholder="40"
                required
              >
            </div>
          </div>

          <div class="row">
            <button class="btn yellow">
              Calculate
            </button>
          </div>
        </form>

        <!-- Results -->
        <div
          v-if="earnings !== null"
          class="col col-sm-20"
        >
          <div class="col">
            <h3>Results</h3>
          </div>

          <div class="col">
            <p>Earnings: ${{ earnings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} / year</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmaEarningsCalc',
  data() {
    return {
      hourlyRate: null,
      numPilots: null,
      flyTime: null,
      earnings: null
    };
  },
  methods: {
    calculate() {
      this.earnings = this.hourlyRate * this.flyTime * this.numPilots;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
}

.form {
  margin-top: 2rem;
}

label {
  text-align: left;

  @media only screen and (min-width: 48em) {
    text-align: right;
  }
}

.btn {
  margin: 5rem auto 0;
}
</style>
