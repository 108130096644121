<template>
  <div class="story-page info-page">
    <div class="container">
      <h1>Our Story</h1>
      <p>In 2007 I bought a used Cirrus SR22, but like most pilots, I was only flying about 4-6 hours a month (FYI, according to the FAA, the average general aviation plane sits on the ground 94% of the time, with the average pilot flying just 3.3 hours/month). However, in spite of only using the plane for this small amount of time, the expenses did not stop Garmin 430 updates, monthly subscriptions, insurance, hanger fees, routine maintenance, and then came the dreaded annual.  I was spending about $15,000/year, or $350/hour to fly my own plane! I decided to try and find another experienced pilot to share my plane, so I posted flyers on the bulletin board at my local airport with no luck, and then finally listed the partnership on the Cirrus Owners website (cirruspilots.org). Here is my original post from 2008:</p>
      <img
        src="@/assets/story1.jpg"
        class="half"
      >
      <p>To my surprise, I quickly got a number of calls from local highly-qualified pilots (instrument ratings and high time-in-type) and settled on two pilots who were signed-off by my local flight instructor, added to my insurance (at no additional charge) and who paid me 10-hour blocks in advance to fly the plane.</p>
      <img
        src="@/assets/raj.jpg"
        class="raj"
        title="Raj and Jill on their way to Oshkosh 2018 in the Husky A1-B — A shared plane!"
      >
      <p>The plane was flying and happy and well kept, I was flying and happy, and best of all, I was making valuable money to cover my fixed expenses and then some.  This dramatically changed the aircraft ownership equation for me. It was a win/win and the best part was even with all three of us flying all we wanted, there were few, if any conflicts, easily resolved!  I was getting $185/hour dry and this was awesome.  My wife Jill said to me, “hey there might be something here – this is really needed and could be great for a pilot and general aviation overall.” – as I explored this further, I found that many pilots already shared their planes, but the process of finding other pilots was difficult and inefficient.  General aviation airports bulletin boards are littered with flyers from pilots looking for airplane shares, hanger shares and ownership partnerships, and there was no single website where one could post or find a share, but there were many fragmented owners’ sites, where shares were posted in forums, only to be lost, when the next post was added.</p>
      <h2>These are just a few postings at KJYO - There are 6300+ general aviation airports in the US</h2>
      <img
        src="@/assets/posters.png"
        class="posters"
      >
      <p>There had to be a better way and so came the idea of - SHAREMYAIRCRAFT.COM.  SMA was shelved for a number of years due to the economy, until we finally decided it was the right time – so about in late 2016, we started working on SMA – and now everything is lining up to make this the best time for SMA!  Why?</p>
      <ol>
        <li>Aircraft are more expensive than ever to buy and maintain so pilots can really use the added income from a sharer – just to make it all work! Flying magazine (AUG 2018) recently noted that “The price of a new Cessna Skyhawk in 1968 took 16 Months (of one’s income) to buy, where today it takes six years to purchase. Clearly airplane prices have far outstripped general inflation!” –  (FYI - We will soon be allowing pilots to post partnership requests for buying a new plane at their specific airport, a great way to buy a new plane for a fraction of the costs)</li>
        <li>General aviation has a new influx of pilots from the passing of FAA’s BASIC MED program, where over 30,000 experienced pilots (and growing every day) have reentered active flight and will need planes!</li>
        <li>There are so many technologically advanced, innovative and LSA planes coming out that could easily and financially comfortably be shared with 2-3 (or more pilots) that would be great additions to their existing plane, such as the ICON A-5, Vashon Ranger (&lt;100k), many new electric options and the Carbon Cub, just to mention a few - !  Fun planes to have in addition to your primary plane.</li>
        <li>So many pilots would love to fly other types of planes in addition to their primary plane, such as acrobatic, tail-wheel, sea planes, etc – now you can!</li>
        <li>There are so many flight schools with planes to rent, but there is no one source to find and learn about the rental options…until now!</li>
        <li>There is no one source to find a local flight instructor and learn about their qualifications…until now!</li>
        <li>Finally, IT JUST MAKES SENSE:</li>
      </ol>
      <div class="indent">
        <strong>FINANCIAL SENSE</strong>
        <ul>
          <li>Cover fixed expenses and make money in the process</li>
          <li>Get into an airplane that resembles ownership for a fraction of what it normal cost</li>
        </ul>
        <strong>MECHANICAL SENSE</strong>
        <ul>
          <li>Keep the plane flying and running and the engine happy</li>
          <li>Keep the airframe used and maintained</li>
        </ul>
        <strong>SOCIAL AND RESPONSIBLE SENSE</strong>
        <ul>
          <li>Having an asset utilized only 6-7% of the time is a waste and irresponsible if another choice is available</li>
          <li>Meet other like-minded experienced pilots and develop relationships</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmaOurStory'
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
}

.half {
  width: 50%;
  float: left;
  margin-right: 1rem;
}

.raj {
  width: 22%;
  float: right;
  margin-left: 1rem;
}

.posters {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.indent {
  margin-left: 3rem;
}
</style>
