export default function debounce(ms, fn) {
  let timeout = null;

  function debounced(...args) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      return fn.call(this, ...args);
    }, ms);
  }

  return debounced;
}
