<template>
  <div class="post-media-page">
    <div class="container">
      <div class="row">
        <sma-progress
          class="col"
          :step="2"
          :steps="steps"
        />
      </div>
      <div class="col row">
        <div
          v-for="(media,i) in form.media"
          :key="media.url"
          class="col col-sm-6"
        >
          <sma-media-thumbnail
            class="aspect-ratio-3-2"
            :media="media"
            :default-media="i === form.defaultMedia"
            @click="setDefault(i)"
            @change="setMedia(i, $event)"
            @del="removeMedia(i)"
          />
        </div>
        <sma-media-dropzone
          class="col col-sm-6"
          @change="addMedia"
        />
      </div>
      <div>
        The highlighted image will be your default image for this listing. Click any image to make that your default image. Add as many pictures or videos as you would like. Images must be JPEG or PNG format.
      </div>
      <div class="fail">
        <ul>
          <li
            v-for="(error,i) in errors"
            :key="i"
          >
            {{ error.message }}
          </li>
        </ul>
      </div>
      <div class="row nav">
        <div class="col col-sm-12">
          <button
            class="btn yellow inv"
            @click="back"
          >
            Previous step
          </button>
        </div>
        <div class="col col-sm-12">
          <button
            class="btn yellow"
            @click="submit"
          >
            Next step
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

import SmaMediaDropzone from '@/components/MediaDropzone';
import SmaMediaThumbnail from '@/components/MediaThumbnail';
import SmaProgress from '@/components/Progress';
import { UPDATE_POST_IN_PROGRESS } from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';
import { getMediaErrors, getDefaultMediaErrors } from '@/utils/validators';

export default {
  name: 'SmaPostMedia',

  components: {
    SmaMediaDropzone,
    SmaMediaThumbnail,
    SmaProgress
  },

  data() {
    return {
      steps: [
        ['General', 'Information'],
        ['Your plane', 'location'],
        ['Add Photos', '& Video'],
        ['Preferred Flight', 'Instructor'],
        ['Preview &', 'Confirm']
      ],
      form: {
        media: [],
        defaultMedia: null
      },
      errors: []
    };
  },

  created() {
    if (!isEmpty(this.$store.state.post.inProgress)) {
      Object.assign(this.form, this.$store.state.post.inProgress);
    } else {
      this.$router.replace({ name: 'post' });
    }
  },

  methods: {
    getBackgroundStyle,

    addMedia(media) {
      this.form.media = this.form.media.concat(media);

      for (let j = 0; j < this.form.media.length; j++) {
        if (this.form.media[j].type === 'image') {
          this.form.defaultMedia = j;
          break;
        }
      }
    },

    setMedia(i, media) {
      this.$set(this.form.media, i, media);
    },

    removeMedia(i) {
      this.form.media.splice(i, 1);

      if (this.form.defaultMedia === 1) {
        for (let j = 0; j < this.form.media.length; j++) {
          if (this.form.media[j].type === 'image') {
            this.form.defaultMedia = j;
            break;
          }
        }
      }
    },

    setDefault(i) {
      if (this.form.media[i].type === 'image') {
        this.form.defaultMedia = i;
      }
    },

    back() {
      this.$router.replace({
        name: 'post-location'
      });
    },

    getErrors() {
      return [].concat(
        getMediaErrors(this.form.media),
        getDefaultMediaErrors(this.form.defaultMedia)
      );
    },

    submit() {
      this.errors = this.getErrors();
      if (this.errors.length > 0) {
        return;
      }

      this.$store.dispatch(UPDATE_POST_IN_PROGRESS, this.form);

      this.$router.push({
        name: 'post-preferred-instructor'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.post-media-page {
  padding: 2rem;
}

.row {
  margin-bottom: 2rem;
}

.media {
  margin: 0.5rem 0;
}

.nav .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .button {
    align-self: center;
    margin-top: 3rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
}

[default-media] {
  box-shadow: 0 0 20px 10px $sma-blue;
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}
</style>
