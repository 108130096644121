import { HIDE_MODAL, SHOW_MODAL } from '../action-types';
import { LOCATION_CHANGED, MODAL_HIDDEN, MODAL_VISIBLE } from '../mutation-types';

export default function createModalsModule() {
  return {
    state: () => ({
      active: null
    }),

    getters: {
      activeModal: state => state.active,
      activeModalName: state => state.active && state.active.name
    },

    mutations: {
      [LOCATION_CHANGED]: (state) => {
        state.active = null;
        if (process.client) {
          document.body.style.overflow = 'auto';
        }
      },

      [MODAL_HIDDEN]: (state) => {
        state.active = null;
        if (process.client) {
          document.body.style.overflow = 'auto';
        }
      },

      [MODAL_VISIBLE]: (state, options) => {
        state.active = options;
        if (process.client) {
          document.body.style.overflow = 'hidden';
        }
      }
    },

    actions: {
      [HIDE_MODAL]: async (context) => {
        context.commit(MODAL_HIDDEN);
      },

      [SHOW_MODAL]: async (context, options) => {
        context.commit(MODAL_VISIBLE, options);
      }
    }
  };
}

export const state = {
  active: null
};

export const getters = {
  activeModal: state => state.active,
  activeModalName: state => state.active && state.active.name
};

export const mutations = {
  [LOCATION_CHANGED]: (state) => {
    state.active = null;
    document.body.style.overflow = 'auto';
  },

  [MODAL_HIDDEN]: (state) => {
    state.active = null;
    document.body.style.overflow = 'auto';
  },

  [MODAL_VISIBLE]: (state, options) => {
    state.active = options;
    document.body.style.overflow = 'hidden';
  }
};

export const actions = {
  [HIDE_MODAL]: async (context) => {
    context.commit(MODAL_HIDDEN);
  },

  [SHOW_MODAL]: async (context, options) => {
    context.commit(MODAL_VISIBLE, options);
  }
};
