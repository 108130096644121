/* eslint-disable no-lonely-if */

import dataUrlToBlob from '@/utils/data-url-to-blob';

const MAX_SIZE = 1024;

export default function resizeImage(file) {
  if (!/^image/.test(file.type)) {
    return Promise.resolve(file);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', (readerEvent) => {
      const image = new Image();
      image.addEventListener('load', (imageEvent) => {
        let { width, height } = image;

        if (width <= MAX_SIZE && height <= MAX_SIZE) {
          return resolve(file);
        }

        if (width > height) {
          if (width > MAX_SIZE) {
            height *= MAX_SIZE / width;
            width = MAX_SIZE;
          }
        } else {
          if (height > MAX_SIZE) {
            width *= MAX_SIZE / height;
            height = MAX_SIZE;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);

        const dataUrl = canvas.toDataURL(file.type);
        canvas.remove();

        const resizedImage = dataUrlToBlob(dataUrl);
        return resolve(resizedImage);
      });

      image.src = readerEvent.target.result;
    });

    reader.readAsDataURL(file);
  });
}
