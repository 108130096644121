import { toShare } from '@/utils/to-models';

import fetchApi from './fetch-api';

function getMap(req, res) {
  if (res && res.fence && res.fence.lat && res.fence.lng) {
    return {
      lat: res.fence.lat,
      lng: res.fence.lng
    };
  }

  if (req && req.lat && req.lng) {
    return {
      lat: req.lat,
      lng: req.lng
    };
  }

  return {
    lat: 38.175662,
    lng: -85.7369231
  };
}

function fetchApiMock({ query }) {
  const createShare = n => ({
    id: n,
    title: 'This is a title',
    description: 'This is a description',
    media: [],
    airport: {
      lat: 100 * n,
      lng: 100 * n,
      city: 'Louisville',
      state: 'Kentucky',
      facility: 'SOUTHWEST HOSPITAL',
      count: n + 1
    },
    ratePerHour: 100 + n,
    aircraft: {
      make: 'MAKE',
      model: 'MODEL',
      year: 1990 + n,
      hours: n * 1000,
      owners: [
        {
          uid: n,
          firstname: 'John',
          lastname: 'Doe',
          address: 'Bloomfield, CT 06002, USA',
          phone: '(111) 222-3333',
          email: 'john@doe.com',
          zip: '06002'
        }
      ]
    }
  });

  return {
    data: {
      shares: [
        createShare(0),
        createShare(1),
        createShare(2),
        createShare(3),
        createShare(4),
        createShare(5),
        createShare(6),
        createShare(7),
        createShare(8),
        createShare(9)
      ].slice(0, query.limit),
      count: 100
    }
  };
}

export default async function fetchShares(options) {
  const query = {
    page: 1,
    limit: 10,
    ...options
  };

  const fetch = process.env.API_URL ? fetchApi : fetchApiMock;

  const response = await fetch({
    method: 'GET',
    path: '/share/favorite',
    query
  });

  return {
    ...query,
    shares: response.data.shares.map(toShare),
    count: response.data.count,
    map: getMap(options, response.data)
  };
}
