<template>
  <div class="manage-listings-page">
    <div class="container">
      <div class="row shares">
        <sma-account-sidebar class="col col-sm-5" />
        <div class="content col col-sm-19">
          <div class="col center">
            <h2>My Planes</h2>
          </div>
          <div class="row">
            <div
              v-for="share in myShares"
              :key="share.id"
              class="col col-sm-12 col-md-6"
            >
              <sma-share-card
                class="share"
                :share="share"
              />
            </div>
          </div>
          <div
            v-if="myShares.length == 0"
            class="add"
          >
            <router-link
              class="button ghost"
              exact
              active-class="active"
              :to=" { name: 'post' }"
            >
              Add Plane
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaAccountSidebar from '@/components/AccountSidebar';
import SmaShareCard from '@/components/ShareCard';
import { FETCH_MY_SHARES } from '@/store/action-types';

export default {
  name: 'SmaManageListings',

  components: {
    SmaAccountSidebar,
    SmaShareCard
  },

  computed: {
    ...mapGetters([
      'myShares',
      'me'
    ])
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$store.dispatch(FETCH_MY_SHARES, {
        page: 1,
        limit: 12
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 200px;
}

.row {
  margin-bottom: 1rem;

  &.border {
    border: 0 solid $sma-light-gray;
    border-bottom-width: 1px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 1rem;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $sma-light-gray;
  transition: 0.4s;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: $sma-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $sma-blue;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;

  &::before {
    border-radius: 50%;
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;

  &.ghost {
    background-color: transparent;
    border: 1px solid $sma-yellow;
    color: $sma-yellow;
  }
}

.tabs {
  .button {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;

    &.inactive {
      background-color: $sma-white;
      color: $sma-yellow;
    }
  }
}

.add {
  text-align: center;
}
</style>
