<template>
  <div
    v-if="activeModalName === 'user'"
    class="user-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content container">
        <div class="row">
          <div class="col col-sm-8">
            <div
              class="image aspect-ratio-1-1"
              :style="getBackgroundStyle()"
            />
          </div>
          <div class="col col-sm-16">
            <h2>{{ user.first }} {{ user.last }}</h2>
            <div class="buttons">
              <a
                class="email"
                :href="getEmailHref()"
              >
                <fa-icon
                  icon="envelope"
                  class="icon"
                /> Send Email
              </a>
            </div>
            <div class="about-me">
              {{ user.about }}
            </div>
          </div>
          <div class="col">
            <h3>User Details</h3>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                Airport:
              </div>
              <div class="value">
                {{ user.airport ? user.airport.locationId : '' }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                City:
              </div>
              <div class="value">
                {{ user.airport ? user.airport.city : '' }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                State:
              </div>
              <div class="value">
                {{ user.airport ? user.airport.state : '' }}
              </div>
            </div>
          </div>
          <div
            v-if="user.bio"
            class="col col-sm-24"
          >
            <div class="pair">
              <div class="name">
                Bio:
              </div>
              <div class="value">
                {{ user.bio }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { HIDE_MODAL } from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';

export default {
  name: 'SmaUserModal',

  computed: {
    ...mapGetters([
      'activeModal',
      'activeModalName',
      'getUserById',
      'error',
      'me'
    ]),

    user() {
      return this.getUserById(this.activeModal.userId);
    }
  },

  methods: {
    hide() {
      this.email = '';
      this.$store.dispatch(HIDE_MODAL);
    },

    getBackgroundStyle() {
      // const media = this.user && this.user.media;
      // const url = media && media[0] && media[0].url;
      const url = this.user && this.user.avatar && this.user.avatar.url;

      return {
        ...getBackgroundStyle(url),
        width: '100%'
      };
    },

    getEmailHref() {
      if (this.user.email) {
        return `mailto:${this.user.email}?subject=Pilot inquiry from sharemyaircraft.com`;
      }

      return '#';
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: $sma-white;
}

.content {
  padding: 2rem;
}

.close {
  float: right;
  color: $sma-gray;
  font-size: 1.5rem;
}

.image {
  background-color: $sma-near-white;
}

.buttons {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  .icon {
    margin-right: 0.5rem;
  }

  .favorite {
    margin-left: 1rem;
  }
}

.about-me {
  font-size: 0.875rem;
}

.pair {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  flex-wrap: wrap;
}

.name {
  font-weight: 600;
  margin-right: 0.5em;
  min-width: 100px;
  text-align: right;
}

.value {
  flex: 1;
  color: $sma-gray;

  & input {
    width: 100%;
    border-bottom: 1px solid $sma-gray;
    color: $sma-gray;
  }
}
</style>
