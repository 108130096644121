<template>
  <div class="post-general-page">
    <div class="container">
      <div class="row">
        <sma-progress
          class="col"
          :step="0"
          :steps="steps"
        />
      </div>
      <div class="row form">
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-24"
        >
          <p>Please enter a valid tail number and click search. Many fields below will automatically propagate. Only fields with Asterisks are required, but more information will make your listing robust and attractive!</p>
        </div>
        <div
          v-else
          class="col col-sm-24"
        >
          <p>Please be as detailed as possible and add a stock picture of the plane you wish to purchase</p>
        </div>

        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-20"
        >
          <label
            class="label"
            for="tailNumber"
          >Tail Number<span class="req">*</span></label>
          <input
            id="tailNumber"
            v-model="form.tailNumber"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-4"
        >
          <button
            class="btn yellow tailNumber"
            @click="tailNumber"
          >
            <span v-if="!tailNumberLoading">Search</span>
            <span v-else><fa-icon
              icon="circle-notch"
              spin
            /></span>
          </button>
        </div>
        <div v-if="tailNumberFail">
          <p class="fail">
            {{ tailNumberFail }}
          </p>
        </div>
        <div
          v-if="form.type === 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="clubName"
          >Club Name<span class="req">*</span></label>
          <input
            id="clubName"
            v-model="form.clubName"
            class="input text"
          >
        </div>
        <div
          v-if="form.type === 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="clubRepName"
          >Club Representative Name<span class="req">*</span></label>
          <input
            id="clubRepName"
            v-model="form.clubRepName"
            class="input text"
          >
        </div>
        <div
          v-if="form.type === 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="clubMemberSize"
          >Member Size<span class="req">*</span></label>
          <input
            id="clubMemberSize"
            v-model="form.clubMemberSize"
            class="input text"
          >
        </div>
        <div
          v-if="form.type === 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="clubNumAircrafts"
          >Number of Aircrafts<span class="req">*</span></label>
          <input
            id="clubNumAircrafts"
            v-model="form.clubNumAircrafts"
            class="input text"
          >
        </div>
        <div
          v-if="form.type === 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="clubWebsiteUrl"
          >Club Website URL<span class="req">*</span></label>
          <input
            id="clubWebsiteUrl"
            v-model="form.clubWebsiteUrl"
            class="input text"
          >
        </div>
        <div
          v-if="form.type === 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="clubEmail"
          >Club Email Address<span class="req">*</span></label>
          <input
            id="clubEmail"
            v-model="form.clubEmail"
            class="input text"
          >
        </div>
        <div
          v-if="form.type === 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="clubPhone"
          >Club Phone Number<span class="req">*</span></label>
          <input
            id="clubPhone"
            v-model="form.clubPhone"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type) && form.type !== 'PRIVATE_OWNER_SHARE_PURCHASE'"
          class="col"
        >
          <label
            class="label"
            for="businessName"
          >Business Name<span
            v-if="form.type === 'FBO_RENTAL'"
            class="req"
          >*</span></label>
          <input
            id="businessName"
            v-model="form.businessName"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col"
        >
          <label
            class="label"
            for="regOwner"
          >Registered Owner</label>
          <input
            id="regOwner"
            v-model="form.regOwner"
            class="input text"
          >
          <input
            id="email"
            v-model="form.honeypot"
            name="email"
            type="email"
            tabindex="-1"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col"
        >
          <label
            class="label"
            for="title"
          >Title<span class="req">*</span></label>
          <input
            id="title"
            v-model="form.title"
            class="input text"
            placeholder="Give it a great title to make it stand out! Such as &quot;The best tail wheel you will fly!&quot;"
          >
        </div>
        <div class="col">
          <label
            class="label"
            for="description"
          >Description<span class="req">*</span></label>
          <textarea
            id="description"
            v-model="form.description"
            class="input text"
            rows="3"
            placeholder="Use this area to give some more details about the plane AND type in any NICKNAME the plane may have. For example, a Cessna 172 is commonly referred to as a SKYHAWK – which can then be searched"
          />
        </div>
        <div
          v-if="form.type !== 'FLIGHT_CLUB'"
          class="col col-sm-12"
          for="make"
        >
          <label class="label">Make<span class="req">*</span></label>
          <input
            id="make"
            v-model="form.make"
            class="input text"
          >
        </div>
        <div
          v-if="form.type !== 'FLIGHT_CLUB'"
          class="col col-sm-12"
          for="make"
        >
          <label
            class="label"
            for="model"
          >Model<span class="req">*</span></label>
          <input
            id="model"
            v-model="form.model"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="year"
          >Year</label>
          <input
            id="year"
            v-model="form.year"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type) && form.type !== 'PRIVATE_OWNER_SHARE_PURCHASE'"
          class="col col-sm-24"
        >
          <div class="row">
            <div class="col col-sm-12">
              <label
                class="label"
                for="rate"
              >Hourly Rate Required<span class="req">*</span></label>
              <input
                id="rate"
                v-model="form.rate"
                class="input text"
                placeholder="What do you want for your plane/hour?"
              >
            </div>
            <div class="col col-sm-12">
              <label
                class="label"
                for="rateType"
              >Rate Type<span class="req">*</span></label>
              <select
                id="rateType"
                class="input text"
                :value="form.isWet ? 'true' : 'false'"
                @change="(e) => {
                  const el = e.target;
                  const valueStr = el.options[el.options.selectedIndex].value;
                  form.isWet = valueStr === 'true';
                }"
              >
                <option value="true">
                  Wet
                </option>
                <option value="false">
                  Dry
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="engineHours"
          >Engine Hours</label>
          <input
            id="engineHours"
            v-model="form.engineHours"
            class="input text"
          >
        </div>

        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="engineManufacturer"
          >Engine Manufacturer</label>
          <input
            id="engineManufacturer"
            v-model="form.engineManufacturer"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="engineModel"
          >Engine Model</label>
          <input
            id="engineModel"
            v-model="form.engineModel"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="classification"
          >Classification</label>
          <input
            id="classification"
            v-model="form.classification"
            class="input text"
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="categories"
          >Categories</label>
          <input
            id="categories"
            v-model="form.categories"
            class="input text"
            placeholder="Enter categories seperated by commas."
          >
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12 horizontal"
        >
          <input
            id="instrumentRatingRequired"
            v-model="form.instrumentRatingRequired"
            class="input checkbox"
            type="checkbox"
          >
          <label
            class="label"
            for="instrumentRatingRequired"
          >Instrument Rating Required</label>
        </div>

        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-24"
        >
          <label
            class="label"
            for="equipment"
          >Equipment</label>
          <textarea
            id="equipment"
            v-model="form.equipment"
            class="input text"
            rows="3"
            placeholder="Talk about equipment, such as deicing, A/C, anything that is extra."
          />
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="exterior"
          >Exterior</label>
          <textarea
            id="exterior"
            v-model="form.exterior"
            class="input text"
            rows="3"
            placeholder="Simple description of exterior (eg. white/blue)"
          />
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="avionics"
          >Avionics</label>
          <textarea
            id="avionics"
            v-model="form.avionics"
            class="input text"
            rows="3"
            placeholder="Talk about Glass vs 6pack, what Garmins are installed, any additional radios or portable units such as Aera 660, NEXRAD, Sirius Radio, etc."
          />
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="interior"
          >Interior</label>
          <textarea
            id="interior"
            v-model="form.interior"
            class="input text"
            rows="3"
            placeholder="Talk about the condition of the interior and anything special you would like to highlight"
          />
        </div>

        <div
          v-if="form.type === 'PRIVATE_OWNER_SHARE_PURCHASE'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="sharePercentOffered"
          >Share Percent Offered</label>
          <div id="sharePercentOffered">
            <div>
              <input
                id="half"
                v-model="form.sharePercentOffered"
                type="radio"
                value="1/2"
              >
              <label for="half">1/2</label>
            </div>
            <div>
              <input
                id="thrid"
                v-model="form.sharePercentOffered"
                type="radio"
                value="1/3"
              >
              <label for="thrid">1/3</label>
            </div>
            <div>
              <input
                id="fourth"
                v-model="form.sharePercentOffered"
                type="radio"
                value="1/4"
              >
              <label for="fourth">1/4</label>
            </div>
            <div>
              <input
                id="fifth"
                v-model="form.sharePercentOffered"
                type="radio"
                value="1/5"
              >
              <label for="fifth">1/5</label>
            </div>
            <div>
              <input
                id="custom"
                ref="custom"
                v-model="form.sharePercentOffered"
                type="radio"
                :value="customSharePercentOffered"
              >
              <label for="other">Other: </label>
              <input
                v-model="customSharePercentOffered"
                class="input text"
              >
            </div>
          </div>
        </div>
        <div
          v-if="form.type === 'PRIVATE_OWNER_SHARE_PURCHASE'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="pricePercentOffered"
          >Price Offered per Share</label>
          <div>
            <span class="col-sm-1">$</span>
            <input
              id="pricePercentOffered"
              v-model="form.pricePercentOffered"
              class="input text col-sm-23"
              type="number"
            >
          </div>
        </div>

        <div
          v-if="form.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="cost"
          >Approximate Expected Cost</label>
          <input
            id="cost"
            v-model="form.cost"
            class="input text"
            type="number"
          >
        </div>
        <div
          v-if="form.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="desiredNumberOfPilots"
          >Desired Number of Pilots</label>
          <input
            id="desiredNumberOfPilots"
            v-model="form.desiredNumberOfPilots"
            class="input text"
            type="number"
          >
        </div>

        <div
          v-if="form.type !== 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="comments"
          >Additional Notes</label>
          <textarea
            id="comments"
            v-model="form.comments"
            class="input text"
            rows="3"
            placeholder="Anything else you would like a potential sharer to know about the plane or about you, including a minimum amount of hours you want yearly"
          />
        </div>

        <div
          v-if="form.type !== 'FLIGHT_CLUB'"
          class="col col-sm-12"
        >
          <label
            class="label"
            for="comments"
          >Hyperlink</label>
          <input
            id="hyperlink"
            v-model="form.url"
            class="input text"
          >
        </div>

        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col col-sm-24 horizontal"
        >
          <label
            class="label"
            for="about"
          >Select Your Aircraft Details</label>
        </div>
        <div class="col-sm-2">
          <!-- spacer -->
        </div>
        <div
          v-if="!['PARTNERSHIP_REQUEST_TO_PURCHASE', 'FLIGHT_CLUB'].includes(form.type)"
          class="col-sm-22"
        >
          <div class="row">
            <div
              v-for="tagName in tagNames"
              :key="tagName"
              class="col col-sm-6 horizontal"
            >
              <input
                :id="tagName"
                v-model="form.tags[tagName]"
                class="input"
                type="checkbox"
              >
              <label
                class="label"
                :for="tagName"
              >
                {{ tagName }}
              </label>
            </div>
          </div>
        </div>
        <div class="fail">
          <ul>
            <li
              v-for="(error,i) in errors"
              :key="i"
            >
              {{ error.message }}
            </li>
          </ul>
        </div>
        <div class="col">
          <button
            class="btn yellow"
            @click="submit"
          >
            Next step
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';

import SmaProgress from '@/components/Progress';
import {
  fetchShares,
  tailNumber as tailNumberSearch
} from '@/services/api';
import {
  CREATE_POST_IN_PROGRESS,
  UPDATE_POST_IN_PROGRESS
} from '@/store/action-types';
import {
  getBusinessNameErrors,
  getClubEmailErrors,
  getClubMemberSizeErrors,
  getClubNameErrors,
  getClubNumAircraftsErrors,
  getClubPhoneErrors,
  getClubRepNameErrors,
  getClubWebsiteUrlErrors,
  getDescriptionErrors,
  getMakeErrors,
  getModelErrors,
  getRateErrors,
  getTailNumberErrors,
  getTitleErrors,
  getUrlErrors
} from '@/utils/validators';

export default {
  name: 'SmaPostGeneral',

  components: {
    SmaProgress
  },

  data() {
    return {
      steps: [
        ['General', 'Information'],
        ['Your plane', 'location'],
        ['Add Photos', '& Video'],
        ['Preferred Flight', 'Instructor'],
        ['Preview &', 'Confirm']
      ],
      tagNames: [
        'Single Engine',
        'Amphibious/Float',
        'Light Sport',
        'Multi-Engine',
        'Acrobatic',
        'Turbo',
        'Bi-Plane',
        'Hot-Air Balloon',
        'Jet',
        'UltraLight',
        'Helicopter',
        'Tail Wheel',
        'Homebuilt',
        'Experimental'
      ],
      form: {
        tailNumber: '',
        regOwner: '',
        title: '',
        description: '',
        url: '',
        make: '',
        model: '',
        year: '',
        rate: '',
        isWet: true,
        engineHours: '',
        engineManufacturer: '',
        engineModel: '',
        classification: '',
        categories: '',
        aircraftTime: '',
        equipment: '',
        exterior: '',
        avionics: '',
        interior: '',
        comments: '',
        email: '',
        honeypot: '',
        tags: {},
        instrumentRatingRequired: false,
        cost: '',
        businessName: '',
        sharePercentOffered: '',
        pricePercentOffered: '',
        desiredNumberOfPilots: '',
        clubName: '',
        clubRepName: '',
        clubMemberSize: '',
        clubNumAircrafts: '',
        clubWebsiteUrl: '',
        clubEmail: '',
        clubPhone: ''
      },
      errors: [],
      sharePercentOffered: '',
      customSharePercentOffered: '',
      tailNumberFail: '',
      tailNumberLoading: false
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'activeModal'
    ])
  },

  created() {
    if (!isEmpty(this.$store.state.post.inProgress)) {
      const inProgress = { ...this.$store.state.post.inProgress };

      const tags = {};
      if (inProgress.tags) {
        inProgress.tags.forEach((tag) => {
          tags[tag] = true;
        });
      }
      inProgress.tags = tags;

      if (inProgress.categories) {
        inProgress.categories = inProgress.categories.join(', ');
      }

      Object.assign(this.form, inProgress);
    } else {
      this.$router.replace({ name: 'post' });
    }
  },

  methods: {
    getErrors() {
      let errors = [];

      if (this.form.type !== 'FLIGHT_CLUB') {
        errors = errors.concat(
          getDescriptionErrors(this.form.description),
          getMakeErrors(this.form.make),
          getModelErrors(this.form.model),
          getUrlErrors(this.form.url)
        );
      }

      if (this.form.type === 'PRIVATE_OWNER_SHARE_PURCHASE') {
        errors = errors.concat(
          getTailNumberErrors(this.form.tailNumber),
          getTitleErrors(this.form.title)
        );
      } else if (this.form.type === 'FBO_RENTAL') {
        errors = errors.concat(
          getTailNumberErrors(this.form.tailNumber),
          getBusinessNameErrors(this.form.businessName),
          getTitleErrors(this.form.title),
          getRateErrors(this.form.rate)
        );
      } else if (this.form.type === 'PRIVATE_OWNER_RENTAL') {
        errors = errors.concat(
          getTailNumberErrors(this.form.tailNumber),
          getTitleErrors(this.form.title),
          getRateErrors(this.form.rate)
        );
      } else if (this.form.type === 'FLIGHT_CLUB') {
        errors = errors.concat(
          getDescriptionErrors(this.form.description),
          getClubNameErrors(this.form.clubName),
          getClubRepNameErrors(this.form.clubRepName),
          getClubMemberSizeErrors(this.form.clubMemberSize),
          getClubNumAircraftsErrors(this.form.clubNumAircrafts),
          getClubWebsiteUrlErrors(this.form.clubWebsiteUrl),
          getClubEmailErrors(this.form.clubEmail),
          getClubPhoneErrors(this.form.clubPhone)
        );
      }

      return errors;
    },

    submit() {
      if (this.form.honeypot !== '') {
        console.log('Bot');
        return;
      }

      this.errors = this.getErrors();

      if (this.errors.length > 0) {
        return;
      }

      const tags = [];
      this.tagNames.forEach((tag) => {
        if (this.form.tags[tag]) { tags.push(tag); }
      });

      const categories = this.form.categories.split(',');
      for (let i = 0; i < categories.length; i++) {
        categories[i] = categories[i].trim();
      }

      const inProgress = { ...this.form };
      inProgress.tags = tags;
      inProgress.categories = categories;

      if (!isEmpty(this.$store.state.post.inProgress)) {
        this.$store.dispatch(CREATE_POST_IN_PROGRESS, inProgress);
      } else {
        this.$store.dispatch(UPDATE_POST_IN_PROGRESS, inProgress);
      }

      this.$router.push({
        name: 'post-location'
      });
    },

    async tailNumber() {
      this.tailNumberFail = '';

      if (this.form.tailNumber === '') {
        this.tailNumberFail = 'Please enter your tail number.';
      } else {
        this.tailNumberLoading = true;

        let results;
        try {
          results = await fetchShares({ tailNumber: this.form.tailNumber, limit: 1 });
        } catch (err) {
          this.tailNumberLoading = false;

          console.error(err);
          return;
        }

        if (!results || !results.shares || results.shares.length !== 0) {
          this.tailNumberLoading = false;

          this.tailNumberFail = 'Tail number already in use.';
          return;
        }

        try {
          results = await tailNumberSearch(this.form);
        } catch (err) {
          this.tailNumberLoading = false;

          console.error(err);
          return;
        }

        this.tailNumberLoading = false;

        if (results.make === '') {
          this.tailNumberFail = 'Your tail number was not found in our database which connects directly to the FAA’s Registration system, please enter the details about your aircraft below.';
          return;
        }

        this.form.regOwner = results.regOwner;
        this.form.make = results.make;
        this.form.model = results.model;
        this.form.serial = results.serial;
        this.form.year = results.year;
        this.form.engineManufacturer = results.engineManufacturer;
        this.form.engineModel = results.engineModel;
        this.form.classification = results.classification;
        this.form.categories = results.categories.join(', ');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.post-general-page {
  padding: 2rem;
}

.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .label {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }

  .input {
    border: 1px solid $sma-light-gray;
    border-radius: 0.25rem;
    background-color: white;

    &::placeholder {
      color: #ccc;
      font-weight: 400;
    }

    &.text {
      padding: 0.25rem 0.5rem;
      font-weight: 600;

      &:focus {
        border-color: $sma-blue;
      }
    }
  }

  .btn {
    align-self: center;
    margin-top: 5rem;

    &.tailNumber {
      margin-top: 20px;
    }
  }
}

#instrumentRatingRequired {
  margin-top: 1.5rem;

  & + label {
    margin-top: 1.5rem;
  }
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}

.req {
  color: $sma-red;
}
</style>
