import { toShare, toInstructor } from '@/utils/to-models';
import fetchApi from './fetch-api';

export default async function search(query) {
  const response = await fetchApi({
    method: 'GET',
    path: '/search',
    query
  });

  return {
    ...query,
    results: response.data.results.map((result) => {
      switch (result.type) {
        case 'user':
        case 'instructor':
          result.data = toInstructor(result.data);
          break;

        case 'share':
          result.data = toShare(result.data);
          break;

        default:
      }

      return result;
    }),
    count: response.data.count,
    airportCounts: response.data.airportCounts
  };
}
