import Vue from 'vue';

import App from '@/App.vue';
import createRouter from '@/router';
import createStore from '@/store';
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta';
import ClientOnly from './components/ClientOnly';
import '@/fontawesome';

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    if (process.client) {
      el.vClickOutside = (e) => {
        if (!el.contains(e.target)) {
          vnode.context[binding.expression](e);
          e.stopPropagation();
        }
      };
      document.body.addEventListener('click', el.vClickOutside);
    }
  },

  unbind(el) {
    if (process.client) {
      document.body.removeEventListener('click', el.vClickOutside);
      delete el.vClickOutside;
    }
  }
});

Vue.component('ClientOnly', ClientOnly);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  console.error({ err, info });
};

export default function createApp() {
  const store = createStore();
  const router = createRouter({ store });

  Vue.use(VueAnalytics, {
    id: 'UA-124808674-1',
    router
  });

  const app = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
  });

  return {
    app,
    router,
    store
  };
}
