<template>
  <button
    class="share-card"
    :style="getCardStyle()"
    @click="showShareModal"
  >
    <div
      class="image aspect-ratio-3-2"
      :style="getBackgroundStyle()"
    >
      <div
        v-if="share.isActive === false"
        class="share-disabled"
      >
        DISABLED
      </div>
    </div>
    <div class="details">
      <div class="airport-id">
        Airport: {{ share.airport.locationId }}
      </div>
      <div
        v-if="share.type === 'FBO_RENTAL'"
        class="business-name"
      >
        {{ share.businessName }}
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="rate">
        <span v-if="share.type === 'PRIVATE_OWNER_RENTAL'">PRIVATE OWNER RENTAL<br></span>
        <span v-if="share.type === 'PRIVATE_OWNER_SHARE_PURCHASE'">PRIVATE OWNER SHARE PURCHASE<br></span>
        <span v-if="share.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE'">PARTNERSHIP REQUEST TO PURCHASE<br></span>
        <span v-if="share.type === 'FBO_RENTAL'">FLIGHT SCHOOL RENTAL<br></span>
        <span v-if="share.type === 'FLIGHT_CLUB'">FLYING CLUB<br></span>
        <span v-if="share.type !== 'FLIGHT_CLUB'">{{ rate }}</span>
      </div>
      <div class="location">
        <fa-icon
          icon="map-marker-alt"
          class="marker"
        />
        <div class="address">
          {{ share.city }}, {{ share.state }}
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { SHOW_MODAL } from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';
import { mapGetters } from 'vuex';
import getMediaThumbnail from '@/utils/get-media-thumbnail';

export default {
  name: 'SmaShareCard',

  props: {
    share: { type: Object, required: true },
    horizontal: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters(['me']),

    title() {
      return [this.share.year, this.share.make, this.share.model]
        .filter(x => x)
        .join(' ');
    },

    rate() {
      if (this.share.type === 'PRIVATE_OWNER_SHARE_PURCHASE') {
        if (this.share.pricePercentOffered && this.share.sharePercentOffered) {
          return `$${this.share.pricePercentOffered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} for ${this.share.sharePercentOffered} share`;
        }
        return 'Ask for cost';
      }

      if (this.share.type === 'PARTNERSHIP_REQUEST_TO_PURCHASE') {
        if (this.share.cost) {
          return `Approx $${this.share.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        }
        return 'Ask for cost';
      }

      if (this.share.type === 'FBO_RENTAL') {
        if (this.share.ratePerHour) {
          return `$${this.share.ratePerHour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} / hour wet`;
        }
        return 'Ask for rate';
      }


      if (this.share.ratePerHour) {
        return `$${this.share.ratePerHour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} / hour`;
      }
      return 'Ask for rate';
    }
  },

  methods: {
    getCardStyle() {
      if (this.horizontal) {
        return {
          flexDirection: 'row'
        };
      }
      return {};
    },

    getBackgroundStyle() {
      let url = '/default.jpg';

      let { defaultMedia } = this.share;
      if (defaultMedia >= this.share.media.length) {
        defaultMedia = 0;
      }

      const media = this.share.media[defaultMedia];

      if (media) {
        url = getMediaThumbnail(media);
      }

      return {
        ...getBackgroundStyle(url),
        width: this.horizontal ? '50%' : '100%'
      };
    },

    showShareModal() {
      if (!this.me) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup'
        });
        return;
      }

      this.$store.dispatch(SHOW_MODAL, {
        name: 'share',
        shareId: this.share.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.airport-id {
  color: $sma-blue;
  font-size: 1rem;
}

.share-card {
  display: block;
  flex-direction: column;
  align-items: stretch;
  background-color: $sma-white;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
  color: $sma-dark-gray;
  border: 0;
  cursor: pointer;

  .image {
    width: 100%;
    background-color: $sma-near-white;
  }

  .details {
    display: block;
    flex: 1;
    flex-direction: column;
    background-color: $sma-white;
    padding: 0.5rem;

    .title {
      font-weight: 600;
    }

    .rate {
      flex: 1;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }

    .location {
      display: flex;
      font-size: 0.875rem;

      .marker {
        color: $sma-blue;
        margin-right: 0.5rem;
        margin-top: 0.125rem;
      }

      .address {
        color: $sma-dark-gray;
      }
    }
  }
}

.share-disabled {
  color: $sma-red;
  font-weight: bold;
}
</style>
