import fetchApi from './fetch-api';

export default async function fetchHomeMap() {
  const response = await fetchApi({
    method: 'GET',
    path: '/airport/shares'
  });

  return response.data;
}
