import fetchApi from './fetch-api';
import fetchUserProfile from './fetch-user-profile';

export default async function login({ username, password }) {
  await fetchApi({
    method: 'POST',
    path: '/auth/local',
    json: {
      user: username,
      password
    }
  });

  return fetchUserProfile();
}
