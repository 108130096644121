<template>
  <div
    v-if="activeModalName === 'instructor-signup'"
    class="instructor-signup-modal modal-background"
    @click="hide"
  >
    <div
      class="form modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content">
        <h2>Fill out your professional profile</h2>
        <div>
          <h3>INSTRUCTORS AND COMMERCIAL PILOTS:</h3>
          <p>List yourself on SMA and get noticed! Whether you are building hours to get to the next level, or already have a robust business flying for individuals or the majors , we can help you get more exposure and earn more income.</p>
          <p>Every month thousands of pilots and non - pilots come to SMA looking for instruction, sign - offs , transition training AND pilots to fly for them AND they need you. We do our best to make sure pilots and non - pilots know about us and therefore about you and we are about to take things to the next level!</p>
          <p>Imagine not only giving flight instruction when and to whom you would like but having the opportunity to manage and fly new high - performance Cirrus SR22 or Diamond D42/62s for partnerships of non - pilots. We are in the process of setting up an offering that allows non - pilots to live the same lifestyle of the uber - rich AND the pilot community, being able to have their own new modern plane that they share with up to 16 other non - pilot, on call when they need it. All that is missing is YOU so let us know if you are interested as we launch this nationwide program, by filling out the form below or checking the box!</p>
          <p>In the meantime, sign yourself up for free and watch what happens!</p>
        </div>

        <sma-user-instructor-card
          :value="form"
          @input="update"
        />
        <div class="error-message">
          <ul>
            <li
              v-for="(error,i) in errors"
              :key="i"
            >
              {{ error.message }}
            </li>
          </ul>
        </div>
        <button
          class="btn signup-btn"
          @click="signup"
        >
          Sign Up For Free
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaUserInstructorCard from '@/components/UserInstructorCard';
import { HIDE_MODAL, CREATE_INSTRUCTOR } from '@/store/action-types';
import {
  getBusinessNameErrors,
  getRateErrors,
  getTagsErrors,
  getAirportErrors,
  getAvatarErrors,
  getAboutErrors
} from '@/utils/validators';

export default {
  name: 'SmaInstructorSignupModal',

  components: {
    SmaUserInstructorCard
  },

  data() {
    return {
      form: {
        businessName: '',
        rate: null,
        about: '',
        airport: null,
        avatar: null,
        tags: [],
        isCommercial: false,
        isDiscoverable: true
      },
      errors: []
    };
  },

  computed: {
    ...mapGetters([
      'activeModalName',
      'error',
      'me'
    ])
  },

  watch: {
    me() {
      if (this.me) {
        this.form.avatar = this.me.avatar;
        this.form.airport = this.me.airport;
      }
    }
  },

  methods: {
    hide() {
      this.$store.dispatch(HIDE_MODAL);
    },

    update(instructor) {
      Object.assign(this.form, instructor);
    },

    getErrors() {
      const errors = [].concat(
        getBusinessNameErrors(this.form.businessName),
        getRateErrors(this.form.rate),
        getTagsErrors(this.form.tags),
        getAirportErrors(this.form.airport),
        getAvatarErrors(this.form.avatar),
        getAboutErrors(this.form.about)
      );

      return errors;
    },

    async signup() {
      this.errors = this.getErrors();
      if (this.errors.length > 0) { return; }

      try {
        await this.$store.dispatch(CREATE_INSTRUCTOR, {
          businessName: this.form.businessName || '',
          ratePerHour: this.form.rate || null,
          about: this.form.about,
          airport: this.form.airport,
          avatar: this.form.avatar,
          tags: this.form.tags,
          isCommercial: this.form.isCommercial,
          isDiscoverable: this.form.isDiscoverable
        });
      } catch (err) {
        console.error(err);
        return;
      }

      this.$router.push({
        name: 'manage-instructor'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  color: $sma-blue;
  font-size: 32px;
  margin: 12px 0;
}

.form {
  @media only screen and (max-width: $breakpoint-sm) {
    max-width: 90%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto !important;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.signup-btn {
  margin-bottom: 16px;
}
</style>
