export default function getMediaThumbnail(media) {
  if (media?.type === 'youtube') {
    return `https://img.youtube.com/vi/${media.url}/default.jpg`;
  }

  if (media?.type === 'vimeo') {
    return `https://i.vimeocdn.com/video/${media.url}.jpg`;
  }

  return media?.url;
}
