import { toInstructor } from '@/utils/to-models';

import fetchApi from './fetch-api';

function fetchApiMock({ query }) {
  const createInstructor = n => ({
    uid: n,
    address: 'Address',
    lat: 100 + n,
    long: 100 + n,
    ratePerHour: 100 + n,
    firstname: 'John',
    lastname: 'Doe',
    aboutMe: 'This is a section about me',
    airportId: 'JFK',
    zip: '40291',
    city: 'Louisville',
    state: 'Kentucky',
    email: 'john@doe.com',
    media: []
  });

  return {
    data: {
      count: 100,
      instructors: [
        createInstructor(0),
        createInstructor(1),
        createInstructor(2),
        createInstructor(3),
        createInstructor(4),
        createInstructor(5),
        createInstructor(6),
        createInstructor(7),
        createInstructor(8),
        createInstructor(9)
      ].slice(0, query.limit)
    }
  };
}

export default async function fetchInstructors(options) {
  const query = {
    page: 1,
    limit: 10,
    ...options
  };

  const fetch = process.env.API_URL ? fetchApi : fetchApiMock;

  const response = await fetch({
    method: 'GET',
    path: '/instructor/favorite',
    query
  });

  return {
    ...query,
    instructors: response.data.map(toInstructor),
    count: response.data.count
  };
}
