import { request } from 'axios';

export default async function fetchAddress(lat, lng) {
  const response = await request({
    url: 'https://maps.googleapis.com/maps/api/geocode/json',
    params: {
      latlng: `${lat},${lng}`,
      key: process.env.GOOGLE_MAPS_API_KEY
    }
  });

  const result = response.data.results && response.data.results[0];
  const addressComps = result && result.address_components;

  const address = {};

  if (addressComps) {
    addressComps.forEach((item) => {
      if (item.types.includes('locality')) {
        address.city = item.long_name;
      } else if (item.types.includes('administrative_area_level_1')) {
        address.state = item.short_name;
      }
    });
  }

  return address;
}
