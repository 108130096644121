<template>
  <router-link
    :to="{ name: 'blog-post', params: { slug: postPreview.slug } }"
  >
    <article
      class="post-preview"
      :class="{ wide }"
    >
      <div class="post-preview-img-container">
        <div class="aspect-16-9">
          <img
            :src="postPreview.img"
            class="post-preview-img"
          >
        </div>
      </div>

      <div class="text-container">
        <h2 class="post-preview-title">
          {{ postPreview.title.rendered }}
        </h2>

        <div class="post-preview-date">
          {{ postPreview.dateStr }}
        </div>
      </div>
    </article>
  </router-link>
</template>

<script>
import defaultThumbnailUrl from '@/assets/logo.png';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
});

export default {
  name: 'SmaBlogPostPreview',

  props: {
    value: { type: Object, required: true },
    wide: { type: Boolean, default: false }
  },

  computed: {
    postPreview() {
      const postPreview = { ...this.value };

      // eslint-disable-next-line camelcase
      postPreview.img = this.value?._embedded?.['wp:featuredmedia']?.[0]?.source_url || defaultThumbnailUrl;

      postPreview.dateStr = dateFormat.format(new Date(postPreview.date));

      return postPreview;
    }
  }
};
</script>

<style lang="scss" scoped>
.aspect-16-9 {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.post-preview {
  .post-preview-img-container {
    width: 100%;
  }

  .post-preview-img {
    object-fit: cover;
    object-position: center;
  }

  .post-preview-title {
    margin: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .post-preview-date {
    font-size: 16px;
  }

  &.wide {
    @media only screen and (min-width: $breakpoint-md) {
      display: flex;
      align-items: center;

      .post-preview-img-container {
        width: 256px;
        flex: none;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
      }

      .post-preview-date {
        order: 0;
      }

      .post-preview-title {
        order: 1;
        margin-bottom: 0;
      }
    }
  }
}
</style>
