<template>
  <div class="favorites-page">
    <div class="container">
      <div class="row">
        <sma-account-sidebar class="col col-sm-5" />
        <div class="col-sm-19">
          <div class="row">
            <div class="col center">
              <h2>My Favorites</h2>
            </div>
            <div class="col center switch">
              <span
                :class="[isInstructors ? 'active' : '']"
                @click="getInstructors"
              >Professionals</span>
              <span
                :class="[!isInstructors ? 'active' : '']"
                @click="getShares"
              >Planes</span>
            </div>
          </div>
          <div
            v-if="isInstructors"
            class="row instructors"
          >
            <div
              v-for="instructor in favoriteInstructors"
              :key="instructor.id"
              class="col col-sm-8"
            >
              <sma-instructor-card
                class="instructor"
                :instructor="instructor"
              />
            </div>
          </div>
          <div
            v-if="!isInstructors"
            class="row shares"
          >
            <div
              v-for="share in favoriteShares"
              :key="share.id"
              class="col col-sm-8"
            >
              <sma-share-card
                class="share"
                :share="share"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaAccountSidebar from '@/components/AccountSidebar';
import SmaShareCard from '@/components/ShareCard';
import SmaInstructorCard from '@/components/InstructorCard';
import { FETCH_FAVORITE_INSTRUCTORS, FETCH_FAVORITE_SHARES } from '@/store/action-types';

export default {
  name: 'SmaFavorites',

  components: {
    SmaAccountSidebar,
    SmaInstructorCard,
    SmaShareCard
  },

  data() {
    return {
      isInstructors: true
    };
  },

  computed: {
    ...mapGetters([
      'favoriteInstructors',
      'favoriteShares'
    ])
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      const action = this.isInstructors
        ? FETCH_FAVORITE_INSTRUCTORS : FETCH_FAVORITE_SHARES;

      this.$store.dispatch(action, {
        page: 1,
        limit: 12
      });
    },

    getInstructors() {
      this.isInstructors = true;
      this.fetch();
    },

    getShares() {
      this.isInstructors = false;
      this.fetch();
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;

  &.border {
    border: 0 solid $sma-light-gray;
    border-bottom-width: 1px;
  }
}

.switch {
  & span {
    color: $sma-yellow;
    background-color: transparent;
    border: 1px solid $sma-yellow;
    padding: 0.5rem 2rem;
    border-radius: 10rem;
    cursor: pointer;
    margin: 0 0.5rem;
  }

  .active {
    background-color: $sma-yellow;
    color: $sma-white;
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $sma-light-gray;
  transition: 0.4s;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: $sma-white;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: $sma-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $sma-blue;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;

  &::before {
    border-radius: 50%;
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.instructor,
.share {
  margin-bottom: 1rem;
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;

  &.ghost {
    background-color: transparent;
    border: 1px solid $sma-yellow;
    color: $sma-yellow;
  }
}

.tabs {
  .button {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;

    &.inactive {
      background-color: $sma-white;
      color: $sma-yellow;
    }
  }
}
</style>
