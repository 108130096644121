<template>
  <button
    class="instructor-card"
    :style="getCardStyle()"
    @click="showInstructorModal"
  >
    <div
      class="image aspect-ratio-1-1"
      :class="[short ? 'aspect-ratio-3-2' : 'aspect-ratio-1-1']"
      :style="getBackgroundStyle()"
    />
    <div class="details">
      <div class="title">
        {{ title }}
      </div>
      <div class="rate">
        {{ rate }}
      </div>
      <div class="location">
        <fa-icon
          icon="map-marker-alt"
          class="marker"
        />
        <div class="address">
          {{ address }}
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { SHOW_MODAL } from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';
import { mapGetters } from 'vuex';

export default {
  name: 'SmaInstructorCard',

  props: {
    instructor: {
      type: Object,
      required: true
    },
    short: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters(['me']),

    title() {
      return `${this.instructor.first} ${this.instructor.last}`;
    },

    address() {
      return this.instructor.airport
        ? `${this.instructor.airport.city}, ${this.instructor.airport.state}`
        : '';
    },

    rate() {
      if (this.instructor.ratePerHour) {
        return `$${this.instructor.ratePerHour} / hour`;
      }

      return 'Ask for rate';
    }
  },

  methods: {
    getCardStyle() {
      if (this.horizontal) {
        return {
          flexDirection: 'row'
        };
      }
      return {};
    },

    getBackgroundStyle() {
      const url = this.instructor && this.instructor.avatar && this.instructor.avatar.url;
      // const url = media && media[0] && media[0].url;

      return {
        ...getBackgroundStyle(url),
        width: '100%'
      };
    },

    showInstructorModal() {
      if (!this.me) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup'
        });
        return;
      }

      this.$store.dispatch(SHOW_MODAL, {
        name: 'instructor',
        instructorId: this.instructor.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.instructor-card {
  display: flex;
  flex-direction: column;
  background-color: $sma-white;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
  align-items: stretch;
  border: 0;
  cursor: pointer;

  .image {
    width: 100%;
    background-color: $sma-near-white;
  }

  .details {
    display: flex;
    flex-direction: column;
    background-color: $sma-white;
    padding: 0.5rem;

    .title {
      font-weight: 600;
    }

    .rate {
      flex: 1;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }

    .location {
      display: flex;
      font-size: 0.875rem;

      .marker {
        color: $sma-blue;
        margin-right: 0.5rem;
        margin-top: 0.125rem;
      }

      .address {
        color: $sma-dark-gray;
      }
    }
  }
}
</style>
