<template>
  <div
    v-if="activeModalName === 'takeover'"
    class="takeover"
    style="z-index: 30;"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <img src="@/assets/takeover2019a.png">
      <div class="buttons">
        <router-link
          class="button find-button"
          :to=" { name: 'find' }"
        >
          Find
        </router-link>
        <div class="space" />
        <router-link
          class="button signup-button"
          :to=" { name: 'signup' }"
        >
          Sign-up
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { HIDE_MODAL } from '@/store/action-types';

export default {
  name: 'SmaTakeover',

  computed: {
    ...mapGetters(['activeModalName'])
  },

  methods: {
    hide() {
      this.$store.dispatch(HIDE_MODAL);
    }
  }
};
</script>

<style lang="scss" scoped>
.takeover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  padding: 5rem 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 30;
}

.close {
  position: absolute;
  color: $sma-gray;
  font-size: 1.5rem;
  top: 0;
  right: 0.5rem;

  &:hover {
    color: $sma-white;
  }
}

.modal {
  position: relative;
  max-width: 95%;
  background-color: $sma-white;

  & img {
    width: 100%;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 1rem 0;

  .button {
    color: $sma-white;
    background-color: $sma-yellow;
    padding: 0.5rem 2rem;
    border-radius: 10rem;

    &.find-button {
      color: $sma-yellow;
      background-color: $sma-white;
      border: 1px solid $sma-yellow;
    }
  }

  .space {
    width: 1rem;
  }
}
</style>
