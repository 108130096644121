<template>
  <div class="password-reset-page">
    <div class="container">
      <div class="col-sm-12 form">
        <div class="col">
          <p>Please enter your desired password.</p>
        </div>
        <div class="col">
          <input
            id="password"
            v-model="form.password"
            class="input text"
            type="password"
            placeholder="New Password"
          >
        </div>
        <div class="col">
          <input
            id="cpassword"
            v-model="form.cpassword"
            class="input text"
            type="password"
            placeholder="Confirm Password"
          >
        </div>
        <div class="col error-message">
          <ul>
            <li
              v-for="(error,i) in errors"
              :key="i"
            >
              {{ error.message }}
            </li>
          </ul>
        </div>
        <div class="col">
          <button
            class="button"
            @click="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <sma-dialog-modal
      :show="showConfirmModal"
      @close="okay"
    >
      Password successfully updated.
      <div>
        <button
          class="button"
          @click="okay"
        >
          Okay
        </button>
      </div>
    </sma-dialog-modal>
  </div>
</template>

<script>
import SmaDialogModal from '@/components/DialogModal';
import { resetPasswordConfirm } from '@/services/api';
import { getConfirmedPasswordErrors, getPasswordErrors } from '@/utils/validators';

export default {
  name: 'SmaPasswordReset',

  components: {
    SmaDialogModal
  },

  data() {
    return {
      form: {
        password: '',
        cpassword: ''
      },
      token: this.$route.query.token,
      errors: [],
      showConfirmModal: false
    };
  },

  methods: {
    getErrors() {
      return [].concat(
        getPasswordErrors(this.form.password),
        getConfirmedPasswordErrors(this.form.password, this.form.cpassword)
      );
    },

    submit() {
      this.errors = this.getErrors();
      if (this.errors.length > 0) { return; }

      resetPasswordConfirm({
        token: this.token,
        password: this.form.password
      })
        .catch((err) => {
          this.errors = [err];
        })
        .then(() => {
          this.showConfirmModal = true;
        });
    },

    okay() {
      this.$router.push({ name: 'home' });
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;
}

.form {
  margin: 5rem auto 0.5rem;

  .col {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;

    .label {
      margin-bottom: 0.25rem;
    }

    &.horizontal {
      flex-direction: row;
      align-items: center;

      .label {
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }

    .input {
      border: 1px solid $sma-light-gray;
      border-radius: 0.25rem;

      &::placeholder {
        color: #aaa;
      }

      &.text {
        padding: 0.25rem 0.5rem;
        font-weight: 600;

        &:focus {
          border-color: $sma-blue;
        }
      }
    }

    .button {
      align-self: center;
      color: $sma-white;
      background-color: $sma-yellow;
      border-radius: 10rem;
      padding: 0.5rem 2rem;
      margin-bottom: 5rem;

      &.tailNumber {
        margin-top: 20px;
      }
    }
  }
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}
</style>
