import fetchApi from './fetch-api';

export default async function addFavoriteShare(share) {
  if (process.env.API_URL) {
    await fetchApi({
      method: 'POST',
      path: `/share/favorite/${share.id}`
    });
  }
}
