<template>
  <div class="cost-calc info-page">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>Hourly Cost Calculator</h1>
          <p>Welcome to the Hourly Rate Calculator. With this tool, you will get a better idea of what it costs per hour to fly your airplane, wet and dry. With these numbers, you can determine an approrpriate hourly rate to both cover your hourly overhead and realize a profit. Fill in all the information, then click the calculate button. THEN PRINT AND SAVE THIS FORM.</p>
        </div>

        <div class="col">
          <strong><em>FORMAT: NO Commas In any fields, .01 = 1%</em></strong>
        </div>

        <!-- Questionnaire -->
        <form
          v-if="!a5"
          class="col form col-sm-20"
          @submit.prevent="calculate"
        >
          <div class="row">
            <div class="col col-sm-20">
              <label for="c8">How much do you pay for a gallon of aviation fuel?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c8"
                v-model="c8"
                class="input text"
                name="c8"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c9">What is the average fuel consumption (in gallons/hour) for this aircraft?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c9"
                v-model="c9"
                class="input text"
                name="c9"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c10">What is the cost per oil change (parts &amp; labor, oil analysis)?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c10"
                v-model="c10"
                class="input text"
                name="c10"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c11">How many hours between oil changes?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c11"
                v-model="c11"
                class="input text"
                name="c11"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c12">What is the average oil consumption (in quarts/hour) for this aircraft?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c12"
                v-model="c12"
                class="input text"
                name="c12"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c13">What is the average cost for a quart of oil?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c13"
                v-model="c13"
                class="input text"
                name="c13"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c14">How much does a factory overhaul cost for this engine?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c14"
                v-model="c14"
                class="input text"
                name="c14"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c15">What is the factory recommended time between engine overhaul (hours)?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c15"
                v-model="c15"
                class="input text"
                name="c15"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c16">What is the cost to overhaul the propeller?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c16"
                v-model="c16"
                class="input text"
                name="c16"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c17">What is the factory recommended time between overhaul for the propeller (hours)?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c17"
                v-model="c17"
                class="input text"
                name="c17"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c20">What is the Annual Insurance Premium?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c20"
                v-model="c20"
                class="input text"
                name="c20"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c21">How much are the Annual Hanger or Tie Down Costs?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c21"
                v-model="c21"
                class="input text"
                name="c21"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c22">Estimate cost for annual inspection plus any misc. unscheduled maintenance during the year</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c22"
                v-model="c22"
                class="input text"
                name="c22"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c23">How much will it cost to upgrade the aircraft avionics in 5 years?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c23"
                v-model="c23"
                class="input text"
                name="c23"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c24">How much will it cost to repaint the aircraft and refurbish the interior in 5 years?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c24"
                v-model="c24"
                class="input text"
                name="c24"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c25">Annual Subscription costs for Avionics databases (Garmin/Sandel/Avidyne)?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c25"
                v-model="c25"
                class="input text"
                name="c25"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c26">Annual Costs of Paper Charts and Approach Books?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c26"
                v-model="c26"
                class="input text"
                name="c26"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c27">Annual Costs of Ground school classes, books,magazines, cleaning supplies?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c27"
                v-model="c27"
                class="input text"
                name="c27"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c28">What is the value of the aircraft?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c28"
                v-model="c28"
                class="input text"
                name="c28"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c29">What is the loan balance?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c29"
                v-model="c29"
                class="input text"
                name="c29"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c30">What is the loan interest rate? .05 = 5%</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c30"
                v-model="c30"
                class="input text"
                name="c30"
                type="number"
                step="0.0001"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c31">If you did not own this plane, what interest rate would you earn on your money annually? .01 = 1%</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c31"
                v-model="c31"
                class="input text"
                name="c31"
                type="number"
                step="0.0001"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="c32">What is the appreciation rate of the aircraft (enter depreciation as a negative)?</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="c32"
                v-model="c32"
                class="input text"
                name="c32"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-20">
              <label for="a29">Hours flown per year:</label>
            </div>
            <div class="col col-sm-4">
              <input
                id="a29"
                v-model="a29"
                class="input text"
                name="a29"
                type="number"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="row">
            <button class="btn yellow">
              Calculate
            </button>
          </div>
        </form>

        <!-- Results -->
        <div
          v-if="a5"
          class="col col-sm-20"
        >
          <div class="col">
            <h3>Results</h3>
          </div>

          <div class="col">
            <p>Fuel: ${{ a5 }}</p>

            <p>Oil Changes/Oil Adds: ${{ a6 }}</p>

            <p><strong>Total Variable Costs per hour: ${{ a7 }}</strong></p>

            <br>

            <p>Insurance: ${{ a10 }}</p>

            <p>Hanger/Tiedown: ${{ a11 }}</p>

            <p>Annual Inspection: ${{ a12 }}</p>

            <p>Avionics Database Subscriptions: ${{ a13 }}</p>

            <p>Paper Charts/Books/ Ground School/ Supplies: ${{ a14 }}</p>

            <p>Cost of Money per year: ${{ a15 }}</p>

            <p><strong>Total Fixed Costs per year: ${{ a16 }}</strong></p>

            <br>

            <p>Engine Overhaul Reserve: ${{ a19 }}</p>

            <p>Propeller Overhaul Reserve: ${{ a20 }}</p>

            <p><strong>Total Reserve Costs per hour: ${{ a21 }}</strong></p>

            <br>

            <p>Avionics Overhaul/Replacement: ${{ a24 }}</p>

            <p>Aircraft Paint/Interior Refurbishment: ${{ a25 }}</p>

            <p><strong>Total Reserve Costs per year: ${{ a26 }}</strong></p>

            <br>

            <p>Cost Per Hour to Fly (w/o Reserve Costs): ${{ a31 }}</p>

            <p>Cost Per Hour to Fly (w Reserve Costs): ${{ a32 }}</p>

            <p>Total Annual Cost (with Reserve Costs): ${{ a35 }}</p>
          </div>

          <div
            class="col"
            style="color: #f00;"
          >
            <strong>SUGGESTED HOURLY RATE DRY (Without Fuel and without reserves): ${{ a36 }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmaCostCalc',

  data() {
    return {
      c8: '',
      c9: '',
      c10: '',
      c11: '',
      c12: '',
      c13: '',
      c14: '',
      c15: '',
      c16: '',
      c17: '',
      c20: '',
      c21: '',
      c22: '',
      c23: '',
      c24: '',
      c25: '',
      c26: '',
      c27: '',
      c28: '',
      c29: '',
      c30: '',
      c31: '',
      c32: '',
      a29: '',
      a5: '',
      a6: '',
      a7: '',
      a10: '',
      a11: '',
      a12: '',
      a13: '',
      a14: '',
      a15: '',
      a16: '',
      a19: '',
      a20: '',
      a21: '',
      a24: '',
      a25: '',
      a26: '',
      a31: '',
      a32: '',
      a33: '',
      a35: '',
      a36: ''
    };
  },
  methods: {
    calculate() {
      const c8 = parseFloat(this.c8);
      const c9 = parseFloat(this.c9);
      const c10 = parseFloat(this.c10);
      const c11 = parseFloat(this.c11);
      const c12 = parseFloat(this.c12);
      const c13 = parseFloat(this.c13);
      const c14 = parseFloat(this.c14);
      const c15 = parseFloat(this.c15);
      const c16 = parseFloat(this.c16);
      const c17 = parseFloat(this.c17);
      const c20 = parseFloat(this.c20);
      const c21 = parseFloat(this.c21);
      const c22 = parseFloat(this.c22);
      const c23 = parseFloat(this.c23);
      const c24 = parseFloat(this.c24);
      const c25 = parseFloat(this.c25);
      const c26 = parseFloat(this.c26);
      const c27 = parseFloat(this.c27);
      const c28 = parseFloat(this.c28);
      const c29 = parseFloat(this.c29);
      const c30 = parseFloat(this.c30);
      const c31 = parseFloat(this.c31);
      const c32 = parseFloat(this.c32);
      const a29 = parseFloat(this.a29);

      this.a5 = parseFloat(Math.round((c8 * c9) * 100) / 100).toFixed(2);
      this.a6 = parseFloat(Math.round(((c10 / c11) + (c13 * c12)) * 100) / 100).toFixed(2);
      this.a7 = parseFloat(Math.round(((this.a5 * 1) + (this.a6 * 1)) * 100) / 100).toFixed(2);
      this.a10 = parseFloat(Math.round((c20) * 100) / 100).toFixed(2);
      this.a11 = parseFloat(Math.round((c21) * 100) / 100).toFixed(2);
      this.a12 = parseFloat(Math.round((c22) * 100) / 100).toFixed(2);
      this.a13 = parseFloat(Math.round((c25) * 100) / 100).toFixed(2);
      this.a14 = parseFloat(Math.round(((1 * c26) + (1 * c27)) * 100) / 100).toFixed(2);
      this.a15 = parseFloat(
        Math.round(((c29 * c30) + (((1 * c28) - (1 * c29)) * (1 * c31)) - (c28 * c32)) * 100) / 100
      ).toFixed(2);
      this.a16 = parseFloat(
        Math.round(
          ((1 * this.a10) + 1 * this.a11 + 1 * this.a12 + 1 * this.a13 + 1 * this.a14 + 1 * this.a15) * 100
        ) / 100
      ).toFixed(2);
      this.a19 = parseFloat(Math.round((c14 / c15) * 100) / 100).toFixed(2);
      this.a20 = parseFloat(Math.round((c16 / c17) * 100) / 100).toFixed(2);
      this.a21 = parseFloat(Math.round((1 * this.a19 + 1 * this.a20) * 100) / 100).toFixed(2);
      this.a24 = parseFloat(Math.round((c23 / 5) * 100) / 100).toFixed(2);
      this.a25 = parseFloat(Math.round((c24 / 5) * 100) / 100).toFixed(2);
      this.a26 = parseFloat(Math.round((1 * this.a24 + 1 * this.a25) * 100) / 100).toFixed(2);
      this.a31 = parseFloat(Math.round((1 * this.a7 + 1 * (this.a16 / a29)) * 100) / 100).toFixed(2);
      this.a32 = parseFloat(
        Math.round((1 * this.a7 + (this.a16 / a29) + (this.a26 / a29) + 1 * this.a21) * 100) / 100
      ).toFixed(2);
      this.a35 = parseFloat(Math.round((a29 * this.a32) * 100) / 100).toFixed(2);
      this.a36 = parseFloat(Math.round(this.a31 - this.a7)).toFixed(2);

      document.getElementById('app').scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
}

.form {
  margin-top: 2rem;
}

label {
  text-align: left;

  @media only screen and (min-width: 48em) {
    text-align: right;
  }
}

.btn {
  margin: 5rem auto 0;
}
</style>
