import { CHANGE_LOCATION } from '../action-types';
import { LOCATION_CHANGED } from '../mutation-types';

export default function createRouterModule() {
  return {
    actions: {
      [CHANGE_LOCATION]: (context, route) => {
        context.commit(LOCATION_CHANGED, route);
      }
    }
  };
}

export const actions = {
  [CHANGE_LOCATION]: (context, route) => {
    context.commit(LOCATION_CHANGED, route);
  }
};
