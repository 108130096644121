export const ACCOUNT_PREFERENCES_IN_PROGRESS_CREATED = 'accountPreferencesInProgressCreated';
export const ACCOUNT_PREFERENCES_IN_PROGRESS_UPDATED = 'accountPreferencesInProgressUpdated';
export const AFFORDABLE_SHARES_FETCHED = 'affordableSharesFetched';
export const CREDENTIALS_INVALID = 'credentialsInvalid';
export const COUNTS_FETCHED = 'countsFetched';
export const ERRORS_CLEARED = 'errorsCleared';
export const FAVORITE_INSTRUCTORS_FETCHED = 'favoriteInstructorsFetched';
export const FAVORITE_INSTRUCTOR_ADDED = 'favoriteInstructorAdded';
export const FAVORITE_INSTRUCTOR_REMOVED = 'favoriteInstructorRemoved';
export const FAVORITE_SHARES_FETCHED = 'favoriteSharesFetched';
export const FAVORITE_SHARE_ADDED = 'favoriteShareAdded';
export const FAVORITE_SHARE_REMOVED = 'favoriteShareRemoved';
export const GEO_LOCATION_FETCHED = 'geoLocationFetched';
export const HEADER_HEIGHT_UPDATED = 'headerHeightUpdated';
export const HOME_MAP_FETCHED = 'homeMapFetched';
export const INSTRUCTORS_SEARCHED = 'instructorsSearched';
export const INSTRUCTOR_FOLLOWEDUP = 'instructorFollowedup';
export const LOCATION_CHANGED = 'locationChanged';
export const MAP_CENTER_SET = 'mapCenterSet';
export const MAP_MARKER_SELECTED = 'mapMarkerSelected';
export const MAP_RADIUS_SET = 'mapRadiusSet';
export const MAP_ZOOM_SET = 'mapZoomSet';
export const MAP_SEARCHED = 'mapSearched';
export const MODAL_HIDDEN = 'modalHidden';
export const MODAL_VISIBLE = 'modalVisible';
export const MY_SHARES_FETCHED = 'mySharesFetched';
export const NEAREST_SHARES_FETCHED = 'nearestSharesFetched';
export const PASSWORD_RESET = 'passwordReset';
export const POST_IN_PROGRESS_CREATED = 'postInProgressCreated';
export const POST_IN_PROGRESS_SUBMITTED = 'postInProgressSubmitted';
export const POST_IN_PROGRESS_UPDATED = 'postInProgressUpdated';
export const RECENT_SHARES_FETCHED = 'recentSharesFetched';
export const SEARCHED = 'searched';
export const SEARCH_FILTERED_BY_AIRPORT = 'searchFiltedByAirport';
export const SHARES_SEARCHED = 'sharesSearched';
export const SHARE_DELETED = 'shareDeleted';
export const SHARE_DISABLED = 'shareDisabled';
export const SHARE_ENABLED = 'shareEnabled';
export const SHARE_UPDATED = 'shareUpdated';
export const UNSUBSCRIBED = 'unsubscribed';
export const USER_ACCOUNT_DELETED = 'userAccountDeleted';
export const USERS_ADDED = 'usersAdded';
export const USER_ALREADY_EXISTS = 'userAlreadyExists';
export const USER_COUNT_FETCHED = 'userCountFetch';
export const USER_CREATED = 'userCreated';
export const USER_LOGGED_IN_WITH_CREDENTIALS = 'userLoggedInWithCredentials';
export const USER_LOGGED_IN_WITH_FACEBOOK = 'userLoggedInWithFacebook';
export const USER_LOGGED_IN_WITH_GOOGLE = 'userLoggedInWithGoogle';
export const USER_LOGGED_OUT = 'userLoggedOut';
export const USER_PROFILE_FETCHED = 'userProfileFetched';
export const USER_PROFILE_UPDATED = 'userProfileUpdated';
export const WP_HP_POSTS_FETCHED = 'wpHpPostsFetched';
export const WP_POST_FETCHED = 'wpPostFetched';
export const WP_POST_PREVIEWS_FETCHED = 'wpPostPreviewsFetched';
