import fetchApi from './fetch-api';

export default async function updateInstructorProfile(options) {
  const data = new FormData();
  const { avatar, ...instructor } = options;

  data.append('instructor', JSON.stringify(instructor));

  if (avatar) {
    data.append('avatar', avatar.file);
  }

  await fetchApi({
    method: 'PATCH',
    path: '/instructor',
    data
  });
}
