import fetchApi from './fetch-api';

export default async function submitFeedback(options) {
  await fetchApi({
    method: 'POST',
    path: '/contact',
    json: {
      name: options.name,
      email: options.email,
      phone: options.phone,
      reason: options.reason
    }
  });
}
