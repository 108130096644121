export function toShare(share) {
  const [owner] = share.aircraft.owners;

  return {
    id: share.id,
    aircraftId: share.aircraft.id,
    title: share.title,
    lat: share.airport.lat,
    lng: share.airport.long,
    ratePerHour: share.ratePerHour,
    isWet: share.isWet,
    make: share.aircraft.make,
    model: share.aircraft.model,
    year: share.aircraft.year,
    media: share.media,
    defaultMedia: share.defaultMedia,
    city: share.airport.city,
    state: share.airport.state,
    description: share.description,
    url: share.url,
    engineHours: share.aircraft.engineHours,
    engineManufacturer: share.aircraft.engineManufacturer,
    engineModel: share.aircraft.engineModel,
    classification: share.aircraft.classification,
    categories: share.aircraft.categories,
    airport: share.airport,
    aircraftCount: share.airport.count,
    interior: share.aircraft.interior,
    exterior: share.aircraft.exterior,
    avionics: share.aircraft.avionics,
    equipment: share.aircraft.equipment,
    comments: share.aircraft.comments,
    tags: share.aircraft.tags || [],
    instrumentRatingRequired: share.aircraft.instrumentRatingRequired,
    isActive: share.isActive,
    regOwner: share.aircraft.regOwner,
    tailNumber: share.aircraft.tailNumber,
    type: share.type,
    businessName: share.businessName,
    sharePercentOffered: share.sharePercentOffered,
    pricePercentOffered: share.pricePercentOffered,
    desiredNumberOfPilots: share.desiredNumberOfPilots,
    cost: share.aircraft.cost,
    clubName: share.clubName,
    clubRepName: share.clubRepName,
    clubMemberSize: share.clubMemberSize,
    clubNumAircrafts: share.clubNumAircrafts,
    clubWebsiteUrl: share.clubWebsiteUrl,
    clubEmail: share.clubEmail,
    clubPhone: share.clubPhone,
    owner: {
      id: owner && owner.id,
      first: owner && owner.firstname,
      last: owner && owner.lastname,
      email: owner && owner.email
    }
  };
}

export function toInstructor(instructor) {
  return {
    id: instructor.uid,
    ratePerHour: instructor.ratePerHour,
    first: instructor.firstname,
    last: instructor.lastname,
    media: instructor.media,
    about: instructor.about,
    tags: instructor.tags,
    airport: instructor.airport,
    avatar: instructor.avatar,
    email: instructor.email,
    businessName: instructor.businessName,
    bio: instructor.bio
  };
}
