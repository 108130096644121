import fetchApi from './fetch-api';

export default async function deleteUserAccount() {
  const response = await fetchApi({
    method: 'DELETE',
    path: '/user'
  });

  const user = response.data;

  return {
    id: user.uid
  };
}
