<template>
  <div
    class="media-thumbnail"
    @click="click"
  >
    <button
      v-if="delButton"
      class="del"
      @click.stop="del"
    >
      <fa-icon icon="times" />
    </button>
    <div
      v-if="media.type === 'image'"
      class="media aspect-ratio-3-2"
      :style="getBackgroundStyle(media.url)"
    >
      <div
        v-if="rotateButtons"
        class="rotate-buttons"
      >
        <div
          class="rotate-button"
          @click.stop="rotate('left')"
        >
          <fa-icon
            icon="redo"
            class="icon"
          />
        </div>
        <div
          class="rotate-button"
          @click.stop="rotate('right')"
        >
          <fa-icon
            icon="undo"
            class="icon"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="media.type === 'youtube'"
      class="vid-wrapper"
    >
      <iframe
        id="ytplayer"
        type="text/html"
        class="media aspect-ratio-3-2"
        :src="`https://www.youtube.com/embed/${media.url}?autoplay=0`"
        frameborder="0"
      />
    </div>
    <div
      v-else-if="media.type === 'vimeo'"
      class="vid-wrapper"
    >
      <iframe
        :src="`https://player.vimeo.com/video/${media.url}`"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      />
    </div>
    <canvas
      ref="canvas"
      style="display: none;"
    />
  </div>
</template>

<script>
import dataUrlToBlob from '@/utils/data-url-to-blob';
import getBackgroundStyle from '@/utils/get-background-style';

export default {
  name: 'SmaMediaThumbnail',

  props: {
    media: { type: Object, default: () => ({}) },
    delButton: { type: Boolean, default: true },
    rotateButtons: { type: Boolean, default: true }
  },

  methods: {
    getBackgroundStyle,

    click() {
      this.$emit('click');
    },

    del() {
      this.$emit('del');
    },

    async rotate(dir) {
      const newMedia = await new Promise((resolve) => {
        const newImage = new Image();
        newImage.onload = () => {
          const width = newImage.height;
          const height = newImage.width;

          const { canvas } = this.$refs;
          canvas.width = width;
          canvas.height = height;

          const context = canvas.getContext('2d');
          context.clearRect(0, 0, width, height);
          context.translate(width / 2, height / 2);
          switch (dir) {
            case 'left':
              context.rotate(0.5 * Math.PI);
              break;
            case 'right':
              context.rotate(-0.5 * Math.PI);
              break;
            default:
          }
          context.translate(-height / 2, -width / 2);
          context.drawImage(newImage, 0, 0);

          const url = canvas.toDataURL();
          resolve({
            url,
            file: dataUrlToBlob(url),
            type: 'image'
          });
        };

        // Chrome+Safari cache image without CORS headers if loaded previously.
        // Append time as query param to bypass cache.
        const url = new URL(this.media.url);
        if (/^https?:$/.test(url.protocol)) {
          url.searchParams.append('t', Date.now());
        }

        newImage.crossOrigin = '';
        newImage.src = url.href;
      });

      this.$emit('change', newMedia);
    }
  }
};
</script>

<style lang="scss" scoped>
.rotate-buttons {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;

  .rotate-button {
    background: $sma-black;
    border-radius: 1em;
    height: 1.5em;
    width: 1.5em;
    display: flex !important;
    float: left;
    align-items: center;
    justify-content: center;
    margin: 0 0 4px 8px;

    .icon {
      color: $sma-white;
    }
  }
}

.media-thumbnail {
  position: relative;
  display: flex;
  width: 100%;
}

.del {
  position: absolute;
  top: 0;
  right: 0;
  color: $sma-white;
  background: $sma-black;
  border-radius: 1em;
  height: 1.2em;
  width: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.media {
  margin: 0.5rem 0;
  width: 100%;
}

.vid-wrapper {
  display: flex;
  width: 100%;
}

iframe {
  width: 100%;
  max-width: 100%;
}
</style>
