import fetchApi from './fetch-api';

export default async function removeFavoriteShare(share) {
  if (process.env.API_URL) {
    await fetchApi({
      method: 'DELETE',
      path: `/share/favorite/${share.id}`
    });
  }
}
