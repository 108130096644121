import axios from './axios';

export default async function fetchPost(slug) {
  const response = await axios({
    method: 'GET',
    url: 'wp/v2/posts/',
    params: {
      slug,
      _embed: 'wp:featuredmedia'
    }
  });

  return response.data[0];
}
