<template>
  <div class="blog-post">
    <div
      v-if="post"
      class="container"
    >
      <div class="row">
        <div class="col col-sm-24">
          <h1>{{ post.title.rendered }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-24">
          <wp-content
            :content="post.content.rendered"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WpContent from '@/components/WpContent.vue';

import { WP_FETCH_POST } from '@/store/action-types';

export default {
  name: 'SmaBlogPost',

  components: {
    WpContent
  },

  computed: {
    post() {
      return this.$store.getters.wpGetPostBySlug(this.$route.params.slug);
    }
  },

  async serverPrefetch() {
    await this.$store.dispatch(WP_FETCH_POST, this.$route.params.slug);
  },

  async mounted() {
    console.log(this.post);
    if (!this.post) {
      await this.$store.dispatch(WP_FETCH_POST, this.$route.params.slug);
    }
  },

  metaInfo() {
    /* eslint-disable camelcase */
    const yoast = this.post?.yoast_head_json;

    if (!yoast) {
      return null;
    }

    const meta = {
      title: yoast.title,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: yoast.og_title },
        { property: 'og:description', vmid: 'og:description', content: yoast.og_title },
        { property: 'og:type', vmid: 'og:type', content: 'article' }
      ]
    };

    if (yoast.og_image) {
      meta.meta.push(
        { property: 'og:image', vmid: 'og:image', content: yoast.og_image.url },
        { property: 'og:image:width', vmid: 'og:image:width', content: yoast.og_image.height },
        { property: 'og:image:height', vmid: 'og:image:height', content: yoast.og_image.height }
      );
    }

    return meta;
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 2rem;

  @media (min-width: $breakpoint-sm) {
    padding: 0 5rem;
  }
}
</style>
