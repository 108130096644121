<template>
  <div class="post-location-page">
    <div class="container">
      <div class="row">
        <sma-progress
          class="col"
          :step="1"
          :steps="steps"
        />
      </div>
      <div class="row form">
        <div class="col col-sm-12">
          <label
            class="label"
            for="airport"
          >Location</label>
          <sma-airport-select
            id="airport"
            v-model="form.airport"
          />
        </div>
      </div>
      <div class="fail">
        <ul>
          <li
            v-for="(error,i) in errors"
            :key="i"
          >
            {{ error.message }}
          </li>
        </ul>
      </div>
      <div class="row form">
        <div class="col col-sm-12">
          <button
            class="btn yellow inv"
            @click="back"
          >
            Previous step
          </button>
        </div>
        <div class="col col-sm-12">
          <button
            class="btn yellow"
            @click="submit"
          >
            Next step
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

import SmaAirportSelect from '@/components/AirportSelect';
import SmaProgress from '@/components/Progress';
import { UPDATE_POST_IN_PROGRESS } from '@/store/action-types';
import { getAirportErrors } from '@/utils/validators';

export default {
  name: 'SmaPostLocation',

  components: {
    SmaAirportSelect,
    SmaProgress
  },

  data() {
    return {
      steps: [
        ['General', 'Information'],
        ['Your plane', 'location'],
        ['Add Photos', '& Video'],
        ['Preferred Flight', 'Instructor'],
        ['Preview &', 'Confirm']
      ],
      form: {
        airport: ''
      },
      errors: []
    };
  },

  created() {
    if (!isEmpty(this.$store.state.post.inProgress)) {
      Object.assign(this.form, this.$store.state.post.inProgress);
    } else {
      this.$router.replace({ name: 'post' });
    }
  },

  methods: {
    airportSelect(airport) {
      this.form.airport = airport;
    },

    back() {
      this.$router.replace({
        name: 'post-general'
      });
    },

    getErrors() {
      return [].concat(
        getAirportErrors(this.form.airport)
      );
    },

    submit() {
      this.errors = this.getErrors();
      if (this.errors.length > 0) {
        return;
      }

      this.$store.dispatch(UPDATE_POST_IN_PROGRESS, this.form);

      this.$router.push({
        name: 'post-media'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.post-location-page {
  padding: 2rem;
}

.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  .input {
    border: 1px solid $sma-light-gray;
    border-radius: 0.25rem;

    &.text {
      padding: 0.25rem 0.5rem;
      font-weight: 600;

      &:focus {
        border-color: $sma-blue;
      }
    }
  }

  .btn {
    align-self: center;
    margin-top: 5rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}
</style>
