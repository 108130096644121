<template>
  <div
    v-if="activeModalName === 'feedback'"
    class="feedback-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content">
        <p>Thank you for your feedback. We will be in contact with you shortly.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { HIDE_MODAL } from '@/store/action-types';

export default {
  name: 'SmaFeedbackModal',

  computed: {
    ...mapGetters([
      'activeModalName'
    ])
  },

  methods: {
    hide() {
      this.username = '';
      this.password = '';

      this.$store.dispatch(HIDE_MODAL);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1em;
}

.modal {
  text-align: center;
}
</style>
