import Vue from 'vue';

import {
  addFavoriteShare,
  deleteShare,
  disableShare,
  enableShare,
  fetchAffordableShares,
  fetchFavoriteShares,
  fetchMyShares,
  fetchNearestShares,
  fetchRecentShares,
  fetchShares,
  removeFavoriteShare,
  updateShare
} from '@/services/api';
import sleep from '@/utils/sleep';
import {
  ADD_FAVORITE_SHARE,
  DELETE_SHARE,
  DISABLE_SHARE,
  ENABLE_SHARE,
  FETCH_AFFORDABLE_SHARES,
  FETCH_FAVORITE_SHARES,
  FETCH_MY_SHARES,
  FETCH_NEAREST_SHARES,
  FETCH_RECENT_SHARES,
  FILTER_SEARCH_BY_AIRPORT,
  REMOVE_FAVORITE_SHARE,
  SEARCH_SHARES,
  UPDATE_SHARE
} from '../action-types';
import {
  AFFORDABLE_SHARES_FETCHED,
  FAVORITE_SHARES_FETCHED,
  FAVORITE_SHARE_ADDED,
  FAVORITE_SHARE_REMOVED,
  MY_SHARES_FETCHED,
  NEAREST_SHARES_FETCHED,
  RECENT_SHARES_FETCHED,
  SEARCH_FILTERED_BY_AIRPORT,
  SHARES_SEARCHED,
  SHARE_DELETED,
  SHARE_DISABLED,
  SHARE_ENABLED,
  SHARE_UPDATED
} from '../mutation-types';

export default function createAccountPreferencesModule() {
  return {
    state: () => ({
      affordable: [],
      favorite: [],
      mine: [],
      nearest: [],
      recent: [],
      searched: [],
      searchedCount: 0,
      records: {},
      reactHack: false
    }),

    getters: {
      affordableShares: (state, getters) => {
        const tmp = state.reactHack; // eslint-disable-line no-unused-vars
        return state.affordable.map(getters.getShareById);
      },
      favoriteShares: (state, getters) => {
        const tmp = state.reactHack; // eslint-disable-line no-unused-vars
        return state.favorite.map(getters.getShareById);
      },
      myShares: (state, getters) => {
        const tmp = state.reactHack; // eslint-disable-line no-unused-vars
        return state.mine.map(getters.getShareById);
      },
      nearestShares: (state, getters) => {
        const tmp = state.reactHack; // eslint-disable-line no-unused-vars
        return state.nearest.map(getters.getShareById);
      },
      getShareById: state => id => state.records[id],
      recentShares: (state, getters) => {
        const tmp = state.reactHack; // eslint-disable-line no-unused-vars
        return state.recent.map(getters.getShareById);
      },
      searchedShares: (state, getters) => state.searched.map(getters.getShareById),
      searchedSharesCount: state => state.searchedCount || 0,
      shareCountByAirport: (state, getters) => {
        const searched = getters.searchedShares;
        const byAirport = {};

        searched.forEach((share) => {
          if (byAirport[share.airport.locationId]) {
            byAirport[share.airport.locationId]++;
          } else {
            byAirport[share.airport.locationId] = 1;
          }
        });

        return byAirport;
      }
    },

    mutations: {
      [AFFORDABLE_SHARES_FETCHED]: (state, { shares }) => {
        shares.forEach((share) => {
          state.records[share.id] = share;
        });

        state.affordable = shares.map(share => share.id);
      },

      [MY_SHARES_FETCHED]: (state, { shares }) => {
        shares.forEach((share) => {
          state.records[share.id] = share;
        });

        state.mine = shares.map(share => share.id);
      },

      [NEAREST_SHARES_FETCHED]: (state, { shares, page }) => {
        shares.forEach((share) => {
          state.records[share.id] = share;
        });

        state.nearest = page && page > 1
          ? [...state.nearest, ...shares.map(share => share.id)]
          : shares.map(share => share.id);
      },

      [SHARE_DISABLED]: (state, share) => {
        state.reactHack = !state.reactHack;
        Vue.set(state.records, share.id, share);
      },

      [SHARE_ENABLED]: (state, share) => {
        state.reactHack = !state.reactHack;
        Vue.set(state.records, share.id, share);
      },

      [FAVORITE_SHARE_ADDED]: (state, share) => {
        state.favorite.push(share.id);
      },

      [FAVORITE_SHARE_REMOVED]: (state, share) => {
        state.favorite = state.favorite.filter(id => id !== share.id);
      },

      [FAVORITE_SHARES_FETCHED]: (state, { shares }) => {
        shares.forEach((share) => {
          state.records[share.id] = share;
        });

        state.favorite = shares.map(share => share.id);
      },

      [RECENT_SHARES_FETCHED]: (state, { shares, page }) => {
        shares.forEach((share) => {
          state.records[share.id] = share;
        });

        state.recent = page && page > 1
          ? [...state.recent, ...shares.map(share => share.id)]
          : shares.map(share => share.id);
      },

      [SEARCH_FILTERED_BY_AIRPORT]: (state, airportId) => {
        const newSearched = [];

        state.searched.forEach((id) => {
          if (state.records[id].airport.locationId === airportId) {
            newSearched.push(id);
          }
        });

        state.searched = newSearched;
      },

      [SHARE_DELETED]: (state, id) => {
        state.reactHack = !state.reactHack;

        const filter = item => item !== id;

        state.affordable = state.affordable.filter(filter);
        state.recent = state.recent.filter(filter);
        state.favorite = state.favorite.filter(filter);
        state.mine = state.mine.filter(filter);

        Vue.delete(state.records, id);
      },

      [SHARE_UPDATED]: (state, share) => {
        state.reactHack = !state.reactHack;
        Vue.set(state.records, share.id, share);
      },

      [SHARES_SEARCHED]: (state, action) => {
        action.shares.forEach((share) => {
          state.records[share.id] = share;
        });

        if (action.page > 1) {
          state.searched = state.searched.concat(
            action.shares.map(share => share.id)
          );
        } else {
          state.searched = action.shares.map(share => share.id);
          state.searchedCount = action.count;
        }
      }
    },

    actions: {
      [ADD_FAVORITE_SHARE]: async (context, options) => {
        await addFavoriteShare(options);
        context.commit(FAVORITE_SHARE_ADDED, options);
      },

      [DELETE_SHARE]: async (context, options) => {
        await deleteShare(options);
        context.commit(SHARE_DELETED, options);

        const { geoAddress, affordableShares, recentShares } = context.getters;

        if (affordableShares && affordableShares.length < 4) {
          context.dispatch(FETCH_AFFORDABLE_SHARES, {
            address: geoAddress,
            limit: 4
          });
        }

        if (recentShares && recentShares.length < 4) {
          context.dispatch(FETCH_RECENT_SHARES, {
            address: geoAddress,
            limit: 4
          });
        }
      },

      [DISABLE_SHARE]: async (context, share) => {
        const response = await disableShare(share);
        context.commit(SHARE_DISABLED, response.share);
      },

      [ENABLE_SHARE]: async (context, share) => {
        const response = await enableShare(share);
        context.commit(SHARE_ENABLED, response.share);
      },

      [FETCH_AFFORDABLE_SHARES]: async (context, options) => {
        const shares = await fetchAffordableShares(options);

        if (shares) {
          context.commit(AFFORDABLE_SHARES_FETCHED, shares);
        }
      },

      [FETCH_FAVORITE_SHARES]: async (context, options) => {
        const shares = await fetchFavoriteShares(options);

        if (shares) {
          context.commit(FAVORITE_SHARES_FETCHED, shares);
        }
      },

      [FETCH_MY_SHARES]: async (context, options) => {
        const shares = await fetchMyShares(options);

        if (shares) {
          context.commit(MY_SHARES_FETCHED, shares);
        }
      },

      [FETCH_NEAREST_SHARES]: async (context, options) => {
        const shares = await fetchNearestShares(options);

        if (shares) {
          context.commit(NEAREST_SHARES_FETCHED, shares);
        }
      },

      [FETCH_RECENT_SHARES]: async (context, options) => {
        const shares = await fetchRecentShares(options);

        if (shares) {
          context.commit(RECENT_SHARES_FETCHED, shares);
        }
      },

      [FILTER_SEARCH_BY_AIRPORT]: (context, airportId) => {
        context.commit(SEARCH_FILTERED_BY_AIRPORT, airportId);
      },

      [REMOVE_FAVORITE_SHARE]: async (context, options) => {
        await removeFavoriteShare(options);
        context.commit(FAVORITE_SHARE_REMOVED, options);
      },

      [SEARCH_SHARES]: async (context, options) => {
        const shares = await fetchShares(options);

        if (shares) {
          context.commit(SHARES_SEARCHED, shares);
        }
      },

      [UPDATE_SHARE]: async (context, options) => {
        const share = await updateShare(options);
        if (share) {
          await sleep(1000);
          context.commit(SHARE_UPDATED, share);
        }
      }
    }
  };
}

export const state = {
  affordable: [],
  favorite: [],
  mine: [],
  nearest: [],
  recent: [],
  searched: [],
  searchedCount: 0,
  records: {},
  reactHack: false
};

export const getters = {
  affordableShares: (state, getters) => {
    const tmp = state.reactHack; // eslint-disable-line no-unused-vars
    return state.affordable.map(getters.getShareById);
  },
  favoriteShares: (state, getters) => {
    const tmp = state.reactHack; // eslint-disable-line no-unused-vars
    return state.favorite.map(getters.getShareById);
  },
  myShares: (state, getters) => {
    const tmp = state.reactHack; // eslint-disable-line no-unused-vars
    return state.mine.map(getters.getShareById);
  },
  nearestShares: (state, getters) => {
    const tmp = state.reactHack; // eslint-disable-line no-unused-vars
    return state.nearest.map(getters.getShareById);
  },
  getShareById: state => id => state.records[id],
  recentShares: (state, getters) => {
    const tmp = state.reactHack; // eslint-disable-line no-unused-vars
    return state.recent.map(getters.getShareById);
  },
  searchedShares: (state, getters) => state.searched.map(getters.getShareById),
  searchedSharesCount: state => state.searchedCount || 0,
  shareCountByAirport: (state, getters) => {
    const searched = getters.searchedShares;
    const byAirport = {};

    searched.forEach((share) => {
      if (byAirport[share.airport.locationId]) {
        byAirport[share.airport.locationId]++;
      } else {
        byAirport[share.airport.locationId] = 1;
      }
    });

    return byAirport;
  }
};

export const mutations = {
  [AFFORDABLE_SHARES_FETCHED]: (state, { shares }) => {
    shares.forEach((share) => {
      state.records[share.id] = share;
    });

    state.affordable = shares.map(share => share.id);
  },

  [MY_SHARES_FETCHED]: (state, { shares }) => {
    shares.forEach((share) => {
      state.records[share.id] = share;
    });

    state.mine = shares.map(share => share.id);
  },

  [NEAREST_SHARES_FETCHED]: (state, { shares, page }) => {
    shares.forEach((share) => {
      state.records[share.id] = share;
    });

    state.nearest = page && page > 1
      ? [...state.nearest, ...shares.map(share => share.id)]
      : shares.map(share => share.id);
  },

  [SHARE_DISABLED]: (state, share) => {
    state.reactHack = !state.reactHack;
    Vue.set(state.records, share.id, share);
  },

  [SHARE_ENABLED]: (state, share) => {
    state.reactHack = !state.reactHack;
    Vue.set(state.records, share.id, share);
  },

  [FAVORITE_SHARE_ADDED]: (state, share) => {
    state.favorite.push(share.id);
  },

  [FAVORITE_SHARE_REMOVED]: (state, share) => {
    state.favorite = state.favorite.filter(id => id !== share.id);
  },

  [FAVORITE_SHARES_FETCHED]: (state, { shares }) => {
    shares.forEach((share) => {
      state.records[share.id] = share;
    });

    state.favorite = shares.map(share => share.id);
  },

  [RECENT_SHARES_FETCHED]: (state, { shares, page }) => {
    shares.forEach((share) => {
      state.records[share.id] = share;
    });

    state.recent = page && page > 1
      ? [...state.recent, ...shares.map(share => share.id)]
      : shares.map(share => share.id);
  },

  [SEARCH_FILTERED_BY_AIRPORT]: (state, airportId) => {
    const newSearched = [];

    state.searched.forEach((id) => {
      if (state.records[id].airport.locationId === airportId) {
        newSearched.push(id);
      }
    });

    state.searched = newSearched;
  },

  [SHARE_DELETED]: (state, id) => {
    state.reactHack = !state.reactHack;

    const filter = item => item !== id;

    state.affordable = state.affordable.filter(filter);
    state.recent = state.recent.filter(filter);
    state.favorite = state.favorite.filter(filter);
    state.mine = state.mine.filter(filter);

    Vue.delete(state.records, id);
  },

  [SHARE_UPDATED]: (state, share) => {
    state.reactHack = !state.reactHack;
    Vue.set(state.records, share.id, share);
  },

  [SHARES_SEARCHED]: (state, action) => {
    action.shares.forEach((share) => {
      state.records[share.id] = share;
    });

    if (action.page > 1) {
      state.searched = state.searched.concat(
        action.shares.map(share => share.id)
      );
    } else {
      state.searched = action.shares.map(share => share.id);
      state.searchedCount = action.count;
    }
  }
};

export const actions = {
  [ADD_FAVORITE_SHARE]: async (context, options) => {
    await addFavoriteShare(options);
    context.commit(FAVORITE_SHARE_ADDED, options);
  },

  [DELETE_SHARE]: async (context, options) => {
    await deleteShare(options);
    context.commit(SHARE_DELETED, options);

    const { geoAddress, affordableShares, recentShares } = context.getters;

    if (affordableShares && affordableShares.length < 4) {
      context.dispatch(FETCH_AFFORDABLE_SHARES, {
        address: geoAddress,
        limit: 4
      });
    }

    if (recentShares && recentShares.length < 4) {
      context.dispatch(FETCH_RECENT_SHARES, {
        address: geoAddress,
        limit: 4
      });
    }
  },

  [DISABLE_SHARE]: async (context, share) => {
    const response = await disableShare(share);
    context.commit(SHARE_DISABLED, response.share);
  },

  [ENABLE_SHARE]: async (context, share) => {
    const response = await enableShare(share);
    context.commit(SHARE_ENABLED, response.share);
  },

  [FETCH_AFFORDABLE_SHARES]: async (context, options) => {
    const shares = await fetchAffordableShares(options);

    if (shares) {
      context.commit(AFFORDABLE_SHARES_FETCHED, shares);
    }
  },

  [FETCH_FAVORITE_SHARES]: async (context, options) => {
    const shares = await fetchFavoriteShares(options);

    if (shares) {
      context.commit(FAVORITE_SHARES_FETCHED, shares);
    }
  },

  [FETCH_MY_SHARES]: async (context, options) => {
    const shares = await fetchMyShares(options);

    if (shares) {
      context.commit(MY_SHARES_FETCHED, shares);
    }
  },

  [FETCH_NEAREST_SHARES]: async (context, options) => {
    const shares = await fetchNearestShares(options);

    if (shares) {
      context.commit(NEAREST_SHARES_FETCHED, shares);
    }
  },

  [FETCH_RECENT_SHARES]: async (context, options) => {
    const shares = await fetchRecentShares(options);

    if (shares) {
      context.commit(RECENT_SHARES_FETCHED, shares);
    }
  },

  [FILTER_SEARCH_BY_AIRPORT]: (context, airportId) => {
    context.commit(SEARCH_FILTERED_BY_AIRPORT, airportId);
  },

  [REMOVE_FAVORITE_SHARE]: async (context, options) => {
    await removeFavoriteShare(options);
    context.commit(FAVORITE_SHARE_REMOVED, options);
  },

  [SEARCH_SHARES]: async (context, options) => {
    const shares = await fetchShares(options);

    if (shares) {
      context.commit(SHARES_SEARCHED, shares);
    }
  },

  [UPDATE_SHARE]: async (context, options) => {
    const share = await updateShare(options);
    if (share) {
      await sleep(1000);
      context.commit(SHARE_UPDATED, share);
    }
  }
};
