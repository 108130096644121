import { fetchHomeMap } from '@/services/api';

import { FETCH_HOME_MAP } from '../action-types';
import { HOME_MAP_FETCHED } from '../mutation-types';

export default function createHomeMapModule() {
  return {
    state: () => ({
      airports: [],
      count: 0
    }),

    mutations: {
      [HOME_MAP_FETCHED]: (state, airports) => {
        state.airports = airports;
        state.count = airports.reduce(
          (total, airport) => total + airport.shares.length,
          0
        );
      }
    },

    actions: {
      [FETCH_HOME_MAP]: async (context) => {
        const airports = await fetchHomeMap();
        context.commit(HOME_MAP_FETCHED, airports);
      }
    }
  };
}

export const state = {
  airports: [],
  count: 0
};

export const mutations = {
  [HOME_MAP_FETCHED]: (state, airports) => {
    state.airports = airports;
    state.count = airports.reduce(
      (total, airport) => total + airport.shares.length,
      0
    );
  }
};

export const actions = {
  [FETCH_HOME_MAP]: async (context) => {
    const airports = await fetchHomeMap();
    context.commit(HOME_MAP_FETCHED, airports);
  }
};
