export const ADD_FAVORITE_INSTRUCTOR = 'addFavoriteInstructor';
export const ADD_FAVORITE_SHARE = 'addFavoriteShare';
export const ADMIN_DELETE_USER_ACCOUNT = 'adminDeleteUserAccount';
export const CHANGE_LOCATION = 'changeLocation';
export const CREATE_ACCOUNT_PREFERENCES_IN_PROGRESS = 'createAccountPreferencesInProgress';
export const CREATE_INSTRUCTOR = 'createInstructor';
export const CREATE_POST_IN_PROGRESS = 'createPostInProgress';
export const CREATE_CUSTOMER = 'createCustomer';
export const CREATE_SUBSCRIPTION = 'createSubscription';
export const CREATE_USER = 'createUser';
export const DELETE_INSTRUCTOR_ACCOUNT = 'deleteInstructorAccount';
export const DELETE_SHARE = 'deleteShare';
export const DELETE_USER_ACCOUNT = 'deleteUserAccount';
export const DISABLE_SHARE = 'disableShare';
export const ENABLE_SHARE = 'enableShare';
export const FETCH_AFFORDABLE_SHARES = 'fetchAffordableShares';
export const FETCH_COUNTS = 'fetchCounts';
export const FETCH_FAVORITE_INSTRUCTORS = 'fetchFavoriteInstructors';
export const FETCH_FAVORITE_SHARES = 'fetchFavoriteShares';
export const FETCH_GEO_LOCATION = 'fetchGeoLocation';
export const FETCH_HOME_MAP = 'fetchHomeMap';
export const FETCH_MY_SHARES = 'fetchMyShares';
export const FETCH_NEAREST_SHARES = 'fetchNearestShares';
export const FETCH_RECENT_SHARES = 'fetchRecentShares';
export const FETCH_USER_COUNT = 'fetchUserCount';
export const FETCH_USER_PROFILE = 'fetchUserProfile';
export const FILTER_SEARCH_BY_AIRPORT = 'filterSearchByAirport';
export const HIDE_MODAL = 'hideModal';
export const INSTRUCTOR_FOLLOWUP = 'instructorFollowup';
export const INSTRUCTOR_SIGNUP = 'instructorSignup';
export const LOGIN_WITH_CREDENTIALS = 'loginWithCredentials';
export const LOGIN_WITH_FACEBOOK = 'loginWithFacebook';
export const LOGIN_WITH_GOOGLE = 'loginWithGoogle';
export const LOGOUT_USER = 'logoutUser';
export const REMOVE_FAVORITE_INSTRUCTOR = 'removeFavoriteInstructor';
export const REMOVE_FAVORITE_SHARE = 'removeFavoriteShare';
export const RESET_PASSWORD = 'resetPassword';
export const SEARCH = 'search';
export const SEARCH_INSTRUCTORS = 'searchInstructors';
export const SEARCH_SHARES = 'searchShares';
export const SELECT_MAP_MARKER = 'selectMapMarker';
export const SET_GEO_LOCATION = 'setGeoLocation';
export const SET_MAP_CENTER = 'setMapCenter';
export const SET_MAP_RADIUS = 'setMapRadius';
export const SET_MAP_ZOOM = 'setMapZoom';
export const SHOW_MODAL = 'showModal';
export const SUBMIT_FEEDBACK = 'submitFeedback';
export const SUBMIT_POST_IN_PROGRESS = 'submitPostInProgress';
export const UPDATE_ACCOUNT_PREFERENCES_IN_PROGRESS = 'updateAccountPreferencesInProgress';
export const UPDATE_HEADER_HEIGHT = 'updateHeaderHeight';
export const UPDATE_INSTRUCTOR_PROFILE = 'updateInstructorProfile';
export const UPDATE_POST_IN_PROGRESS = 'updatePostInProgress';
export const UPDATE_SHARE = 'updateShare';
export const UPDATE_SUBSCRIPTION = 'updateSubscription';
export const UPDATE_USER_PROFILE = 'updateUserProfile';
export const WP_FETCH_POST_PREVIEWS = 'wpFetchPostPreviews';
export const WP_FETCH_HP_POSTS = 'wpFetchHpPosts';
export const WP_FETCH_POST = 'wpFetchPost';
