<template>
  <div
    v-if="activeModalName === 'signup'"
    class="signup-modal modal-background"
    @click="hide"
  >
    <div
      class="form modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content form">
        <div class="sign-up-text">
          It’s Free to Sign Up
        </div>
        <div
          v-if="activeModal.instructorSignup"
          class="top-blurb"
        >
          <strong>
            Interested in signing up as an instructor?<br>
            First sign up for a free account.
          </strong>
          <div class="divider">
            <div class="line" />
          </div>
        </div>
        <a
          class="btn facebook"
          :href="facebookLoginURL"
        >Continue with Facebook</a>
        <a
          class="btn blue inv"
          :href="googleLoginURL"
        >Continue with Google</a>
        <div class="divider">
          <div class="line" />
          <div class="text">
            or
          </div>
        </div>
        <div class="input-row">
          <input
            v-model="firstName"
            class="input first-name"
            type="text"
            placeholder="First Name"
          >
          <input
            v-model="lastName"
            class="input last-name"
            type="text"
            placeholder="Last Name"
          >
        </div>
        <input
          v-model="email"
          class="input email"
          type="text"
          placeholder="Email address"
        >
        <input
          id="email"
          v-model="honeypot"
          name="email"
          type="email"
          tabindex="-1"
        >
        <input
          v-model="username"
          class="input username"
          type="text"
          placeholder="Username"
        >
        <input
          v-model="password"
          class="input password"
          type="password"
          placeholder="Password"
        >
        <input
          v-model="cpassword"
          class="input cpassword"
          type="password"
          placeholder="Confirm password"
        >

        <!-- Airport -->
        <sma-airport-select
          id="airport"
          v-model="airport"
          placeholder="Primary Airport Name"
        />

        <div class="is-discoverable single-line">
          <label
            class="label"
            for="isDiscoverable"
          >Do you want to be discoverable on the map?</label>
          <client-only>
            <toggle-button
              id="isDiscoverable"
              v-model="isDiscoverable"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              :css-colors="true"
              :width="75"
              :height="36"
              :font-size="14"
            />
          </client-only>
        </div>

        <textarea
          v-model="bio"
          class="input bio"
          placeholder="Tell us something about yourself as a pilot."
        />

        <!-- Media -->
        <div class="row">
          <div class="col">
            <label
              class="label"
              for="avatar"
            >Avatar</label>
            <sma-image-dropzone
              id="avatar"
              class="hidden"
              @change="addAvatar"
            />
          </div>
        </div>
        <div class="row">
          <sma-media-thumbnail
            v-if="avatar"
            class="col col-8"
            :media="avatar"
            @del="removeAvatar"
            @change="updateAvatar"
          />
        </div>

        <!-- RECAPTCHA -->
        <div>
          <vue-recaptcha
            ref="recaptcha"
            :sitekey="recaptchaKey"
            @verify="recaptchaVerified"
          />
        </div>

        <div class="error-message">
          <ul>
            <li
              v-for="(error,i) in errors"
              :key="i"
            >
              {{ error.message }}
            </li>
          </ul>
        </div>
        <button
          class="btn red"
          @click="signup"
        >
          Sign Up For Free
        </button>
        <div class="divider">
          <div class="line" />
        </div>
        <div class="login">
          <span>Already have an account?</span>
          <a
            class="link"
            @click="login"
          >
            Log in
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from 'vuex';

import SmaAirportSelect from '@/components/AirportSelect';
import SmaImageDropzone from '@/components/ImageDropzone';
import SmaMediaThumbnail from '@/components/MediaThumbnail';
import { FACEBOOK_LOGIN_URL, GOOGLE_LOGIN_URL } from '@/services/api';
import { CREATE_USER, HIDE_MODAL, SHOW_MODAL } from '@/store/action-types';
import {
  getEmailErrors,
  getUsernameErrors,
  getPasswordErrors,
  getConfirmedPasswordErrors,
  getRecaptchaError
} from '@/utils/validators';

export default {
  name: 'SmaSignupModal',

  components: {
    SmaAirportSelect,
    SmaMediaThumbnail,
    SmaImageDropzone,
    VueRecaptcha
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      password: '',
      cpassword: '',
      avatar: '',
      airport: null,
      isDiscoverable: true,
      bio: '',
      code: '',
      errors: [],
      honeypot: '',
      recaptchaKey: process.env.RECAPTCHA_KEY
    };
  },

  computed: {
    ...mapGetters(['activeModalName', 'activeModal', 'error']),

    afterSignupNav() {
      return this.activeModal?.afterSignupNav;
    },

    onComplete() {
      const defaultOnComplete = () => {};
      return this.activeModal?.onComplete || defaultOnComplete;
    },

    returnUrl() {
      return this.activeModal?.afterSignupNav
        ? this.$router.resolve(this.activeModal.afterSignupNav).href
        : window.location.href;
    },

    facebookLoginURL() {
      return `${FACEBOOK_LOGIN_URL}?returnUrl=${encodeURIComponent(this.returnUrl)}`;
    },

    googleLoginURL() {
      return `${GOOGLE_LOGIN_URL}?returnUrl=${encodeURIComponent(this.returnUrl)}`;
    }
  },

  methods: {
    recaptchaVerified(code) {
      this.code = code;
    },

    hide() {
      this.username = '';
      this.password = '';

      // if (this.activeModal.find) {
      //   this.$router.push({ name: 'home' });
      // }

      this.$store.dispatch(HIDE_MODAL);
    },

    getErrors() {
      return [].concat(
        getEmailErrors(this.email),
        getUsernameErrors(this.username),
        getPasswordErrors(this.password),
        getConfirmedPasswordErrors(this.password, this.cpassword),
        getRecaptchaError(this.code)
      );
    },

    async signup() {
      if (this.honeypot !== '') {
        console.log(
          `Registration is a suspected bot (honeypot): email ${
            this.email
          } username ${this.username}`
        );
        // return false; // TOOD: Further investigate implementing a honeypot banner
      } else {
        console.log('Human registration detected (no honeypot)');
      }
      this.errors = this.getErrors();

      if (this.errors.length === 0) {
        const user = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          username: this.username,
          password: this.password,
          avatar: this.avatar,
          airport: this.airport,
          isDiscoverable: this.isDiscoverable,
          bio: this.bio,
          code: this.code
        };

        this.password = '';
        this.cpassword = '';

        try {
          await this.$store.dispatch(CREATE_USER, user);
        } catch (err) {
          if (err.response.status === 403) {
            if (err.response.data === 'recaptcha fail') {
              this.errors = [{
                prop: 'recaptcha',
                message: 'ReCAPTCHA failed'
              }];
            }
          }

          if (err.response.status === 409) {
            if (err.response.data === 'user already exists') {
              this.errors = [{
                prop: 'username',
                message: 'Username already in use'
              }];
            }
          }

          this.$refs.recaptcha.reset();

          return;
        }

        // if (this.$store.state.instructors.instructorFollowup) {
        //   this.$store.dispatch(SHOW_MODAL, {
        //     name: 'instructor-signup'
        //   });
        //   // this.$router.push({
        //   //   name: 'manage-subscription'
        //   // });
        // } else {
        //   // this.$router.push({
        //   //   name: 'manage-account'
        //   // });
        // }

        await this.onComplete();

        if (this.afterSignupNav) {
          this.$router.push(this.afterSignupNav);
        }

        this.hide();
      }
    },

    login() {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'login',
        afterLoginNav: this.afterSignupNav,
        onComplete: this.onComplete
      });
    },

    addAvatar(files) {
      const file = files[0];
      if (file) {
        this.avatar = {
          file,
          url: URL.createObjectURL(file),
          type: 'image'
        };
      }
    },

    updateAvatar(media) {
      this.avatar = media;
    },

    removeAvatar() {
      this.avatar = null;
      this.files = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  max-width: 600px;
}

.sign-up-text {
  font-size: 28px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 700;
}

.top-blurb {
  font-size: 20px;
}

.btn {
  &.facebook {
    color: $sma-white;
    background-color: $facebook-blue;
    border-color: $facebook-blue;
  }
}

.divider {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .line {
    background-color: $sma-light-gray;
    height: 2px;
  }

  .text {
    align-self: center;
    margin-top: -0.75rem;
    padding: 0 1.5rem;
    background-color: $sma-white;
    font-size: 0.875rem;
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.875rem;

  @media only screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.link {
  color: $sma-blue;
  font-weight: 600;
}

.forgot-password {
  align-self: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.login {
  align-self: center;
  font-size: 0.875rem;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.input-row {
  display: flex;

  @media only screen and (max-width: $breakpoint-sm) {
    display: block;
  }
}

.first-name {
  flex: 1;
  margin-right: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-right: 0;
  }
}

.last-name {
  flex: 1;
  margin-left: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

input.error {
  border-color: red;
}

.media-container {
  position: relative;
}

.media {
  margin: 0.5rem 0;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}

.single-line {
  align-items: center;
}

.label[for="isDiscoverable"] {
  margin-right: 16px;
}

.is-discoverable {
  margin-top: 4px;
}

.bio {
  min-height: 80px;
}

#isDiscoverable::v-deep {
  .v-switch-core {
    background-color: $sma-gray;
  }

  &.toggled {
    .v-switch-core {
      background-color: $sma-blue;
    }
  }
}
</style>
