<template>
  <div class="signup-page info-page">
    <div class="container">
      <div class="row">
        <h1 class="col header">
          SIGN-UP TODAY!
        </h1>
      </div>

      <div class="row">
        <div class="col col-sm-8 left-side">
          <span><strong>Take advantage of our new features:</strong></span>
          <div class="bullets">
            <div
              v-for="(bullet,i) of bullets"
              :key="i"
              class="row bullet"
            >
              <div class="col col-sm-3 check">
                <fa-icon
                  icon="check-circle"
                  class="check-icon"
                />
              </div>
              <div class="col col-sm-21 bullet-body">
                <h3 class="bullet-title">
                  {{ bullet.title }}
                </h3>
                <div class="bullet-text">
                  {{ bullet.text }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col col-sm-2" />

        <div class="col col-sm-14">
          <div
            class="form"
            @click.stop
          >
            <a
              class="button facebook"
              :href="facebookLoginURL"
            >Continue with Facebook</a>
            <a
              class="button google"
              :href="googleLoginURL"
            >Continue with Google</a>
            <div class="divider">
              <div class="line" />
              <div class="text">
                or
              </div>
              <div class="line" />
            </div>
            <div class="input-row">
              <input
                v-model="firstName"
                class="input first-name"
                type="text"
                placeholder="First Name"
              >
              <input
                v-model="lastName"
                class="input last-name"
                type="text"
                placeholder="Last Name"
              >
            </div>
            <input
              v-model="email"
              class="input email"
              type="text"
              placeholder="Email address"
            >
            <input
              id="email"
              v-model="honeypot"
              name="email"
              type="email"
              tabindex="-1"
            >
            <input
              v-model="username"
              class="input username"
              type="text"
              placeholder="Username"
            >
            <input
              v-model="password"
              class="input password"
              type="password"
              placeholder="Password"
            >
            <input
              v-model="cpassword"
              class="input cpassword"
              type="password"
              placeholder="Confirm password"
            >
            <!-- Media -->
            <div class="row">
              <div class="col">
                <label
                  class="label"
                  for="avatar"
                >Avatar</label>
                <sma-image-dropzone
                  id="avatar"
                  class="hidden"
                  @change="addAvatar"
                />
              </div>
            </div>
            <div class="row">
              <sma-media-thumbnail
                v-if="avatar"
                class="col col-8"
                :media="avatar"
                @del="removeAvatar"
                @change="updateAvatar"
              />
            </div>

            <!-- RECAPTCHA -->
            <div>
              <vue-recaptcha
                :sitekey="recaptchaKey"
                @verify="recaptchaVerified"
              />
            </div>

            <div class="error-message">
              <ul>
                <li
                  v-for="(error,i) in errors"
                  :key="i"
                >
                  {{ error.message }}
                </li>
              </ul>
            </div>
            <button
              class="btn signup"
              @click="signup"
            >
              Sign Up For Free
            </button>
            <div class="divider">
              <div class="line" />
            </div>
            <div class="login">
              <span>Already have an account?</span>
              <button
                class="link"
                @click="login"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from 'vuex';

import SmaImageDropzone from '@/components/ImageDropzone';
import SmaMediaThumbnail from '@/components/MediaThumbnail';
import { FACEBOOK_LOGIN_URL, GOOGLE_LOGIN_URL } from '@/services/api';
import { CREATE_USER, HIDE_MODAL, SHOW_MODAL } from '@/store/action-types';
import {
  getConfirmedPasswordErrors,
  getEmailErrors,
  getPasswordErrors,
  getRecaptchaError,
  getUsernameErrors
} from '@/utils/validators';

export default {
  name: 'SmaSignupModal',

  components: {
    SmaImageDropzone,
    SmaMediaThumbnail,
    VueRecaptcha
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      password: '',
      cpassword: '',
      avatar: '',
      code: '',
      errors: [],
      honeypot: '',
      recaptchaKey: process.env.RECAPTCHA_KEY,
      bullets: [
        {
          title: 'New listing alerts',
          text: 'Get alerts when SHARES are posted to your favorite airports! It\'s cool!'
        },
        {
          title: 'Instructor sign-up',
          text: 'Sign up as an instructor and get noticed and hired by pilots in your area!'
        },
        {
          title: 'Favorite instructors & shares',
          text: 'Keep track of your favorite Aircraft Posts and Instructors'
        },
        {
          title: 'Revenue calculator',
          text: 'See how much you can earn with your aircraft'
        }
      ]
    };
  },

  computed: {
    ...mapGetters(['activeModalName', 'activeModal', 'error']),

    afterSignupNav() {
      return this.activeModal?.afterSignupNav;
    },

    returnUrl() {
      return this.activeModal?.afterSignupNav
        ? this.$router.resolve(this.activeModal.afterSignupNav).href
        : window.location.href;
    },

    facebookLoginURL() {
      return `${FACEBOOK_LOGIN_URL}?returnUrl=${encodeURIComponent(this.returnUrl)}`;
    },

    googleLoginURL() {
      return `${GOOGLE_LOGIN_URL}?returnUrl=${encodeURIComponent(this.returnUrl)}`;
    }
  },

  methods: {
    recaptchaVerified(code) {
      this.code = code;
    },

    hide() {
      this.username = '';
      this.password = '';

      this.$store.dispatch(HIDE_MODAL);
    },

    getErrors() {
      return [].concat(
        getEmailErrors(this.email),
        getUsernameErrors(this.username),
        getPasswordErrors(this.password),
        getConfirmedPasswordErrors(this.password, this.cpassword),
        getRecaptchaError(this.code)
      );
    },

    signup() {
      if (this.honeypot !== '') {
        console.log('bot');
        return;
      }
      console.log('human');

      this.errors = this.getErrors();

      if (this.errors.length === 0) {
        const user = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          username: this.username,
          password: this.password,
          avatar: this.avatar,
          code: this.code
        };

        this.password = '';
        this.cpassword = '';

        this.$store.dispatch(CREATE_USER, user).then(async () => {
          // if (this.$store.state.instructors.instructorFollowup) {
          //   // this.$store.dispatch(SHOW_MODAL, {
          //   //   name: 'instructor-signup'
          //   // });
          //   this.$router.push({
          //     name: 'manage-subscription'
          //   });
          // } else {
          //   this.$router.push({
          //     name: 'manage-account'
          //   });
          // }

          await this.onComplete();

          if (this.afterSignupNav) {
            this.$router.push(this.afterSignupNav);
          }
        });
      }
    },

    login() {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'login'
      });
    },

    addAvatar(files) {
      const file = files[0];
      if (file) {
        this.avatar = {
          file,
          url: URL.createObjectURL(file),
          type: 'image'
        };
      }
    },

    updateAvatar(media) {
      this.avatar = media;
    },

    removeAvatar() {
      this.avatar = null;
      this.files = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.signup {
  margin-bottom: 50px;
}

.header {
  color: $sma-blue;
  margin-bottom: 2rem;
}

.left-side {
  @media only screen and (max-width: $breakpoint-sm) {
    display: none;
  }
}

.bullets {
  margin-top: 1rem;

  .bullet {
    margin-bottom: 1rem;

    .check {
      color: #42d577;
      font-size: 2rem;
      margin-top: -4px;
    }

    .bullet-body {
      .bullet-title {
        color: $sma-blue;
        margin: 0;
        height: 2rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;

  /*
  @media only screen and (max-width: $breakpoint-sm) {
    max-width: 90%;
  }
  */
}

.button {
  cursor: pointer;
  padding: 0.25rem 4rem;
  margin: 0.5rem 0;
  border-style: solid;
  border-width: 1px;
  border-radius: 10rem;
  white-space: nowrap;
  background-color: $sma-white;
  text-align: center;

  &.facebook {
    color: $sma-white;
    background-color: $facebook-blue;
    border-color: $facebook-blue;
  }

  &.signup {
    color: $sma-white;
    background: $sma-red;
    border-color: $sma-red;
  }
}

.divider {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  align-items: center;

  .line {
    background-color: $sma-light-gray;
    height: 2px;
    width: 100%;
  }

  .text {
    align-self: center;
    margin-top: -0.25rem;
    padding: 0 1.5rem;
    font-size: 0.875rem;
    flex-shrink: 0;
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.875rem;

  @media only screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.link {
  color: $sma-blue;
  font-weight: 600;
}

.forgot-password {
  align-self: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.login {
  align-self: center;
  font-size: 0.875rem;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.input-row {
  display: flex;

  @media only screen and (max-width: $breakpoint-sm) {
    display: block;
  }
}

.first-name {
  flex: 1;
  margin-right: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-right: 0;
  }
}

.last-name {
  flex: 1;
  margin-left: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

input.error {
  border-color: red;
}

.media-container {
  position: relative;
}

.media {
  margin: 0.5rem 0;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}
</style>
