import fetchApi from './fetch-api';

export default async function addFavoriteInstructor(instructor) {
  if (process.env.API_URL) {
    await fetchApi({
      method: 'POST',
      path: `/instructor/favorite/${instructor.id}`
    });
  }
}
