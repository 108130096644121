<template>
  <div
    id="instructor-info"
    class="row form"
  >
    <div class="col">
      <label
        class="label"
        for="businessName"
      >Business Name (optional)</label>
      <input
        id="businessName"
        v-model="form.businessName"
        class="input"
        type="text"
      >
    </div>
    <div class="col">
      <label
        class="label"
        for="airport"
      >Airport</label>
      <airport-select
        id="airport"
        v-model="form.airport"
      />
    </div>
    <div class="col">
      <label
        class="label"
        for="rate"
      >Hourly Rate</label>
      <input
        id="rate"
        v-model="form.rate"
        class="input"
        type="text"
      >
      <input
        id="email"
        v-model="form.honeypot"
        name="email"
        type="email"
        tabindex="-1"
      >
    </div>
    <div class="col">
      <label
        class="label"
        for="about"
      >About</label>
      <textarea
        id="about"
        v-model="form.about"
        class="input"
        placeholder="Tell us about your certifications, ie. CFI, CFII, and anything else you would like pilots to know. If you work out of multiple airports, list all the IDS or regions."
      />
    </div>
    <!-- Tags -->
    <div class="col col-24">
      <label
        class="label"
        for="about"
      >Specialties</label>
    </div>
    <div class="row">
      <div
        v-for="tagName in tagNames"
        :key="tagName"
        class="col col-sm-12 horizontal checkbox"
      >
        <input
          :id="tagName"
          v-model="form.tags[tagName]"
          class="input"
          type="checkbox"
        >
        <label
          class="label"
          :for="tagName"
        >
          {{ tagName }}
        </label>
      </div>
    </div>

    <!-- Media -->
    <div class="row">
      <div class="col">
        <label
          class="label"
          for="avatar"
        >Avatar</label>
        <image-dropzone
          id="avatar"
          class="hidden"
          @change="addAvatar"
        />
      </div>
    </div>
    <div class="row">
      <div class="col col-8">
        <media-thumbnail
          v-if="form.avatar"
          :media="form.avatar"
          @del="removeAvatar"
          @change="updateAvatar"
        />
      </div>
    </div>

    <div class="row single-line">
      <label
        class="label"
        for="isCommercial"
      >Are you a flight instructor or commercial pilot?</label>
      <v-select
        id="isCommercialVSelect"
        v-model="form.isCommercial"
        input-id="isCommercial"
        :options="[
          { label: 'Flight Instructor', value: false },
          { label: 'Commercial Pilot', value: true }
        ]"
        :reduce="(opt) => opt.value"
        :clearable="false"
        :filterable="false"
      />
    </div>
  </div>
</template>

<script>
import AirportSelect from '@/components/AirportSelect';
import ImageDropzone from '@/components/ImageDropzone';
import MediaThumbnail from '@/components/MediaThumbnail';
import getBackgroundStyle from '@/utils/get-background-style';
import VSelect from 'vue-select';

import 'vue-select/dist/vue-select.css';

export default {
  name: 'SmaUserInstructorCard',

  components: {
    AirportSelect,
    ImageDropzone,
    MediaThumbnail,
    VSelect
  },

  props: {
    value: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      form: {
        businessName: '',
        rate: '',
        about: '',
        airport: null,
        avatar: null,
        honeypot: '',
        tags: {},
        isCommercial: false
      },
      tagNames: [
        'Private',
        'Instrument',
        'Tail Wheel',
        'Acrobatic',
        'Sea Plane',
        'Multi-Engine',
        'High Performance',
        'Turbo',
        'Jet',
        'Cirrus CSIP / Transition',
        'Helicopter / Rotorcraft'
      ]
    };
  },

  watch: {
    value() {
      this.update();
    },

    form: {
      handler() {
        this.submit();
      },
      deep: true
    }
  },

  created() {
    this.update();
  },

  methods: {
    submit() {
      const tags = [];
      this.tagNames.forEach((tag) => {
        if (this.form.tags[tag]) { tags.push(tag); }
      });

      const value = {
        businessName: this.form.businessName,
        rate: parseInt(this.form.rate),
        about: this.form.about,
        airport: this.form.airport,
        avatar: this.form.avatar,
        tags,
        isCommercial: this.form.isCommercial
      };

      if (this.form.honeypot === '') {
        this.$emit('input', value);
      }
    },

    update() {
      if (this.value) {
        const tags = {};
        if (this.value.tags) {
          this.value.tags.forEach((tag) => {
            tags[tag] = true;
          });
        }


        this.form.businessName = this.value.businessName;
        this.form.rate = this.value.rate || '';
        this.form.about = this.value.about;
        this.form.airport = this.value.airport;
        this.form.avatar = this.value.avatar;
        this.form.tags = tags;
        this.form.isCommercial = this.value.isCommercial;
      }
    },

    addAvatar(files) {
      const file = files[0];
      if (file) {
        this.form.avatar = {
          file,
          url: URL.createObjectURL(file),
          type: 'image'
        };
      }
    },

    updateAvatar(media) {
      this.form.avatar = media;
    },

    removeAvatar() {
      this.form.avatar = null;
      this.files = null;
    },

    getBackgroundStyle
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .label {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 4px 8px;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  display: block;

  &:focus {
    border-color: $sma-blue;
  }
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-top: 5rem;
}

.delete-button {
  background-color: red;
}

.media-container {
  position: relative;
}

.media {
  margin: 0.5rem 0;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}

.single-line {
  align-items: center;
}

#isCommercialVSelect {
  width: 256px;
}

.label[for="isCommercial"],
.label[for="isDiscoverable"] {
  margin-right: 16px;
}

#isDiscoverable::v-deep {
  .v-switch-core {
    background-color: $sma-gray;
  }

  &.toggled {
    .v-switch-core {
      background-color: $sma-blue;
    }
  }
}
</style>
