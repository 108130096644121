// TODO: There is an async await eslint bug here -
// switch to await regardless and re-enable linting in npm test in package.json

import { fetchAddress } from '@/services/google';

import fetchApi from './fetch-api';

function fetchApiMock() {
  return {
    data: {
      city: {
        names: {
          en: 'Louisville'
        }
      },
      subdivisions: [
        {
          iso_code: 'KY', // eslint-disable-line camelcase
          names: {
            en: 'Kentucky'
          }
        }
      ],
      location: {
        latitude: 38.175662,
        longitude: -85.7369231
      }
    }
  };
}

function getLocationGeoIp() {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      return reject(new Error('geolocation not available'));
    }
    // TODO: Convert this to async await
    return navigator.geolocation.getCurrentPosition(
      (pos) => {
        const { coords } = pos;
        console.log(coords);

        fetchAddress(coords.latitude, coords.longitude)
          .catch((err) => {
            reject(err);
          })
          .then((address) => {
            resolve({
              lat: coords.latitude,
              lng: coords.longitude,
              ...address
            });
          });
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export default async function fetchGeoLocation() {
  const fetch = process.env.API_URL ? fetchApi : fetchApiMock;

  let response;
  try {
    response = await fetch({
      method: 'GET',
      path: '/meta/geoip'
    });
  } catch (err) {
    console.error(err);
    response = fetchApiMock();
  }

  let location;
  if (response.data.isCellular) {
    try {
      location = await getLocationGeoIp();
      return location;
    } catch (err) {
      console.error(err);
      response = fetchApiMock();
    }
  }

  location = {
    city: response.data.city.names.en,
    state: response.data.subdivisions[0].iso_code,
    lat: response.data.location.latitude,
    lng: response.data.location.longitude
  };

  return location;
}
