import { SET_MAP_CENTER, SET_MAP_RADIUS, SET_MAP_ZOOM } from '../action-types';

import {
  GEO_LOCATION_FETCHED,
  MAP_CENTER_SET,
  MAP_RADIUS_SET,
  MAP_ZOOM_SET,
  MAP_SEARCHED
} from '../mutation-types';

export default function createMapsModule() {
  return {
    state: () => ({
      center: {
        lat: 37,
        lng: -85
      },
      radius: 30,
      zoom: 10
    }),

    getters: {
      mapCenter: state => state.center,
      mapRadius: state => state.radius,
      mapZoom: state => state.zoom
    },

    mutations: {
      [MAP_SEARCHED]: (state, res) => {
        state.center = {
          lat: res.map.lat,
          lng: res.map.lng
        };
      },

      [GEO_LOCATION_FETCHED]: (state, geo) => {
        state.center = {
          lat: geo.lat,
          lng: geo.lng
        };
      },

      [MAP_CENTER_SET]: (state, center) => {
        state.center = center;
      },

      [MAP_RADIUS_SET]: (state, radius) => {
        state.radius = radius;
      },

      [MAP_ZOOM_SET]: (state, zoom) => {
        state.zoom = zoom;
      }
    },

    actions: {
      [SET_MAP_CENTER]: async (context, options) => {
        context.commit(MAP_CENTER_SET, options);
      },

      [SET_MAP_RADIUS]: async (context, options) => {
        context.commit(MAP_RADIUS_SET, options);
      },

      [SET_MAP_ZOOM]: async (context, options) => {
        context.commit(MAP_ZOOM_SET, options);
      }
    }
  };
}

export const state = {
  center: {
    lat: 37,
    lng: -85
  },
  radius: 30,
  zoom: 10
};

export const getters = {
  mapCenter: state => state.center,
  mapRadius: state => state.radius,
  mapZoom: state => state.zoom
};

export const mutations = {
  [MAP_SEARCHED]: (state, res) => {
    state.center = {
      lat: res.map.lat,
      lng: res.map.lng
    };
  },

  [GEO_LOCATION_FETCHED]: (state, geo) => {
    state.center = {
      lat: geo.lat,
      lng: geo.lng
    };
  },

  [MAP_CENTER_SET]: (state, center) => {
    state.center = center;
  },

  [MAP_RADIUS_SET]: (state, radius) => {
    state.radius = radius;
  },

  [MAP_ZOOM_SET]: (state, zoom) => {
    state.zoom = zoom;
  }
};

export const actions = {
  [SET_MAP_CENTER]: async (context, options) => {
    context.commit(MAP_CENTER_SET, options);
  },

  [SET_MAP_RADIUS]: async (context, options) => {
    context.commit(MAP_RADIUS_SET, options);
  },

  [SET_MAP_ZOOM]: async (context, options) => {
    context.commit(MAP_ZOOM_SET, options);
  }
};
