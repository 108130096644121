<template>
  <div class="coflyt-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Coflyt partnership</h1>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-24">
          <div class="bold">
            <p>ShareMyAircraft members can fly and enjoy #PilotPeaceofMind with Coflyt using promo code "SMA" to save 20% off any annual subscription with 30 days free.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-16">
          <p>Sharemyaircraft is happy to announce a partnership with Coflyt.com that will significantly benefit our aircraft owners, and the pilots who share their planes.</p>
          <p>Coflyt launched in late 2019 with the goal of providing the general aviation community the technology needed to effectively manage aircraft, whether it is used by a single owner or owned and flown by multiple users. Essentially Coflyt  is intelligent aircraft software that combines inspections and maintenance tracking, scheduling, and billing into a simple app, providing peace of mind for aircraft owners and the pilots sharing their planes. Now all pilots know exactly when a plane is being used, when aircraft maintenance is needed, have streamlined communications with each other and can ensure compliance of all issues – all from the palm of their hands! - It’s pretty cool!</p>
          <p>We are excited to partner with Coflyt because Sharing an Aircraft is just the beginning, the next step is effectively managing the partnership and making the experience great for all! Coflyt’s software does exactly that,  as all the heavy lifting is done for you allowing you and your sharers to simply enjoy the experience of flying - It’s a natural fit for Sharemyaircraft.com so check it out.</p>
        </div>
        <div class="col col-sm-8">
          <img
            src="@/assets/coflyt_logo.png"
            class="shrink"
          >

          <form
            action="https://coflyt.com/"
            method="get"
            target="_blank"
          >
            <button
              class="btn blue"
            >
              VISIT COFLYT
            </button>
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col col-sm-12">
          <div class="videos">
            <a
              class="video"
              @click="video(videoCoflyt)"
            >
              <span />
              <img
                src="https://i3.ytimg.com/vi/XQz7UgueNEg/maxresdefault.jpg"
              >
            </a>
          </div>
        </div>

        <div class="col col-sm-12">
          <p>INTELLIGENT AIRCRAFT MANAGEMENT:</p>
          <p>
            <ul>
              <li>Know when a plane is being used</li>
              <li>See maintenance logs and issues</li>
              <li>Maintain Digital Flight Sheet</li>
              <li>View &amp; Analyze Shared Usage</li>
              <li>Visualize Flights &amp; See Stats Across All Partners</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHOW_MODAL } from '@/store/action-types';

export default {
  name: 'SmaCoflyt',

  components: {
    // SmaSidebar
  },

  data() {
    return {
      videoCoflyt: 'https://www.youtube.com/embed/XQz7UgueNEg?autoplay=1'
    };
  },

  methods: {
    video(btnId) {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'video',
        videoURL: btnId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.shrink {
  width: 100%;
  float: left;
  margin-top: 1.2rem;
  margin-right: 1rem;
  font-weight: bold;
}

.bold {
  font-weight: bold;
  font-size: 1.2rem;
}

.btn {
  font-weight: bold;
  position: relative;
  left: 30%;
  margin-top: 4rem;
}

.videos img {
  width: 90%;
  height: auto;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

a.video {
  float: left;
  position: relative;
}

a.video span {
  width: 90%;
  height: 90%;
  position: absolute;
  background: url(https://img.icons8.com/color/480/000000/youtube-play.png) no-repeat;
  background-position: 50% 45%;
  background-size: 20%;
}

@media screen and (max-width: 480px) {
  a.video span {
    background-size: 20%;
  }
}
</style>
