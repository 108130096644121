<template>
  <div class="documents-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Sharing Documents</h1>
        </div>
        <div class="col col-sm-18">
          <h2>
            <a
              class="excel"
              href="/static/time-log-template.xls"
            >Time Log Template</a>
          </h2>
          <h2>
            <a
              class="excel"
              href="/static/hourly-rate-calculator.xlsx"
            >Hourly Rate Calculator</a>
          </h2>

          <div class="partner-text">
            <h2>SMA –YOUR PARTNERSHIP EXPERTS!</h2>
            <p>Partnerships are great WHEN THEY WORK. There is nothing better that sharing all the expenses and having camaraderie with other like-minded highly qualified pilots!</p>
            <p>For most people going into a partnership can be a daunting task. Everyone remembers a story they have heard about a partnership gone bad, and the worst is imagined! However, partnerships are an essential way of life and are enacted in all other areas daily, and if initiated correctly with the right people and the right expectations, they are win-win-win–especially in aviation.</p>
            <p>We have years of experience in aviation partnerships as we founded SMA because we successfully share our planes and enjoy great benefits from these relationships.We spent the time to think through the legal agreements needed to make these partnerships work, whether a simple rental agreement between a few pilots, or a Co-Ownership agreement where all the pilots own the plane together, we invested time and money to make sure these agreements encompass most scenarios one can expect in the aviation world. Some examples on the Co-Ownership front are the following:</p>
            <ul>
              <li>How are the co-owners to schedule the plane most efficiently?</li>
              <li>Are you setting up a reserve fund for unexpected expenses?</li>
              <li>How long can an owner take the plane for an overnight trip?</li>
              <li>How will you decide on spending money for things such as avionic upgrades, or other NON-ESSENTIALLY EXPENDITURES?</li>
              <li>Can a co-owner sell his or her share, if so,what are the terms and conditions?</li>
              <li>What happens if a co-owner passes away, who gets his or her share and what are the terms and conditions in this situation?</li>
              <li>Should the co-owners get a life insurance policy naming the other co-owners as beneficiaries in order for them not to lose the plane.....?</li>
            </ul>
            <p>Some examples on the Rental front (you are renting/”sharing”your plane to another pilot for an hourly fee):</p>
            <ul>
              <li>How are charging for time?–usually this is blocks of 10 hours.</li>
              <li>Again, how are you scheduling the plane?</li>
              <li>Are there any restrictions as to where a sharer can go (ie minimum runway lenghts, distance from home base etc., can he or she fly over water or in IFR?)</li>
              <li>How long can a sharer take the plane for an OVERNIGHT TRIP?</li>
              <li>Do you as the owner have the ability to use the plane even if it is already scheduled to another pilot....if so under what conditions?</li>
            </ul>
            <p>While is sound like there is a lot to think about, and there is, we have thought through these details as we do this with our own planes and have created and refined both a RENTERS AGREEMENT and a CO-OWNERSHIP AGREEMENT. We have worked with the leading aviation law firm to create these agreements at great expense, and can offer these to you and support in making your partnership work:</p>
            <table class="partner-table">
              <tbody>
                <tr>
                  <th scope="row">
                    Renters’ agreement between two or more pilots:
                  </th>
                  <td>$299</td>
                  <!-- <td>(BUY BUTTON)</td> -->
                </tr>
                <tr>
                  <th scope="row">
                    Co-Ownership agreement between two or more pilots:
                  </th>
                  <td>$499</td>
                  <!-- <td>(BUY BUTTON)</td> -->
                </tr>
              </tbody>
            </table>
            <p>In addition, you will get a free 3-month subscription to COFLYT.COM (a $100 value), to help you and your partners setup scheduling and maintenance from the palm of your hands! Also, we will be here to help you with other detailslike calculating a good hourly rate (if it’s a rental) or an appropriate value for your plane (when you are selling shares to others) and we can be a sounding board for you as you embark on a rewarding partnership</p>
            <p>Please fill in this form and one of SMA’s partners will call you to discuss your situation and help you reap the benefits of sharing your plane!</p>
          </div>

          <div id="f3d8c2f2-5db8-46c8-8942-7ac3f7162bc1" />
          <link
            rel="stylesheet"
            media="screen"
            href="https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css"
          >
          <component
            :is="'script'"
            src="https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js"
            clienthub_id="f3d8c2f2-5db8-46c8-8942-7ac3f7162bc1"
            form_url="https://clienthub.getjobber.com/client_hubs/f3d8c2f2-5db8-46c8-8942-7ac3f7162bc1/public/work_request/embedded_work_request_form"
          />
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaDocuments',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
.excel::before {
  content: '';
  display: block;
  height: 2rem;
  width: 2rem;
  background: url('~@/assets/excel.png');
  background-size: contain;
  float: left;
  margin-right: 0.5rem;
}

.partner-text {
  margin-top: 64px;
}

.partner-table {
  text-align: left;

  tr,
  td {
    padding: 8px 16px;
  }
}
</style>
