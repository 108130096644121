import { submitFeedback } from '@/services/api';

import { SUBMIT_FEEDBACK } from '../action-types';

export default function createFeedbackModule() {
  return {
    actions: {
      [SUBMIT_FEEDBACK]: async (context, options) => {
        try {
          await submitFeedback(options);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };
}

export const actions = {
  [SUBMIT_FEEDBACK]: async (context, options) => {
    try {
      await submitFeedback(options);
    } catch (err) {
      console.error(err);
    }
  }
};
