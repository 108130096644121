<template>
  <div
    v-if="activeModalName === 'login'"
    class="login-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content form">
        <a
          class="btn facebook"
          :href="facebookLoginURL"
        >
          Continue with Facebook
        </a>
        <a
          class="btn blue inv"
          :href="googleLoginURL"
        >
          Continue with Google
        </a>
        <div class="divider">
          <div class="line" />
          <div class="text">
            or
          </div>
        </div>
        <input
          v-model="username"
          class="input username"
          type="text"
          placeholder="Username"
        >
        <input
          v-model="password"
          class="input password"
          type="password"
          placeholder="Password"
        >
        <div class="error">
          {{ error }}
        </div>
        <button
          class="btn login"
          @click="login"
        >
          Log in
        </button>
        <a
          class="link forgot-password"
          @click="forgotPassword"
        >
          Forgot Password?
        </a>
        <div class="divider">
          <div class="line" />
        </div>
        <div class="signup">
          <span>Don't have an account?</span>
          <a
            class="link"
            @click="signup"
          >
            Sign Up For Free
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { FACEBOOK_LOGIN_URL, GOOGLE_LOGIN_URL } from '@/services/api';
import { LOGIN_WITH_CREDENTIALS, HIDE_MODAL, SHOW_MODAL } from '@/store/action-types';

export default {
  name: 'SmaLoginModal',

  data() {
    return {
      username: '',
      password: ''
    };
  },

  computed: {
    afterLoginNav() {
      return this.activeModal?.afterLoginNav;
    },

    onComplete() {
      const defaultOnComplete = () => {};
      return this.activeModal?.onComplete || defaultOnComplete;
    },

    returnUrl() {
      return this.activeModal?.afterLoginNav
        ? this.$router.resolve(this.activeModal.afterLoginNav).href
        : window.location.href;
    },

    facebookLoginURL() {
      return `${FACEBOOK_LOGIN_URL}?returnUrl=${encodeURIComponent(this.returnUrl)}`;
    },

    googleLoginURL() {
      return `${GOOGLE_LOGIN_URL}?returnUrl=${encodeURIComponent(this.returnUrl)}`;
    },

    ...mapGetters([
      'activeModalName',
      'activeModal',
      'error'
    ])
  },
  methods: {
    hide() {
      this.username = '';
      this.password = '';

      this.$store.dispatch(HIDE_MODAL);
    },

    async login() {
      const creds = {
        username: this.username,
        password: this.password
      };

      this.password = '';
      await this.$store.dispatch(LOGIN_WITH_CREDENTIALS, creds);

      if (this.error) {
        return;
      }

      await this.onComplete();

      if (this.afterLoginNav) {
        this.$router.push(this.afterLoginNav);
      }

      this.hide();
    },

    forgotPassword() {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'forgot-password'
      });
    },

    signup() {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'signup',
        afterSignupNav: this.afterLoginNav,
        onComplete: this.onComplete
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.btn {
  padding: 0.25rem 0;

  &.facebook {
    color: $sma-white;
    background-color: $facebook-blue;
    border-color: $facebook-blue;
  }
}

.divider {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .line {
    background-color: $sma-light-gray;
    height: 2px;
  }

  .text {
    align-self: center;
    margin-top: -0.75rem;
    padding: 0 1.5rem;
    background-color: $sma-white;
    font-size: 0.875rem;
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.link {
  color: $sma-blue;
  font-weight: 600;
}

.forgot-password {
  align-self: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.signup {
  align-self: center;
  font-size: 0.875rem;
}

.error {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  text-align: center;
  color: $sma-red;
}
</style>
