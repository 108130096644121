import { toShare } from '@/utils/to-models';

import fetchApi from './fetch-api';

export default async function createShare(options) {
  const share = {
    aircraft: {
      regOwner: options.regOwner,
      make: options.make || '',
      model: options.model || '',
      year: parseInt(options.year) || 0,
      owner: parseInt(options.owner) || 0,
      engineHours: parseInt(options.engineHours) || 0,
      engineManufacturer: options.engineManufacturer || '',
      engineModel: options.engineModel || '',
      classification: options.classification || '',
      categories: options.categories || [],
      equipment: options.equipment || '',
      avionics: options.avionics || '',
      interior: options.interior || '',
      exterior: options.exterior || '',
      comments: options.comments || '',
      tags: options.tags || [],
      instrumentRatingRequired: options.instrumentRatingRequired || false,
      tailNumber: options.tailNumber || '',
      cost: options.cost || 0
    },
    ratePerHour: parseInt(`${options.rate}`.replace(/[^\d]/g, '')) || 0,
    isWet: options.isWet,
    description: options.description || '',
    url: options.url || '',
    title: options.title || '',
    airport: parseInt(options.airport.id) || 0,
    media: options.media || [],
    defaultMedia: options.defaultMedia || 0,
    preferredInstructorName: options.preferredInstructorName || '',
    preferredInstructorEmail: options.preferredInstructorEmail || '',
    type: options.type || 'PRIVATE_OWNER_RENTAL',
    businessName: options.businessName || '',
    sharePercentOffered: options.sharePercentOffered || '',
    pricePercentOffered: options.pricePercentOffered || 0,
    desiredNumberOfPilots: options.desiredNumberOfPilots || 1,
    clubName: options.clubName || '',
    clubRepName: options.clubRepName || '',
    clubMemberSize: options.clubMemberSize || 0,
    clubNumAircrafts: options.clubNumAircrafts || 0,
    clubWebsiteUrl: options.clubWebsiteUrl || '',
    clubEmail: options.clubEmail || '',
    clubPhone: options.clubPhone || ''
  };

  const data = new FormData();
  data.append('share', JSON.stringify(share));
  share.media.forEach((m) => {
    if (m.type === 'image' && m.file) {
      data.append('media', m.file);
    }
  });

  const response = await fetchApi({
    method: 'POST',
    path: '/share',
    data
  });

  return toShare(response.data);
}
