import fetchApi from './fetch-api';

export default async function removeFavoriteAirport(airportId) {
  if (process.env.API_URL) {
    await fetchApi({
      method: 'DELETE',
      path: `/user/favorites/airport/${airportId}`
    });
  }
}
