import fetchApi from './fetch-api';

function fetchApiMock() {
  return {
    data: {
      make: 'AVIAT AIRCRAFT INC',
      model: 'A-1B',
      year: '2007',
      serial: '2397'
    }
  };
}

export default async function tailNumber(options) {
  const fetch = process.env.API_URL ? fetchApi : fetchApiMock;

  try {
    const response = await fetch({
      method: 'GET',
      path: '/tailnumber/search',
      query: { tailNumber: options.tailNumber }
    });

    const info = response.data;

    /*
    return {
      serial: info.serial,
      make: info.make,
      model: info.model,
      year: info.year,
    };
    */

    return { ...info };
  } catch (err) {
    console.error(err);
    return null;
  }
}
