<template>
  <div class="dropzone">
    <sma-image-dropzone
      multiple
      class="hidden"
      @click.native.prevent="click"
      @change="addImage"
    >
      Drag an image here<br>or<br>Click to upload an image or video
    </sma-image-dropzone>
    <sma-dialog-modal
      :show="showDialog"
      @close="closeDialog"
    >
      <div
        v-if="dialogPage === 'main'"
        class="buttons"
      >
        <div>
          <label
            for="file-input"
            class="btn yellow"
          >Image <fa-icon icon="image" /></label>
          <input
            id="file-input"
            class="hidden"
            type="file"
            accept=".png,.jpg,.jpeg,image/png,imgage/jpeg"
            multiple
            @change="addImage($event.target.files)"
          >
        </div>
        <div>
          <button
            class="btn youtube-btn"
            @click="dialogPage = 'youtube'"
          >
            Youtube Video <fa-icon :icon="['fab', 'youtube']" />
          </button>
        </div>
        <div>
          <button
            class="btn vimeo-btn"
            @click="dialogPage = 'vimeo'"
          >
            Vimeo Video <fa-icon :icon="['fab', 'vimeo-v']" />
          </button>
        </div>
      </div>
      <div
        v-if="dialogPage === 'youtube'"
        class="link"
      >
        <input
          v-model="youtubeUrl"
          type="text"
          class="text"
          placeholder="Enter YouTube video URL"
        >
        <button
          class="btn yellow"
          @click="addYoutube"
        >
          Okay
        </button>
      </div>
      <div
        v-if="dialogPage === 'vimeo'"
        class="link"
      >
        <input
          v-model="vimeoUrl"
          type="text"
          class="text"
          placeholder="Enter Vimeo video URL"
        >
        <button
          class="btn yellow"
          @click="addVimeo"
        >
          Okay
        </button>
      </div>
    </sma-dialog-modal>
  </div>
</template>

<script>
import SmaDialogModal from '@/components/DialogModal';
import SmaImageDropzone from '@/components/ImageDropzone';

export default {
  name: 'MediaDropzone',

  components: {
    SmaDialogModal,
    SmaImageDropzone
  },

  data() {
    return {
      showDialog: false,
      dialogPage: 'main',
      youtubeUrl: '',
      vimeoUrl: ''
    };
  },

  methods: {
    click() {
      this.openDialog();
    },

    async addImage(files) {
      this.closeDialog();

      const images = [].map.call(files || [], file => ({
        file,
        url: URL.createObjectURL(file),
        type: 'image'
      }));

      this.$emit('change', images);
    },

    addYoutube(e) {
      this.closeDialog();

      const url = new URL(this.youtubeUrl);

      let id;
      if (url.host === 'youtu.be') {
        id = url.pathname.substring(1);
      } else {
        id = url.searchParams.get('v');
      }

      const youtube = [{
        url: id,
        type: 'youtube'
      }];

      this.$emit('change', youtube);
    },

    addVimeo(e) {
      this.closeDialog();

      const vimeo = [{
        url: new URL(this.vimeoUrl).pathname.split('/')[1],
        type: 'vimeo'
      }];

      this.$emit('change', vimeo);
    },

    openDialog() {
      this.showDialog = true;
      this.dialogPage = 'main';
      this.youtubeUrl = '';
      this.vimeoUrl = '';
    },

    closeDialog() {
      this.showDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#file-input {
  display: none;
}

input[type="text"] {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  width: 300px;

  &::placeholder {
    color: #aaa;
  }

  &.text {
    margin-top: 1rem;
    padding: 0.25rem 0.5rem;
    font-size: 1em;
    font-weight: 600;

    &:focus {
      border-color: $sma-blue;
    }
  }
}

.buttons {
  margin: -1em 0;
}

.btn {
  display: block;
  width: 200px;
  margin-top: 2rem;
}

.image-button {
  background-color: $sma-yellow;
  border: 1px solid $sma-yellow;
}

.youtube-btn {
  color: $sma-white;
  background-color: $youtube-red;
  border: 1px solid $youtube-red;
}

.vimeo-btn {
  color: $sma-white;
  background-color: $vimeo-blue;
  border: 1px solid $vimeo-blue;
}

.link {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
