<template>
  <div class="find-instructors-page">
    <div class="form">
      <div class="container">
        <div class="row">
          <div class="col col-sm-7">
            <client-only>
              <gmap-autocomplete
                class="input"
                :value="form.location"
                @place_changed="placeChanged"
              />
            </client-only>
          </div>
          <div class="col col-sm-7">
            <input
              class="input"
              type="text"
              placeholder="Search by keyword"
              :value="form.q"
              @change="qChanged"
            >
          </div>
          <div class="col col-sm-5 specialties-btn">
            <button
              class="btn blue sm-wide"
              @click="toggleSpecialties"
            >
              Specialties <fa-icon
                v-if="showTags"
                icon="chevron-up"
              /><fa-icon
                v-else
                icon="chevron-down"
              />
            </button>
            <div
              v-if="showTags"
              class="specialties"
            >
              <div
                v-for="(tag,i) in tagNames"
                :key="i"
                class="drop"
              >
                <input
                  :id="`tag${i}`"
                  v-model="form.tags"
                  class="input"
                  type="checkbox"
                  :value="tag"
                >
                <label :for="`tag${i}`">{{ tag }}</label>
              </div>
            </div>
          </div>
          <!--<div class="col col-sm-1" />-->
          <div class="col col-sm-4">
            <button
              class="btn yellow sm-wide"
              @click="search(true)"
            >
              Search
            </button>
          </div>
          <div class="col col-sm-6 desktop-hide">
            <button
              v-if="!me || !me.isInstructor"
              class="btn instructor-signup sm-wide"
              @click="instructorSignup"
            >
              Sign up as a professional
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="instructors">
      <div class="container">
        <!-- <div v-if="!me || !me.isInstructor">
          <h2>INSTRUCTORS AND COMMERCIAL PILOTS:</h2>
          <p>List yourself on SMA and get noticed! Whether you are building hours to get to the next level, or already have a robust business flying for individuals or the majors , we can help you get more exposure and earn more income.</p>
          <p>Every month thousands of pilots and non - pilots come to SMA looking for instruction, sign - offs , transition training AND pilots to fly for them AND they need you. We do our best to make sure pilots and non - pilots know about us and therefore about you and we are about to take things to the next level!</p>
          <p>Imagine not only giving flight instruction when and to whom you would like but having the opportunity to manage and fly new high - performance Cirrus SR22 or Diamond D42/62s for partnerships of non - pilots. We are in the process of setting up an offering that allows non - pilots to live the same lifestyle of the uber - rich AND the pilot community, being able to have their own new modern plane that they share with up to 16 other non - pilot, on call when they need it. All that is missing is YOU so let us know if you are interested as we launch this nationwide program, by filling out the form below or checking the box!</p>
          <p>In the meantime, sign yourself up for free and watch what happens!</p>
        </div> -->

        <div class="row sort">
          <div class="col col-sm-6 mobile-hide">
            <button
              v-if="!me || !me.isInstructor"
              class="btn instructor-signup"
              @click="instructorSignup"
            >
              Sign up as a professional
            </button>
          </div>
          <div class="col col-sm-13" />
          <div class="col col-sm-5">
            <select
              v-model="sortBy"
              class="dropdown"
            >
              <option value="dist asc">
                <span class="text">Closest</span>
              </option>
              <option value="ratePerHour desc">
                <span class="text">Price (highest)</span>
              </option>
              <option value="ratePerHour asc">
                <span class="text">Price (lowest)</span>
              </option>
            </select>
          </div>
        </div>

        <div
          v-if="searchedInstructors && searchedInstructors.length"
          class="row col results"
        >
          <div class="row">
            <div
              v-for="instructor in searchedInstructors"
              :key="instructor.id"
              class="col col-sm-12 col-md-6"
            >
              <sma-instructor-card
                :key="instructor.id"
                :instructor="instructor"
              />
            </div>
          </div>
          <div class="row">
            <div class="col row">
              <div class="col col-sm-10" />
              <div class="col col-sm-4">
                <button
                  class="btn"
                  @click="search(false)"
                >
                  <span v-if="!searching">Load More</span>
                  <span v-else><fa-icon
                    icon="circle-notch"
                    spin
                  /></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="container no-instrtuctors"
        >
          <h3>No search results</h3>
          Please change search terms
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmaInstructorCard from '@/components/InstructorCard';
import {
  FETCH_GEO_LOCATION,
  INSTRUCTOR_SIGNUP,
  SEARCH_INSTRUCTORS
} from '@/store/action-types';

const DEFAULT_RADIUS = 250;

const TAG_NAMES = [
  'Private',
  'Instrument',
  'Tail Wheel',
  'Acrobatic',
  'Sea Plane',
  'Multi-Engine',
  'High Performance',
  'Turbo',
  'Jet',
  'Cirrus CSIP / Transition',
  'Helicopter / Rotorcraft'
];

export default {
  name: 'SmaInstructors',

  components: {
    SmaInstructorCard
  },

  data() {
    return {
      form: {
        location: '',
        lat: '',
        lng: '',
        q: '',
        tags: TAG_NAMES.slice()
      },
      sortBy: 'dist asc',
      nextPage: 1,
      searching: false,
      tagNames: TAG_NAMES,
      showTags: false
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'geoAddress',
      'geoLatLng',
      'searchedInstructors'
    ])
  },

  watch: {
    sortBy() {
      this.search(true);
    }
  },

  async mounted() {
    const { $route, $router } = this;

    if ($route.query.signup) {
      const query = { ...$route.query };
      delete query.signup;

      $router.replace({ query });

      this.instructorSignup();
    }

    this.queryToForm();

    if (!this.form.lat || !this.form.lng) {
      await this.$store.dispatch(FETCH_GEO_LOCATION);
      this.form.location = this.geoAddress;
      this.form.lat = this.geoLatLng.lat;
      this.form.lng = this.geoLatLng.lng;
    }

    await this.search(true);
  },

  methods: {
    toggleSpecialties() {
      this.showTags = !this.showTags;
    },

    instructorSignup() {
      this.$store.dispatch(INSTRUCTOR_SIGNUP);
    },

    placeChanged(place) {
      if (place.geometry) {
        this.form.location = place.formatted_address;
        this.form.lat = place.geometry.location.lat();
        this.form.lng = place.geometry.location.lng();
        this.form.radius = DEFAULT_RADIUS;
      } else {
        this.form.location = '';
        this.form.lat = '';
        this.form.lng = '';
        this.form.radius = '';
      }
    },

    qChanged(e) {
      this.form.q = e.target.value;
    },

    formToQuery() {
      const form = { ...this.form };
      Object.keys(form).forEach(key => !form[key] && delete form[key]);

      if (form.tags) {
        form.tags = form.tags.join(',');
      }

      this.$router.replace({ query: { ...form } });
    },

    queryToForm() {
      const form = { ...this.form, ...this.$route.query };

      if (typeof form.tags === 'string') {
        form.tags = form.tags.split(',');
      }

      this.form = form;
    },

    async search(fresh) {
      this.formToQuery();

      if (this.searching) { return; }
      this.searching = true;

      if (fresh) { this.nextPage = 1; }

      this.$store.dispatch(SEARCH_INSTRUCTORS, {
        q: this.form.q,
        lat: this.form.lat,
        lng: this.form.lng,
        limit: 32,
        page: this.nextPage,
        sort: this.sortBy.split(' ')[0],
        sortDir: this.sortBy.split(' ')[1],
        tags: this.form.tags.join(','),
        fresh
      })
        .catch((err) => {
          console.error(err);
        })
        .then(() => {
          this.nextPage++;
        })
        .finally(() => {
          this.searching = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  background-color: $sma-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 0.5rem 1rem;
  border: 0 solid $sma-light-gray;
  border-top-width: 1px;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 0.5rem 1rem;
      margin: 0;

      &:nth-child(3) {
        margin-top: 1rem;
      }
    }

    .btn {
      @media only screen and (max-width: $breakpoint-sm) {
        margin: 0 0.5rem;
      }
    }
  }

  .desktop-hide {
    display: none;

    @media only screen and (max-width: $breakpoint-sm) {
      display: inherit;
    }
  }
}

.rate {
  max-width: 100%;
}

.sort {
  margin-top: 1rem;
}

.label {
  color: $sma-gray;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;

  &.input {
    width: 100%;
    background: #fff;
    border-radius: 10em;
    font-size: 1.25em;
    padding: 0.5em 1em;
  }

  &.model {
    width: 8rem;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }
}

.no-instrtuctors {
  text-align: center;
  padding-bottom: 3rem;
}

.instructors {
  margin: 0;

  .col {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .mobile-hide {
    display: inherit;

    @media only screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }
}

.dropdown {
  display: flex;
  align-items: center;
  color: $sma-gray;
  font-size: 0.875rem;
  border: 1px solid $sma-gray;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  width: 100%;

  .text {
    margin-right: 1rem;
  }
}

.specialties-btn {
  position: relative;

  .specialties {
    position: absolute;
    background-color: $sma-white;
    padding: 1rem;
    z-index: 999;
    top: 100%;

    .drop {
      display: flex;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      input {
        width: auto;
        margin: 0 4px 0 -4px;
      }
    }
  }
}
</style>
