import {
  CREATE_ACCOUNT_PREFERENCES_IN_PROGRESS,
  UPDATE_ACCOUNT_PREFERENCES_IN_PROGRESS
} from '../action-types';
import {
  ACCOUNT_PREFERENCES_IN_PROGRESS_CREATED,
  ACCOUNT_PREFERENCES_IN_PROGRESS_UPDATED
} from '../mutation-types';

export default function createAccountPreferencesModule() {
  return {
    state: () => ({
      inProgress: {}
    }),

    getters: {
      accountPreferencesInProgress: state => state.inProgress
    },

    mutations: {
      [ACCOUNT_PREFERENCES_IN_PROGRESS_CREATED]: (state, options) => {
        state.inProgress = options;
      },

      [ACCOUNT_PREFERENCES_IN_PROGRESS_UPDATED]: (state, options) => {
        Object.assign(state.inProgress, options);
      }
    },

    actions: {
      [CREATE_ACCOUNT_PREFERENCES_IN_PROGRESS]: (context, options) => {
        context.commit(ACCOUNT_PREFERENCES_IN_PROGRESS_CREATED, options);
      },

      [UPDATE_ACCOUNT_PREFERENCES_IN_PROGRESS]: (context, options) => {
        context.commit(ACCOUNT_PREFERENCES_IN_PROGRESS_UPDATED, options);
      }
    }
  };
}

export const state = {
  inProgress: {}
};

export const getters = {
  accountPreferencesInProgress: state => state.inProgress
};

export const mutations = {
  [ACCOUNT_PREFERENCES_IN_PROGRESS_CREATED]: (state, options) => {
    state.inProgress = options;
  },

  [ACCOUNT_PREFERENCES_IN_PROGRESS_UPDATED]: (state, options) => {
    Object.assign(state.inProgress, options);
  }
};

export const actions = {
  [CREATE_ACCOUNT_PREFERENCES_IN_PROGRESS]: (context, options) => {
    context.commit(ACCOUNT_PREFERENCES_IN_PROGRESS_CREATED, options);
  },

  [UPDATE_ACCOUNT_PREFERENCES_IN_PROGRESS]: (context, options) => {
    context.commit(ACCOUNT_PREFERENCES_IN_PROGRESS_UPDATED, options);
  }
};
