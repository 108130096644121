import Vue from 'vue';

import {
  fetchPost,
  fetchPostPreviews
} from '@/services/wp';
import {
  WP_FETCH_POST,
  WP_FETCH_HP_POSTS,
  WP_FETCH_POST_PREVIEWS
} from '../action-types';
import {
  WP_POST_FETCHED,
  WP_HP_POSTS_FETCHED,
  WP_POST_PREVIEWS_FETCHED
} from '../mutation-types';

const POSTS_PER_PAGE = 16;

export default function createWpModule() {
  return {
    state: () => ({
      wpPostsBySlug: {},
      wpPostPreviews: {},
      wpSearchedPosts: [],
      wpHpPosts: [],
      wpPage: 1,
      wpTotal: 0,
      wpTotalPages: 0
    }),

    getters: {
      wpGetPostBySlug: state => slug => state.wpPostsBySlug[slug],

      wpPostPreviews: state => state.wpSearchedPosts.map(id => state.wpPostPreviews[id]),

      wpHpPosts: state => state.wpHpPosts.map(id => state.wpPostPreviews[id]),

      wpPage: state => state.wpPage,

      wpTotalPages: state => state.wpTotalPages
    },

    mutations: {
      [WP_POST_FETCHED]: (state, { slug, post }) => {
        Vue.set(state.wpPostsBySlug, slug, post);
      },

      [WP_POST_PREVIEWS_FETCHED]: (state, { postPreviews, page, total, totalPages }) => {
        postPreviews.forEach((postPreview) => {
          Vue.set(state.wpPostPreviews, postPreview.id, postPreview);
        });
        state.wpSearchedPosts = postPreviews.map(postPreview => postPreview.id);
        state.wpPage = page;
        state.wpTotal = total;
        state.wpTotalPages = totalPages;
      },

      [WP_HP_POSTS_FETCHED]: (state, postPreviews) => {
        postPreviews.forEach((postPreview) => {
          Vue.set(state.wpPostPreviews, postPreview.id, postPreview);
        });
        state.wpHpPosts = postPreviews.map(postPreview => postPreview.id);
      }
    },

    actions: {
      [WP_FETCH_POST]: async (context, slug) => {
        const post = await fetchPost(slug);
        context.commit(WP_POST_FETCHED, { slug, post });
      },

      [WP_FETCH_POST_PREVIEWS]: async (context, { page }) => {
        const results = await fetchPostPreviews({ page, postsPerPage: POSTS_PER_PAGE });
        context.commit(WP_POST_PREVIEWS_FETCHED, {
          ...results,
          page
        });
      },

      [WP_FETCH_HP_POSTS]: async (context) => {
        const results = await fetchPostPreviews({ postsPerPage: 5 });
        context.commit(WP_HP_POSTS_FETCHED, results.postPreviews);
      }
    }
  };
}
