<template>
  <div class="find-page">
    <div class="row">
      <div
        class="col map-container col-sm-16"
        :style="{ height: `calc(100vh - ${headerHeight})` }"
      >
        <!-- Map -->
        <client-only>
          <gmap-map
            ref="map"
            class="map"
            :options="{
              fullscreenControl: false,
              gestureHandling: me ? 'cooperative' : 'none',
              mapTypeControl: false,
              scaleControl: true,
              scrollwheel: false,
              streetViewControl: false,
              zoomControl: false
            }"
            :center="{ lat: 0, lng: 0 }"
            :zoom="0"
            @center_changed="onMapCenterChanged"
            @dragend="onMapDragend"
            @click="onMapClicked"
          >
            <!-- <gmap-cluster
            ref="cluster"
            :styles="[{
              url: clusterIconUrl,
              width: 64,
              height: 64,
              textSize: 14,
              fontWeight: 400,
              fontFamily: 'Roboto, Arial, sans-serif'
            }]"
            :calculator="(markers, numStyles) => {
              const { users, instructors, shares } = markers.reduce((count, marker) => {
                const [users, instructors, shares] = marker.title.split(', ').map(count => parseInt(count.replace(/[^\d]/g, '')));
                count.users += users;
                count.instructors += instructors;
                count.shares += shares;
                return count;
              }, { users: 0, instructors: 0, shares: 0 });

              return {
                index: 1,
                text: users + instructors + shares,
                title: `${markers.length} airports, ${users} pilot${ users !== 1 ? 's' : ''}, ${instructors} professional${ instructors !== 1 ? 's' : ''}, ${shares} plane${ shares !== 1 ? 's' : ''}`
              };
            }"
            @click="onClusterClick"
          > -->
            <gmap-marker
              v-for="count in serachAirportCounts"
              :key="count.airport"
              :position="{
                lat: count.lat,
                lng: count.long
              }"
              :icon="{
                url: createSearchIcon(count),
                scaledSize: {
                  width: 64,
                  height: 64
                }
              }"
              :title="`${count.user} pilot${ count.user !== 1 ? 's' : ''}, ${count.instructor} professional${ count.instructor !== 1 ? 's' : ''}, ${count.share} plane${ count.share !== 1 ? 's' : ''}`"
              @click="onMapMarkerClick(count)"
            />
            <!-- </gmap-cluster> -->

            <gmap-circle
              ref="circle"
              :center="{ lat: form.lat, lng: form.lng }"
              :radius="form.radius * 1000"
              :options="{
                fillOpacity: 0,
                strokeColor: '#006eb0',
                strokeOpacity: 0.5
              }"
            />
          </gmap-map>
        </client-only>

        <div class="legend">
          <div class="header">
            Legend
          </div>
          <div class="content">
            <div class="type plane">
              <img src="@/assets/icons/plane.png">
              Plane
            </div>
            <div class="type pilot">
              <img src="@/assets/icons/pilot.png">
              Pilot
            </div>
            <div class="type professional">
              <img src="@/assets/icons/professional.png">
              Professional
            </div>
          </div>
        </div>

        <!-- Search Box -->
        <div
          v-if="searchBoxVisible"
        >
          <div class="search-box form">
            <!-- Close Btn -->
            <button
              class="close-btn"
              @click="closeBtnClicked"
            >
              <fa-icon icon="times" />
            </button>

            <div class="col col-lg-18">
              <!-- Text Inputs -->
              <div class="row">
                <!-- Location Input -->
                <div class="col col-lg-12">
                  <client-only>
                    <gmap-autocomplete
                      class="input"
                      :value="form.location"
                      @place_changed="onAutocompletePlaceChanged"
                    />
                  </client-only>
                </div>

                <!-- Query Input -->
                <div class="col col-lg-12">
                  <input
                    v-model.lazy.trim="form.q"
                    class="input"
                    type="text"
                    placeholder="Search by keyword"
                    @keyup.enter="onQInputKeyupEnter"
                  >
                </div>
              </div>

              <!-- Dropdowns -->
              <div class="row">
                <!-- Share Categories -->
                <div class="col col-lg-12 categories-btn">
                  <button
                    class="btn blue"
                    @click.stop="onCategoriesBtnClicked"
                  >
                    Plane Categories
                    <fa-icon
                      v-if="!shareCategoryIsVisible"
                      icon="chevron-up"
                    />
                    <fa-icon
                      v-else
                      icon="chevron-down"
                    />
                  </button>

                  <div
                    v-if="shareCategoryIsVisible"
                    v-click-outside="onCategoriesClickOutside"
                    class="categories"
                  >
                    <div
                      v-for="(opt, i) in shareCategoryOptions"
                      :key="opt.value"
                      class="drop"
                    >
                      <input
                        :id="`cat-${i}`"
                        v-model="form.shareCategories"
                        class="input"
                        type="checkbox"
                        :value="opt.value"
                      >
                      <label :for="`cat-${i}`">{{ opt.label }}</label>
                    </div>
                  </div>
                </div>

                <!-- Instructor Specialties -->
                <div class="col col-lg-12 categories-btn">
                  <button
                    class="btn blue"
                    @click.stop="onTagsBtnClicked"
                  >
                    Professional Specialties
                    <fa-icon
                      v-if="!instructorTagIsVisible"
                      icon="chevron-up"
                    />
                    <fa-icon
                      v-else
                      icon="chevron-down"
                    />
                  </button>

                  <div
                    v-if="instructorTagIsVisible"
                    v-click-outside="onTagsClickOutside"
                    class="categories"
                  >
                    <div
                      v-for="(opt, i) in instructorTagOptions"
                      :key="opt.value"
                      class="drop"
                    >
                      <input
                        :id="`cat-${i}`"
                        v-model="form.instructorTags"
                        class="input"
                        type="checkbox"
                        :value="opt.value"
                      >
                      <label :for="`cat-${i}`">{{ opt.label }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col col-lg-6">
              <div class="row search-types">
                <div class="col col-12 col-lg-24">
                  <div>
                    <input
                      id="user-checkbox"
                      v-model="form.searchTypes"
                      type="checkbox"
                      value="user"
                    >
                    <label for="user-checkbox">Pilot</label>
                  </div>
                </div>
                <div class="col col-12 col-lg-24">
                  <div>
                    <input
                      id="instructor-checkbox"
                      v-model="form.searchTypes"
                      type="checkbox"
                      value="instructor"
                    >
                    <label for="instructor-checkbox">Professional</label>
                  </div>
                </div>
                <div class="col col-12 col-lg-24">
                  <div>
                    <input
                      id="share-checkbox"
                      v-model="form.searchTypes"
                      type="checkbox"
                      value="share"
                    >
                    <label for="share-checkbox">Plane</label>
                  </div>
                </div>
              </div>

              <!-- Search Btn -->
              <div class="row">
                <div class="col">
                  <button
                    class="btn yellow"
                    @click="onSearchBtnClicked"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- View All Btn -->
        <div
          v-show="form.airport || form.q"
          class="view-all-btn"
        >
          <button
            class="btn yellow"
            @click="onViewAllBtnClicked"
          >
            Reset Map
          </button>
        </div>

        <!-- Zoom Btns -->
        <div
          class="zoom-btns"
        >
          <button
            class="btn yellow round"
            @click="onHomeBtnClicked"
          >
            <fa-icon icon="location-arrow" />
          </button>
          <button
            class="btn yellow round"
            @click="onZoomInBtnClicked"
          >
            <fa-icon icon="plus" />
          </button>
          <button
            class="btn yellow round"
            @click="onZoomOutBtnClicked"
          >
            <fa-icon icon="minus" />
          </button>
        </div>

        <!-- Show Search Box Btn -->
        <button
          v-if="!searchBoxVisible"
          class="show-search-btn btn yellow round"
          @click="onShowSearchBoxBtnClicked"
        >
          <fa-icon icon="search" />
        </button>
      </div>

      <!-- Results -->
      <div
        class="col col-flex col-sm-8"
        :style="{ height: `calc(100vh - ${headerHeight})` }"
      >
        <div class="results">
          <div class="stats">
            <div class="total">
              Showing {{ searchResults.length || '0' }} of {{ searchCount || '0' }} results
            </div>
            <select
              :value="`${form.sortBy} ${form.sortDir}`"
              class="dropdown"
              @change="onSortBySelectChanged"
            >
              <option
                v-for="opt in sortByOptions"
                :key="opt.value"
                :value="opt.value"
              >
                <span class="text">{{ opt.label }}</span>
              </option>
            </select>
          </div>
          <div
            v-if="searchCount"
            class="shares"
          >
            <div
              v-for="result in searchResults"
              :key="`${result.type}-${result.id}`"
            >
              <sma-search-card
                class="share"
                :search="result"
                @click.native="onSearchCardClick"
              />
            </div>

            <button
              v-if="searchResults.length !== searchCount"
              class="btn yellow"
              :disabled="isSearching"
              @click="onLoadMoreBtnClicked"
            >
              <span v-if="!isSearching">Load More</span>
              <span v-else>
                <fa-icon
                  icon="circle-notch"
                  spin
                />
              </span>
            </button>
          </div>
          <div
            v-else
            class="no-shares"
          >
            <h3>No search results</h3>
            Please change search terms
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { mapGetters } from 'vuex';
import { mergeWith, xor } from 'lodash';

import SmaSearchCard from '@/components/SearchCard';

import {
  FETCH_GEO_LOCATION,
  SEARCH,
  SHOW_MODAL
} from '@/store/action-types';
import createSearchIcon from '@/utils/create-search-icon';

const DEFAULT_RADIUS = 50;
const MAX_RADIUS = 2000;

const hasAllValues = (arr, opts) => xor(arr, opts.map(opt => opt.value)).length === 0;

export default {
  name: 'SmaFind',

  components: {
    SmaSearchCard
  },

  data() {
    return {
      form: {
        location: '',
        lat: 0,
        lng: 0,
        radius: 0,
        q: '',
        airport: null,
        searchTypes: ['user', 'instructor', 'share'],
        shareCategories: [
        ],
        instructorTags: [
        ],
        sortBy: 'dist',
        sortDir: 'asc'
      },
      sortByOptions: [
        { label: 'Closest', value: 'dist asc' },
        { label: 'Price (highest)', value: 'ratePerHour desc' },
        { label: 'Price (lowest)', value: 'ratePerHour asc' },
        { label: 'List Date (newest)', value: 'listDate desc' },
        { label: 'List Date (oldest)', value: 'listDate asc' }
      ],
      shareCategoryOptions: [
        { label: 'Private Owner Rental', value: 'PRIVATE_OWNER_RENTAL' },
        { label: 'Private Owner Share Purchase', value: 'PRIVATE_OWNER_SHARE_PURCHASE' },
        { label: 'Partnership to Purchase New or Used Aircraft', value: 'PARTNERSHIP_REQUEST_TO_PURCHASE' },
        { label: 'Flight School Rental', value: 'FBO_RENTAL' },
        { label: 'Flying Club', value: 'FLIGHT_CLUB' }
      ],
      instructorTagOptions: [
        { label: 'Private', value: 'Private' },
        { label: 'Instrument', value: 'Instrument' },
        { label: 'Tail Wheel', value: 'Tail Wheel' },
        { label: 'Acrobatic', value: 'Acrobatic' },
        { label: 'Sea Plane', value: 'Sea Plane' },
        { label: 'Multi-Engine', value: 'Multi-Engine' },
        { label: 'High Performance', value: 'High Performance' },
        { label: 'Turbo', value: 'Turbo' },
        { label: 'Jet', value: 'Jet' },
        { label: 'Cirrus CSIP / Transition', value: 'Cirrus CSIP / Transition' },
        { label: 'Helicopter / Rotorcraft', value: 'Helicopter / Rotorcraft' }
      ],
      page: 1,
      isSearching: false,
      searchBoxVisible: true,
      shareCategoryIsVisible: false,
      instructorTagIsVisible: false,
      mapCenter: { lat: 0, lng: 0 },
      hasMoved: false,
      markerIconUrl: require('@/assets/map-marker-airport.svg'),
      clusterIconUrl: require('@/assets/map-marker-airport-cluster.svg')
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'geoAddress',
      'geoLatLng',
      'searchResults',
      'searchCount',
      'serachAirportCounts',
      'headerHeight'
    ])
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.formToMap();

        if (
          !this.hasMoved
          && (
            this.form.lat !== this.geoLatLng.lat
            || this.form.lng !== this.geoLatLng.lng
            || this.form.radius !== DEFAULT_RADIUS
          )
        ) {
          this.hasMoved = true;
        }
      }
    }
  },

  // async beforeRouteEnter(to, from, next) {
  //   if (!store.getters.geoIsFetched) {
  //     try {
  //       await store.dispatch(FETCH_GEO_LOCATION);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  //   next();
  // },

  async created() {
    if (!this.$store.getters.geoIsFetched) {
      try {
        await this.$store.dispatch(FETCH_GEO_LOCATION);
      } catch (err) {
        console.error(err);
      }
    }
  },

  mounted() {
    // setTimeout(() => {
    //   if (!this.me) {
    //     this.$store.dispatch(SHOW_MODAL, {
    //       name: 'signup',
    //       find: true
    //     });
    //   }
    // }, 1000);

    this.searchBoxVisible = window.innerWidth > 768;

    this.reset();

    const { query } = this.$route;

    this.form = mergeWith(
      { ...this.form },
      {
        lat: query.lat && parseFloat(query.lat),
        lng: query.lng && parseFloat(query.lng),
        radius: query.radius && parseFloat(query.radius),
        q: query.q,
        location: query.location,
        airport: query.airport,
        sortBy: query.sortBy,
        sortDir: query.sortDir,
        shareCategories: query.shareCategories || this.shareCategoryOptions.map(opt => opt.value),
        instructorTags: query.instructorTags || this.instructorTagOptions.map(opt => opt.value),
        searchTypes: query.searchTypes ? query.searchTypes : ['user', 'instructor', 'share']
      },
      (objVal, srcVal) => {
        if (Array.isArray(srcVal)) {
          return srcVal;
        }

        return undefined;
      }
    );

    this.search({ minResults: 4, replace: true, noShowSignup: true });
  },

  methods: {
    createSearchIcon,

    /* Methods */
    async formToMap() {
      const { map: mapRef } = this.$refs;
      if (!mapRef) { return; }

      const map = await mapRef.$mapPromise;

      const circle = new window.google.maps.Circle({
        center: { lat: this.form.lat, lng: this.form.lng },
        radius: this.form.radius * 1000
      });

      map.fitBounds(circle.getBounds(), 0);
    },

    reset() {
      this.form.location = this.geoAddress;
      this.form.lat = this.geoLatLng.lat;
      this.form.lng = this.geoLatLng.lng;
      this.form.radius = DEFAULT_RADIUS;
    },

    async search({
      fresh = true,
      minResults = 0,
      replace = false,
      noShowSignup = false
    } = {}) {
      if (!this.me && !noShowSignup) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup',
          find: true
        });

        return;
      }
      if (this.isSearching) { return; }

      if (fresh) { this.page = 1; }

      const { form } = this;

      this.$router[replace ? 'replace' : 'push']({ query: {
        lat: form.lat,
        lng: form.lng,
        radius: form.radius,
        q: form.q || undefined,
        location: form.location || undefined,
        airport: form.airport || undefined,
        sortBy: form.sortBy || undefined,
        sortDir: form.sortDir || undefined,
        shareCategories: hasAllValues(form.shareCategories, this.shareCategoryOptions)
          ? undefined
          : form.shareCategories,
        instructorTags: hasAllValues(form.instructorTags, this.instructorTagOptions)
          ? undefined
          : form.instructorTags,
        searchTypes: form.searchTypes.length < 3 ? form.searchTypes : undefined
      } });

      this.isSearching = true;
      try {
        await this.$store.dispatch(SEARCH, {
          lat: form.lat,
          lng: form.lng,
          radius: form.radius * 1000,
          q: form.q,
          location: form.location,
          airport: form.airport,
          sortBy: form.sortBy,
          sortDir: form.sortDir,
          shareCategories: hasAllValues(form.shareCategories, this.shareCategoryOptions)
            ? undefined
            : form.shareCategories,
          instructorTags: hasAllValues(form.instructorTags, this.instructorTagOptions)
            ? undefined
            : form.instructorTags,
          searchTypes: form.searchTypes.length < 3 ? form.searchTypes : undefined,
          page: this.page
        });
      } catch (err) {
        console.error(err);
        return;
      } finally {
        this.isSearching = false;
      }

      this.$nextTick(() => {
        if (this.searchCount < minResults && this.form.radius < MAX_RADIUS) {
          this.form.radius = Math.min(this.form.radius * 2, MAX_RADIUS);
          this.formToMap();
          this.search({ fresh, minResults, replace, noShowSignup });
        }
      });
    },

    /* Event Listeners */
    onSearchCardClick(e) {
      if (!this.me) {
        e.preventDefault();
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup',
          find: true
        });
      }
    },

    onMapDragend() {
      this.form.lat = this.mapCenter.lat;
      this.form.lng = this.mapCenter.lng;
      this.search();
    },

    onMapCenterChanged(mapCenter) {
      this.mapCenter.lat = mapCenter.lat();
      this.mapCenter.lng = mapCenter.lng();
    },

    onZoomInBtnClicked() {
      this.form.radius = Math.max(this.form.radius / 2, 1);
      this.formToMap();
      this.search();
    },

    onZoomOutBtnClicked() {
      this.form.radius = Math.min(this.form.radius * 2, MAX_RADIUS);
      this.formToMap();
      this.search();
    },

    onMapClicked() {
      this.form.airport = null;
      this.search();
    },

    onClusterClick(cluster) {
      const center = cluster.getCenter();

      this.mapCenter.lat = center.lat();
      this.mapCenter.lng = center.lng();

      this.form.lat = this.mapCenter.lat;
      this.form.lng = this.mapCenter.lng;
      this.form.radius = Math.max(this.form.radius / 2, 1);

      this.search();
    },

    onMapMarkerClick(count) {
      this.form.airport = count.airport;
      this.search();
    },

    onAutocompletePlaceChanged(place) {
      this.form.location = place.formatted_address;
      this.form.lat = place.geometry.location.lat();
      this.form.lng = place.geometry.location.lng();
      this.form.radius = DEFAULT_RADIUS;
      this.search();
    },

    onQInputKeyupEnter(e) {
      this.search();
    },

    onCategoriesBtnClicked() {
      this.shareCategoryIsVisible = !this.shareCategoryIsVisible;
    },

    onCategoriesClickOutside() {
      if (this.shareCategoryIsVisible) {
        this.shareCategoryIsVisible = false;
      }
    },

    onTagsBtnClicked() {
      this.instructorTagIsVisible = !this.instructorTagIsVisible;
    },

    onTagsClickOutside() {
      if (this.instructorTagIsVisible) {
        this.instructorTagIsVisible = false;
      }
    },

    closeBtnClicked() {
      this.searchBoxVisible = false;
    },

    onSearchBtnClicked() {
      this.search();
    },

    onLoadMoreBtnClicked() {
      this.page++;
      this.search({ fresh: false });
    },

    onViewAllBtnClicked() {
      this.form.airport = null;
      this.form.q = null;
      this.search();
    },

    onHomeBtnClicked() {
      this.reset();
      this.search();
    },

    onShowSearchBoxBtnClicked() {
      this.searchBoxVisible = true;
    },

    onSortBySelectChanged(e) {
      const [sortBy, sortDir] = e.target.value.split(' ');
      this.form.sortBy = sortBy;
      this.form.sortDir = sortDir;
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
.find-page {
  position: relative;
  height: 100%;
}

.find-page > .row {
  /* height: 100vh; */
  padding-bottom: 2rem;

  /* @media only screen and (max-width: $breakpoint-sm) {
    height: auto;
  } */
}

.find-page {
  @media only screen and (max-width: $breakpoint-sm) {
    height: auto;
  }
}

.col.map-container {
  min-height: 400px;

  /* height: 96vh;

  @media only screen and (max-width: $breakpoint-sm) {
    height: calc(100vh - 100px);
  } */
}

.map {
  height: 100%;
}

.col {
  position: relative;
  height: 100%;
  background-color: $sma-near-white;
}

.form {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  right: 1.5rem;
  display: flex;
  background-color: $sma-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  z-index: 99;

  @media only screen and (max-width: $breakpoint-lg) {
    flex-wrap: wrap;
  }

  .col {
    background: transparent;
  }
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  color: $sma-gray;
  font-size: 1.5em;
  border: 0;
  background-color: transparent;
}

.view-all-btn {
  position: absolute;
  bottom: 1rem;
  left: calc(50% - 100px);
  width: 200px;

  @media only screen and (max-width: $breakpoint-sm) {
    top: 0;
    left: 3.5em;
  }
}

.home-btn {
  position: absolute;
  bottom: 1rem;
  left: 10rem;
}

.zoom-btns {
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
}

.show-search-btn {
  position: absolute;
  top: 0;
  left: 0.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.label {
  color: $sma-gray;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;

  @media only screen and (max-width: $breakpoint-sm) {
    margin: 0.5rem 0;
  }

  &.address {
    width: 100%;
    background: #fff;
    border-radius: 10em;
    font-size: 1.5em;
    padding: 0.2em 0.5em;
  }

  &.model {
    width: 8rem;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }
}

.amount {
  text-align: center;
}

.results {
  flex: 1;
  padding: 1rem;
  height: 100%;
  overflow: auto;
}

.stats {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.dropdown {
  display: flex;
  align-items: center;
  color: $sma-gray;
  font-size: 0.875rem;
  border: 1px solid $sma-gray;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;

  .text {
    margin-right: 1rem;
  }
}

.share {
  margin: 1rem 0;
}

.no-shares {
  text-align: center;
}

.col-flex {
  @media only screen and (max-width: $breakpoint-lg) {
    height: auto;
  }
}

.categories-btn {
  position: relative;

  .categories {
    position: absolute;
    background-color: $sma-white;
    top: 100%;
    padding: 1rem;
    z-index: 999;

    .drop {
      display: flex;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      & input {
        width: auto;
        margin: 0 4px 0 -4px;
      }
    }
  }
}

.search-types {
  @media (min-width: $breakpoint-lg) {
    height: 58px;
  }

  & > .col {
    align-items: center;
    height: auto;

    @media (min-width: $breakpoint-lg) {
      align-items: start;
      margin: 0;

      /* margin-top: 4px; */
    }

    & > * {
      width: fit-content;
    }
  }
}

.legend {
  margin-top: 0;
  flex-direction: column;
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  padding: 1rem;
  background-color: $sma-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  .header {
    font-weight: 700;
  }

  .content {
    display: flex;
    margin-top: 8px;
    flex-direction: column;

    .type {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 0;
        margin-right: 8px;

        @media (min-width: $breakpoint-md) {
          margin-bottom: 8px;
          margin-right: 0;
        }
      }

      img {
        height: 32px;
        width: 32px;
        margin-right: 8px;
      }
    }
  }
}
</style>
