<template>
  <div class="company-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>About Us</h1>
        </div>
        <div class="col col-sm-18">
          <p>ShareMyAircraft.com (SMA) was created to help connect like-minded pilots seeking access to an aircraft, for either business or pleasure, to aircraft owners offering to share their aircraft.</p>
          <p>This can be a simple rental situation (private or and FBO) or an equity share offer as we allow a pilot to advertise their aircraft, no matter the type, with pictures, video and details, to an inherently interested audience – pilots seeking to fly their aircraft. Then, via our geo-location feature, SMA displays relevant listings in the user’s immediate area, with full search and filtering capabilities. Once connected, the pilots can utilize our Share management platform to schedule the aircraft on a shared calendar (COMING SOON), insure that all pilots know everything needed to know in real time.</p>
          <p>More than 225,000 aircraft are registered with the FAA in the United States. Based on percentage of utilization, these planes sit on the ground 97% of the time. When these assets aren’t in use by their owner, they can be shared with other pilots willing to pay a competitive hourly rate, in advance, for access to that aircraft.</p>
          <p>In a typical private rental share, three or four other pilots will share an aircraft and scheduling conflict will be rare. To that end, sharing will much more resemble aircraft ownership and access to the aircraft of your choice will be stress-free and easy. Indeed, since the average pilot flies 3.3 hours/month, this allows ample time for sharing without conflicts.</p>
          <p>Finally, when pilots are carefully screened for experience, aircraft owners can rest assured that their valuable asset is safe and in good hands during use. To be sure, all owners always have final say regarding access to their aircraft, and all sharing pilots must meet insurance and other important prerequisites like a high-altitude or high-performance endorsement or an instrument rating.</p>
          <p>Sharing an aircraft is fun to be sure, but it also makes sound economic sense, and ShareMyAircraft.com is committed to facilitating quality pilot / owner relationships leading to a beneficial aviation experience for all. IT JUST MAKES SENSE!</p>
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
    <div class="container">
      <h2>Our Story</h2>
      <p>In 2007 I bought a used Cirrus SR22, but like most pilots, I was only flying about 4-6 hours a month (FYI, according to the FAA, the average general aviation plane sits on the ground 94% of the time, with the average pilot flying just 3.3 hours/month). However, in spite of only using the plane for this small amount of time, the expenses did not stop Garmin 430 updates, monthly subscriptions, insurance, hanger fees, routine maintenance, and then came the dreaded annual.  I was spending about $15,000/year, or $350/hour to fly my own plane! I decided to try and find another experienced pilot to share my plane, so I posted flyers on the bulletin board at my local airport with no luck, and then finally listed the partnership on the Cirrus Owners website (cirruspilots.org). Here is my original post from 2008:</p>
      <img
        src="@/assets/story1.jpg"
        class="half"
      >
      <p>To my surprise, I quickly got a number of calls from local highly-qualified pilots (instrument ratings and high time-in-type) and settled on two pilots who were signed-off by my local flight instructor, added to my insurance (at no additional charge) and who paid me 10-hour blocks in advance to fly the plane.</p>
      <img
        src="@/assets/raj.jpg"
        class="raj"
        title="Raj and Jill on their way to Oshkosh 2018 in the Husky A1-B — A shared plane!"
      >
      <p>The plane was flying and happy and well kept, I was flying and happy, and best of all, I was making valuable money to cover my fixed expenses and then some.  This dramatically changed the aircraft ownership equation for me. It was a win/win and the best part was even with all three of us flying all we wanted, there were few, if any conflicts, easily resolved!  I was getting $185/hour dry and this was awesome.  My wife Jill said to me, “hey there might be something here – this is really needed and could be great for a pilot and general aviation overall.” – as I explored this further, I found that many pilots already shared their planes, but the process of finding other pilots was difficult and inefficient.  General aviation airports bulletin boards are littered with flyers from pilots looking for airplane shares, hanger shares and ownership partnerships, and there was no single website where one could post or find a share, but there were many fragmented owners’ sites, where shares were posted in forums, only to be lost, when the next post was added.</p>
      <h2>These are just a few postings at KJYO - There are 6300+ general aviation airports in the US</h2>
      <img
        src="@/assets/posters.png"
        class="posters"
      >
      <p>There had to be a better way and so came the idea of - SHAREMYAIRCRAFT.COM.  SMA was shelved for a number of years due to the economy, until we finally decided it was the right time – so about in late 2016, we started working on SMA – and now everything is lining up to make this the best time for SMA!  Why?</p>
      <ol>
        <li>Aircraft are more expensive than ever to buy and maintain so pilots can really use the added income from a sharer – just to make it all work! Flying magazine (AUG 2018) recently noted that “The price of a new Cessna Skyhawk in 1968 took 16 Months (of one’s income) to buy, where today it takes six years to purchase. Clearly airplane prices have far outstripped general inflation!” –  (FYI - We will soon be allowing pilots to post partnership requests for buying a new plane at their specific airport, a great way to buy a new plane for a fraction of the costs)</li>
        <li>General aviation has a new influx of pilots from the passing of FAA’s BASIC MED program, where over 30,000 experienced pilots (and growing every day) have reentered active flight and will need planes!</li>
        <li>There are so many technologically advanced, innovative and LSA planes coming out that could easily and financially comfortably be shared with 2-3 (or more pilots) that would be great additions to their existing plane, such as the ICON A-5, Vashon Ranger (&lt;100k), many new electric options and the Carbon Cub, just to mention a few - !  Fun planes to have in addition to your primary plane.</li>
        <li>So many pilots would love to fly other types of planes in addition to their primary plane, such as acrobatic, tail-wheel, sea planes, etc – now you can!</li>
        <li>There are so many flight schools with planes to rent, but there is no one source to find and learn about the rental options…until now!</li>
        <li>There is no one source to find a local flight instructor and learn about their qualifications…until now!</li>
        <li>Finally, IT JUST MAKES SENSE:</li>
      </ol>
      <div class="indent">
        <strong>FINANCIAL SENSE</strong>
        <ul>
          <li>Cover fixed expenses and make money in the process</li>
          <li>Get into an airplane that resembles ownership for a fraction of what it normal cost</li>
        </ul>
        <strong>MECHANICAL SENSE</strong>
        <ul>
          <li>Keep the plane flying and running and the engine happy</li>
          <li>Keep the airframe used and maintained</li>
        </ul>
        <strong>SOCIAL AND RESPONSIBLE SENSE</strong>
        <ul>
          <li>Having an asset utilized only 6-7% of the time is a waste and irresponsible if another choice is available</li>
          <li>Meet other like-minded experienced pilots and develop relationships</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaCompany',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
}

.half {
  width: 50%;
  float: left;
  margin-right: 1rem;
}

.raj {
  width: 22%;
  float: right;
  margin-left: 1rem;
}

.posters {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.indent {
  margin-left: 3rem;
}
</style>
