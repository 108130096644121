<template>
  <div class="posting-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Tips For Listing</h1>
        </div>
        <div class="col col-sm-18">
          <p>In your ad, be as descriptive and specific as possible. For example, if you’re posting an offer for a share of your Piper PA28r200, make sure you also refer to it as a Piper Arrow II, which is the model’s more common name. Not everyone knows all the suffixes of the PA28 line and instead they’ll simply search Piper Arrow. Maximize your search results by including both name and model number in your title and description.</p>
          <p>Also be sure to list all the features and special equipment your aircraft may have. Does it have a glass cockpit? Be sure to detail that and if it’s by Avidyne or Garmin or another company. Does it have any mods? I’m sure you’re prospects would love to know.</p>
          <p>And do you or your insurance company require any specific pilot experience minimums? Also important to note those.</p>
          <p>The more you can list upfront, the better the prospects you’ll hear from and the sharing process will be even more streamlined.</p>
          <p>Once your share listing is created, PRINT MULTIPLE FLYERS and post them at your local FBO, local airport restaurant, and any other places pilots may frequent.  This is a great feature of SMA, we help you to format an effective ad or flyer.(COMING SOON)</p>
          <p>Price your share fairly and competitively. Use the Hourly Rate Calculator to tally your costs as a guideline for setting your price. Another benchmark would be the rate an FBO charges when renting a similar plane. You should be able to set your price 10-15% lower than the FBO or school since you won’t need a commercial insurance policy and through other savings.</p>
          <p>Make sure you check all the type and feature (I think some of the boxes need to be adjusted and clarified) boxes that apply to your aircraft.  For example, you might fly a single-engine, turbocharged, amphibious aircraft, so all of those boxes would apply. Remember that a user will search by type to find the right plane for them.</p>
          <p>For added exposure, and to be a featured listing on the homepage slideshow, choose the slideshow option when creating your listing. This will highlight your listing, with full pictures and description, to a user near you.</p>
          <p>Use great pictures, and add a video link to YOUTUBE.  This will allow a pilot to really see the plane before you meet. Remember, part of the benefit of sharing is for the camaraderie with like-minded pilots!</p>
          <p>Try to have friends who have flown with you comment on you and  your aircraft. And ask them to rate you if they have shared your plane with you.</p>
          <p>Post your listing on FACEBOOK, LINKEDIN and TWITTER for maximum exposure — The buttons are all set up for you!</p>
        </div>
        <div class="col col-sm-1">
          <!-- spacer -->
        </div>
        <div class="col col-sm-5">
          <sma-sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaTipsForPosting',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
</style>
