import { fetchGeoLocation } from '@/services/api';
import { fetchLatLng } from '@/services/google';

import { FETCH_GEO_LOCATION, SET_GEO_LOCATION } from '../action-types';
import { GEO_LOCATION_FETCHED } from '../mutation-types';

export default function createGeoModule() {
  return {
    state: () => ({
      city: null,
      state: null,
      lat: null,
      lng: null,
      isFetched: false
    }),

    getters: {
      geoLatLng: state => ({ lat: state.lat, lng: state.lng }),
      geoAddress: (state) => {
        if (state.city) {
          return `${state.city}, ${state.state}`;
        }

        return state.state;
      },
      geoIsFetched: state => state.isFetched
    },

    mutations: {
      [GEO_LOCATION_FETCHED]: (state, geoLocation) => {
        state.city = geoLocation.city;
        state.state = geoLocation.state;
        state.lat = geoLocation.lat;
        state.lng = geoLocation.lng;
        state.isFetched = true;
      }
    },

    actions: {
      [FETCH_GEO_LOCATION]: async (context) => {
        const geoLocation = await fetchGeoLocation();

        if (geoLocation) {
          context.commit(GEO_LOCATION_FETCHED, geoLocation);
        }
      },

      [SET_GEO_LOCATION]: async (context, address) => {
        const latlng = await fetchLatLng({ address });

        if (latlng) {
          context.commit(GEO_LOCATION_FETCHED, {
            ...latlng,
            city: address.substring(0, address.lastIndexOf(',')),
            state: address.substring(address.lastIndexOf(',') + 1)
          });
        }
      }
    }
  };
}

export const state = {
  city: null,
  state: null,
  lat: null,
  lng: null,
  isFetched: false
};

export const getters = {
  geoLatLng: state => ({ lat: state.lat, lng: state.lng }),
  geoAddress: (state) => {
    if (state.city) {
      return `${state.city}, ${state.state}`;
    }

    return state.state;
  },
  geoIsFetched: state => state.isFetched
};

export const mutations = {
  [GEO_LOCATION_FETCHED]: (state, geoLocation) => {
    state.city = geoLocation.city;
    state.state = geoLocation.state;
    state.lat = geoLocation.lat;
    state.lng = geoLocation.lng;
    state.isFetched = true;
  }
};

export const actions = {
  [FETCH_GEO_LOCATION]: async (context) => {
    const geoLocation = await fetchGeoLocation();

    if (geoLocation) {
      context.commit(GEO_LOCATION_FETCHED, geoLocation);
    }
  },

  [SET_GEO_LOCATION]: async (context, address) => {
    const latlng = await fetchLatLng({ address });

    if (latlng) {
      context.commit(GEO_LOCATION_FETCHED, {
        ...latlng,
        city: address.substring(0, address.lastIndexOf(',')),
        state: address.substring(address.lastIndexOf(',') + 1)
      });
    }
  }
};
