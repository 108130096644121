<template>
  <div class="footer">
    <div class="testimonials">
      <div class="container">
        <div
          class="row"
          style="width: 70%; margin: 20px auto; height: 400px;"
        >
          <div
            class="pre"
            @click="slidePre"
          />
          <client-only>
            <slider
              ref="slider"
              :pages="testimonials"
              :sliderinit="testimonialinit"
              @slide="slide"
              @tap="onTap"
              @init="onInit"
            >
              <div slot="loading">
                loading...
              </div>
            </slider>
          </client-only>
          <div
            class="next"
            @click="slideNext"
          />
        </div>
      </div>
    </div>
    <div class="hover-zone">
      <div class="container top">
        <div class="row">
          <div class="col col-sm-8">
            <a href="tel:6503365837">
              <img src="@/assets/phone.png">
              <h1>Call Us</h1>
            </a>
          </div>
          <div class="col col-sm-8">
            <a href="mailto:support@sharemyaircraft.com">
              <img src="@/assets/mail.png">
              <h1>Email Us</h1>
            </a>
          </div>
          <div class="col col-sm-8">
            <router-link :to=" { name: 'faq' }">
              <img src="@/assets/help.png">
              <h1>FAQ's</h1>
            </router-link>
          </div>
        </div>
      </div>
      <hr>
      <div class="container bottom">
        <div class="row">
          <div class="col col-sm-5">
            <h2>Company</h2>
            <router-link :to=" { name: 'company' }">
              About Us
            </router-link>
            <router-link :to=" { name: 'investors' }">
              Investors
            </router-link>
            <router-link :to=" { name: 'our-team' }">
              Our Team
            </router-link>
            <router-link :to=" { name: 'how-it-works' }">
              How It Works
            </router-link>
            <router-link :to=" { name: 'feedback' }">
              Give Feedback
            </router-link>
            <router-link :to=" { name: 'faq' }">
              FAQ's
            </router-link>
          </div>
          <div class="col col-sm-5">
            <h2>Services</h2>
            <router-link :to=" { name: 'find' }">
              Find Shares
            </router-link>
            <router-link :to=" { name: 'post' }">
              List Shares
            </router-link>
            <router-link :to=" { name: 'instructors' }">
              Find Instructors
            </router-link>
          </div>
          <div class="col col-sm-5">
            <h2>Popular Locations</h2>
            <a
              v-for="loc of popularLocations"
              :key="loc.abbr"
              :href="`https://find.sharemyaircraft.com/${loc.abbr.toLowerCase()}`"
            >
              {{ loc.name }}
            </a>
          </div>
          <div class="col col-sm-3">
            <!-- Spacer -->
          </div>
          <div class="col col-sm-6">
            <h2>Follow us on:</h2>
            <div class="social">
              <a
                href="https://www.facebook.com/sharemyaircraft/"
                target="_blank"
                class="facebook"
              />
              <a
                href="https://twitter.com/sharemyaircraft"
                target="_blank"
                class="twitter"
              />
              <a
                href="https://www.instagram.com/sharemyaircraft/?hl=en"
                target="_blank"
                class="instagram"
              />
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="container copy">
        <div class="row">
          <div class="col">
            <p>
              ©{{ new Date().getFullYear() }} ShareMyAircraft.com All Rights Reserved  |  <router-link :to=" { name: 'privacy' }">
                Privacy Policy
              </router-link>  |  <router-link :to=" { name: 'terms' }">
                Terms of Service
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmaFooter',

  data() {
    return {
      testimonials: [
        {
          html: '<img style="display: block; margin: 0 auto 24px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA2CAYAAACfkiopAAAACXBIWXMAAAsSAAALEgHS3X78AAAECUlEQVRoBcVb3W0iMRAeVilgO0g6OKggRNr3CxUcqSDkHemCtO8hFYRUEPKORK6CcB1sCS7hNNznaFm89nj9k09CQrB45vOMPT82IxKgXO7GRDTlt62nFREd+KXqSknGCUG53LFsrUcbDXQ4+AzfSxyCFkR03yFsAgt9JaJN7Ekol7spdLh1PMpyt6yHqqsP17hG4uVyNyeiJwFhk/CVqqu15+9MOrDsFwFhE5j4g80LzoiXyx0LmwfqzYJnQ62PpfUC1w7Bnaqrjen3o47AGKQ1eLZvfMnD0p9EdBVJD15+d90Pi5bA24ikCdbag4gP3iKSZsyxdE9wJN5aT7HB5H9Lx4SC3V07Bl6wfE6JY/f2tYwUC+zMEognaQBODKuJ/0ookCSEsNRiungX47bLF3CBlAIZ066rGXCdWAfGvX5TRAgZUri8KoceY22AIoO1NVyJSC4DHPUo3M9Fw5XD3VNtrl0cl1RO4uSwavJCBzhGmCKjQMYPy3de1VUI2PMK5NW5YLN4k1GPskAFk9PqfXjPKGuq17ixgkmAXourutrmNIAm/pxJnmvnzqXHf+Kqrnh9rXIJtWCda61/hTNVV4+ZN7ozoHaf5XD5bhyfJQ4rzrGx2d6kJn9CnGdc1dUELpcCIjIt8qmM0BgzN1VXDxD8ba7P5GGEVYJ13zj76uVyd4XE/rIVjsqBRcUKe4k30MyYIvvT0WE8MMefiA4UTMCEPHm2f3u7ngF63EIPcZWp6mo0uEjhEKjqauaZ/EQPVUh8Jh5jH/eN4OoMrVuRUMkJx0AdeNN8ED5+1CFWWbqVCkwFj5T3L0Uk/ifSM6GQhL+oFpfMtMQrUuOA9DxbB6bxPcZNhFc9bCzirpiey9qumP6lRyzitpYStWc6FVoXB/rw5eYxiduOiD4yubnrmOqk1g8mjlTSljUltzbw0/Kd6maMMSxuOyFpYqeoJiB9th1xn3V2gogLBObq6th0UKYyO9TitjP1nNa2ncY+m25lDCaOqsi2oZxdv0gE1+Qby+BBxDHLNoHbVAVJR4/F0Mn3Jo54+WZJFlQOayOaPFkeWdsm34s4SO8dicJd6puOOHV9szzibJeLiQtJr1EeJgP2lr3D45x37ETE4VafDtJbNClTkn50LDNy3WjUuHAIKhEqFo5xDinXdWs9u4ohcU+v7y7rGBnZXHiB1/sGowRw63vh3TevRuYIoWmM1yWESDuWQXdW24BVdbv42rN17N29vYBVh1ysM94RDcB+wE/1RuadMwxJYLSwXJlZH5jsZGiiZN3cDNhg1/zOGxQKOgTVAVLiGxz/5Lyn0kWD2n4dY+JtxLdoG0f/u4UHGrj0e+zEiIm31wiHpu/qiOoUU//Jh/VI42FE9A/h/3OHFqalJAAAAABJRU5ErkJggg=="><p>I could not believe how easy it was. Within a few weeks I had multiple calls and 2 other pilots right away, and then another the following week! It has worked out really well!</p><p>James S, KTEB</p>',
          style: {
            'color': '#000',
            'white-space': 'normal',
            'font-size': '16px'
          }
        },
        {
          html: '<img style="display: block; margin: 0 auto 24px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA2CAYAAACfkiopAAAACXBIWXMAAAsSAAALEgHS3X78AAAECUlEQVRoBcVb3W0iMRAeVilgO0g6OKggRNr3CxUcqSDkHemCtO8hFYRUEPKORK6CcB1sCS7hNNznaFm89nj9k09CQrB45vOMPT82IxKgXO7GRDTlt62nFREd+KXqSknGCUG53LFsrUcbDXQ4+AzfSxyCFkR03yFsAgt9JaJN7Ekol7spdLh1PMpyt6yHqqsP17hG4uVyNyeiJwFhk/CVqqu15+9MOrDsFwFhE5j4g80LzoiXyx0LmwfqzYJnQ62PpfUC1w7Bnaqrjen3o47AGKQ1eLZvfMnD0p9EdBVJD15+d90Pi5bA24ikCdbag4gP3iKSZsyxdE9wJN5aT7HB5H9Lx4SC3V07Bl6wfE6JY/f2tYwUC+zMEognaQBODKuJ/0ookCSEsNRiungX47bLF3CBlAIZ066rGXCdWAfGvX5TRAgZUri8KoceY22AIoO1NVyJSC4DHPUo3M9Fw5XD3VNtrl0cl1RO4uSwavJCBzhGmCKjQMYPy3de1VUI2PMK5NW5YLN4k1GPskAFk9PqfXjPKGuq17ixgkmAXourutrmNIAm/pxJnmvnzqXHf+Kqrnh9rXIJtWCda61/hTNVV4+ZN7ozoHaf5XD5bhyfJQ4rzrGx2d6kJn9CnGdc1dUELpcCIjIt8qmM0BgzN1VXDxD8ba7P5GGEVYJ13zj76uVyd4XE/rIVjsqBRcUKe4k30MyYIvvT0WE8MMefiA4UTMCEPHm2f3u7ngF63EIPcZWp6mo0uEjhEKjqauaZ/EQPVUh8Jh5jH/eN4OoMrVuRUMkJx0AdeNN8ED5+1CFWWbqVCkwFj5T3L0Uk/ifSM6GQhL+oFpfMtMQrUuOA9DxbB6bxPcZNhFc9bCzirpiey9qumP6lRyzitpYStWc6FVoXB/rw5eYxiduOiD4yubnrmOqk1g8mjlTSljUltzbw0/Kd6maMMSxuOyFpYqeoJiB9th1xn3V2gogLBObq6th0UKYyO9TitjP1nNa2ncY+m25lDCaOqsi2oZxdv0gE1+Qby+BBxDHLNoHbVAVJR4/F0Mn3Jo54+WZJFlQOayOaPFkeWdsm34s4SO8dicJd6puOOHV9szzibJeLiQtJr1EeJgP2lr3D45x37ETE4VafDtJbNClTkn50LDNy3WjUuHAIKhEqFo5xDinXdWs9u4ohcU+v7y7rGBnZXHiB1/sGowRw63vh3TevRuYIoWmM1yWESDuWQXdW24BVdbv42rN17N29vYBVh1ysM94RDcB+wE/1RuadMwxJYLSwXJlZH5jsZGiiZN3cDNhg1/zOGxQKOgTVAVLiGxz/5Lyn0kWD2n4dY+JtxLdoG0f/u4UHGrj0e+zEiIm31wiHpu/qiOoUU//Jh/VI42FE9A/h/3OHFqalJAAAAABJRU5ErkJggg=="><p>It was so easy to use- I loved making and posting a flyer. Also, my friends who flew my plane, posted comments, which helped a great deal as people viewing my listing got a sense of my plane (and me) before meeting me.</p><p>Neil B, KHPN</p>',
          style: {
            'color': '#000',
            'white-space': 'normal',
            'font-size': '16px'
          }
        },
        {
          html: '<img style="display: block; margin: 0 auto 24px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA2CAYAAACfkiopAAAACXBIWXMAAAsSAAALEgHS3X78AAAECUlEQVRoBcVb3W0iMRAeVilgO0g6OKggRNr3CxUcqSDkHemCtO8hFYRUEPKORK6CcB1sCS7hNNznaFm89nj9k09CQrB45vOMPT82IxKgXO7GRDTlt62nFREd+KXqSknGCUG53LFsrUcbDXQ4+AzfSxyCFkR03yFsAgt9JaJN7Ekol7spdLh1PMpyt6yHqqsP17hG4uVyNyeiJwFhk/CVqqu15+9MOrDsFwFhE5j4g80LzoiXyx0LmwfqzYJnQ62PpfUC1w7Bnaqrjen3o47AGKQ1eLZvfMnD0p9EdBVJD15+d90Pi5bA24ikCdbag4gP3iKSZsyxdE9wJN5aT7HB5H9Lx4SC3V07Bl6wfE6JY/f2tYwUC+zMEognaQBODKuJ/0ookCSEsNRiungX47bLF3CBlAIZ066rGXCdWAfGvX5TRAgZUri8KoceY22AIoO1NVyJSC4DHPUo3M9Fw5XD3VNtrl0cl1RO4uSwavJCBzhGmCKjQMYPy3de1VUI2PMK5NW5YLN4k1GPskAFk9PqfXjPKGuq17ixgkmAXourutrmNIAm/pxJnmvnzqXHf+Kqrnh9rXIJtWCda61/hTNVV4+ZN7ozoHaf5XD5bhyfJQ4rzrGx2d6kJn9CnGdc1dUELpcCIjIt8qmM0BgzN1VXDxD8ba7P5GGEVYJ13zj76uVyd4XE/rIVjsqBRcUKe4k30MyYIvvT0WE8MMefiA4UTMCEPHm2f3u7ngF63EIPcZWp6mo0uEjhEKjqauaZ/EQPVUh8Jh5jH/eN4OoMrVuRUMkJx0AdeNN8ED5+1CFWWbqVCkwFj5T3L0Uk/ifSM6GQhL+oFpfMtMQrUuOA9DxbB6bxPcZNhFc9bCzirpiey9qumP6lRyzitpYStWc6FVoXB/rw5eYxiduOiD4yubnrmOqk1g8mjlTSljUltzbw0/Kd6maMMSxuOyFpYqeoJiB9th1xn3V2gogLBObq6th0UKYyO9TitjP1nNa2ncY+m25lDCaOqsi2oZxdv0gE1+Qby+BBxDHLNoHbVAVJR4/F0Mn3Jo54+WZJFlQOayOaPFkeWdsm34s4SO8dicJd6puOOHV9szzibJeLiQtJr1EeJgP2lr3D45x37ETE4VafDtJbNClTkn50LDNy3WjUuHAIKhEqFo5xDinXdWs9u4ohcU+v7y7rGBnZXHiB1/sGowRw63vh3TevRuYIoWmM1yWESDuWQXdW24BVdbv42rN17N29vYBVh1ysM94RDcB+wE/1RuadMwxJYLSwXJlZH5jsZGiiZN3cDNhg1/zOGxQKOgTVAVLiGxz/5Lyn0kWD2n4dY+JtxLdoG0f/u4UHGrj0e+zEiIm31wiHpu/qiOoUU//Jh/VI42FE9A/h/3OHFqalJAAAAABJRU5ErkJggg=="><p>I was only looking to cover some expenses, but I was surprised at the amount I got per hour for my Cirrus. It made the difference in my flying!</p><p>Stratis H, KMVY</p>',
          style: {
            'color': '#000',
            'white-space': 'normal',
            'font-size': '16px'
          }
        },
        {
          html: '<img style="display: block; margin: 0 auto 24px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA2CAYAAACfkiopAAAACXBIWXMAAAsSAAALEgHS3X78AAAECUlEQVRoBcVb3W0iMRAeVilgO0g6OKggRNr3CxUcqSDkHemCtO8hFYRUEPKORK6CcB1sCS7hNNznaFm89nj9k09CQrB45vOMPT82IxKgXO7GRDTlt62nFREd+KXqSknGCUG53LFsrUcbDXQ4+AzfSxyCFkR03yFsAgt9JaJN7Ekol7spdLh1PMpyt6yHqqsP17hG4uVyNyeiJwFhk/CVqqu15+9MOrDsFwFhE5j4g80LzoiXyx0LmwfqzYJnQ62PpfUC1w7Bnaqrjen3o47AGKQ1eLZvfMnD0p9EdBVJD15+d90Pi5bA24ikCdbag4gP3iKSZsyxdE9wJN5aT7HB5H9Lx4SC3V07Bl6wfE6JY/f2tYwUC+zMEognaQBODKuJ/0ookCSEsNRiungX47bLF3CBlAIZ066rGXCdWAfGvX5TRAgZUri8KoceY22AIoO1NVyJSC4DHPUo3M9Fw5XD3VNtrl0cl1RO4uSwavJCBzhGmCKjQMYPy3de1VUI2PMK5NW5YLN4k1GPskAFk9PqfXjPKGuq17ixgkmAXourutrmNIAm/pxJnmvnzqXHf+Kqrnh9rXIJtWCda61/hTNVV4+ZN7ozoHaf5XD5bhyfJQ4rzrGx2d6kJn9CnGdc1dUELpcCIjIt8qmM0BgzN1VXDxD8ba7P5GGEVYJ13zj76uVyd4XE/rIVjsqBRcUKe4k30MyYIvvT0WE8MMefiA4UTMCEPHm2f3u7ngF63EIPcZWp6mo0uEjhEKjqauaZ/EQPVUh8Jh5jH/eN4OoMrVuRUMkJx0AdeNN8ED5+1CFWWbqVCkwFj5T3L0Uk/ifSM6GQhL+oFpfMtMQrUuOA9DxbB6bxPcZNhFc9bCzirpiey9qumP6lRyzitpYStWc6FVoXB/rw5eYxiduOiD4yubnrmOqk1g8mjlTSljUltzbw0/Kd6maMMSxuOyFpYqeoJiB9th1xn3V2gogLBObq6th0UKYyO9TitjP1nNa2ncY+m25lDCaOqsi2oZxdv0gE1+Qby+BBxDHLNoHbVAVJR4/F0Mn3Jo54+WZJFlQOayOaPFkeWdsm34s4SO8dicJd6puOOHV9szzibJeLiQtJr1EeJgP2lr3D45x37ETE4VafDtJbNClTkn50LDNy3WjUuHAIKhEqFo5xDinXdWs9u4ohcU+v7y7rGBnZXHiB1/sGowRw63vh3TevRuYIoWmM1yWESDuWQXdW24BVdbv42rN17N29vYBVh1ysM94RDcB+wE/1RuadMwxJYLSwXJlZH5jsZGiiZN3cDNhg1/zOGxQKOgTVAVLiGxz/5Lyn0kWD2n4dY+JtxLdoG0f/u4UHGrj0e+zEiIm31wiHpu/qiOoUU//Jh/VI42FE9A/h/3OHFqalJAAAAABJRU5ErkJggg=="><p>I really liked the fact that I could ask for exactly who I wanted. My plane is complex, so it was important for me to have someone with minimum time in my type and an Instrument rating. It was easy to request this. Thank you.</p><p>James M, KSDL</p>',
          style: {
            'color': '#000',
            'white-space': 'normal',
            'font-size': '16px'
          }
        }
      ],
      testimonialinit: {
        currentPage: 0,
        thresholdDistance: 100,
        thresholdTime: 100,
        autoplay: 6000,
        loop: true,
        direction: 'horizontal',
        infinite: 1,
        slidesToScroll: 1,
        timingFunction: 'ease',
        duration: 300
      },
      popularLocations: [
        { name: 'Alaska', abbr: 'AK' },
        { name: 'Arizona', abbr: 'AZ' },
        { name: 'California', abbr: 'CA' },
        { name: 'Connecticut', abbr: 'CT' },
        { name: 'Florida', abbr: 'FL' },
        { name: 'Oklahoma', abbr: 'OK' },
        { name: 'Texas', abbr: 'TX' }
      ]
    };
  },

  methods: {
    slide(data) {
      // console.log(data)
    },
    onTap(data) {
      // console.log(data)
    },
    onInit(data) {
      // console.log(data)
    },
    slideNext() {
      this.$refs.slider.$emit('slideNext');
    },
    slidePre() {
      this.$refs.slider.$emit('slidePre');
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background: $sma-dark-blue;
  color: $sma-white;
  position: relative;

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 0 0.5rem;
  }

  &::before {
    content: '';
    height: 50px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    background:
      linear-gradient(
        rgba(
          255,
          255,
          255,
          0
        ),
        #fff
      );
  }

  .testimonials {
    background: #fff url('~@/assets/testimonial-bg.jpg') top/100% auto no-repeat;
    position: relative;

    .container {
      position: relative;
    }

    .slider-container {
      width: 300px;
      position: absolute;
      top: 0;
      right: 10%;

      @media (min-width: $breakpoint-md) {
        width: 500px;
      }
    }

    .pre,
    .next {
      display: block;
      width: 13px;
      cursor: pointer;
      position: absolute;
      top: 50%;
    }

    .pre {
      background-image: url('~@/assets/pre.png');
      height: 33px;
      right: calc(10% + 320px);

      @media (min-width: $breakpoint-md) {
        right: calc(10% + 520px);
      }
    }

    .next {
      background-image: url('~@/assets/next.png');
      height: 35px;
      right: calc(10% - 25px);
    }
  }

  .copy {
    padding: 0.5em 0;

    & a,
    & p {
      color: $sma-light-gray;
      transition: color 0.3s;
      font-size: 0.8rem;
    }

    & a:hover {
      color: $sma-blue;
    }

    .credit {
      float: right;
    }
  }

  .top {
    padding: 2em 0;

    .col a {
      display: flex;
      align-items: center;
      justify-content: left;
    }

    & img {
      max-height: 64px;
      max-width: 64px;
      margin: 0 1em 0 0;
    }

    & h1 {
      font-size: 36px;
      color: $sma-white;
    }
  }

  .bottom {
    padding: 2em 0;

    .col {
      & a {
        display: block;
        color: $sma-white;
        margin: 1em 0;
        transition: color 0.3s;

        &:hover {
          color: $sma-blue;
        }
      }

      & h2 {
        transition: color 0.3s;
      }
    }

    .social {
      display: flex;

      .linkedin {
        background: url('~@/assets/social.png');
        background-size: 400%;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
        }
      }

      .facebook {
        background: url('~@/assets/social.png');
        background-size: 400%;
        background-position: -100% 0;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
          background-position: -100% 0;
        }
      }

      .twitter {
        background: url('~@/assets/social.png');
        background-size: 400%;
        background-position: -200% 0;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
          background-position: -200% 0;
        }
      }

      .instagram {
        background: url('~@/assets/social.png');
        background-size: 400%;
        background-position: -300% 0;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
          background-position: -300% 0;
        }
      }
    }
  }
}
</style>
