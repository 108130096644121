<template>
  <div
    v-show="show"
    class="generic-modal modal-background"
    @click="$emit('close', false);"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="$emit('close', null);"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content">
        <p><slot /></p>
        <div>
          <button
            v-for="button in buttons"
            :key="button"
            class="btn yellow"
            @click="$emit('close', button);"
          >
            {{ button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SmaDialogModal',

  props: {
    show: { type: Boolean, default: false },
    message: { type: String, default: '' },
    buttons: { type: Array, default: () => [] }
  },

  computed: {
    ...mapGetters([
      'activeModalName'
    ])
  }

};
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  padding: 0.25rem 4rem;
  margin: 0.5rem 0;
  border-radius: 10rem;
  white-space: nowrap;
  border: 1px solid $sma-yellow;
  text-align: center;

  /*
  &:hover {
    background-color: $sma-yellow;
    color: $sma-white;
  }
  */
}
</style>
