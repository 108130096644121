import fetchApi from './fetch-api';

export default async function deleteShare(id) {
  const response = await fetchApi({
    method: 'DELETE',
    path: `/share/${id}`
  });

  const share = response.data;

  return {
    id: share.id
  };
}
