const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function getEmailErrors(email) {
  const errors = [];

  if (!email) {
    errors.push({
      prop: 'email',
      message: 'An email address is required'
    });
  } else if (!EMAIL_REGEX.test(email)) {
    errors.push({
      prop: 'email',
      message: 'Please enter a valid email address'
    });
  }

  return errors;
}

export function getAddressErrors(address) {
  const errors = [];

  if (!address) {
    errors.push({
      prop: 'address',
      message: 'A business address is required'
    });
  }

  return errors;
}

export function getUsernameErrors(username) {
  const errors = [];

  if (!username) {
    errors.push({
      prop: 'username',
      message: 'A username is required'
    });
  } else if (!/^[A-Za-z0-9_-]+$/.test(username)) {
    errors.push({
      prop: 'username',
      message:
        'Usernames may only contain letters, numbers, underscores, and dashes'
    });
  }

  return errors;
}

export function getPasswordErrors(password) {
  const errors = [];

  if (!password) {
    errors.push({
      prop: 'password',
      message: 'A password is required'
    });
  } else if (password.length < 8) {
    errors.push({
      prop: 'password',
      message: 'Passwords must be at least 8 characters long'
    });
  }

  return errors;
}

export function getConfirmedPasswordErrors(password, cpassword) {
  const errors = [];

  if (password && password !== cpassword) {
    errors.push({
      prop: 'cpassword',
      message: 'The passwords do not match'
    });
  }

  return errors;
}

export function getRateErrors(rate) {
  const errors = [];

  if (!rate) {
    errors.push({
      prop: 'rate',
      message: 'Please enter a rate'
    });
  } else if (!/[\d]+/.test(rate)) {
    errors.push({
      prop: 'rate',
      message: 'Rate must be a positive integer'
    });
  } else if (parseInt(rate) > Number.MAX_SAFE_INTEGER) {
    errors.push({
      prop: 'rate',
      message: `Rate cannot be greater than ${Number.MAX_SAFE_INTEGER}`
    });
  }

  return errors;
}

export function getTagsErrors(tags) {
  const errors = [];

  if (!Array.isArray(tags)) {
    errors.push({
      prop: 'tags',
      message: 'Tags must be an array of strings'
    });
  } else if (tags.length === 0) {
    errors.push({
      prop: 'tags',
      message: 'Please select at least one tag'
    });
  }

  return errors;
}

export function getAvatarErrors(avatar) {
  const errors = [];

  if (!avatar) {
    errors.push({
      prop: 'avatar',
      message: 'Please select an avatar'
    });
  }

  return errors;
}

export function getAirportErrors(airport) {
  const errors = [];

  if (!airport) {
    errors.push({
      prop: 'airport',
      message: 'Please select an airport'
    });
  }

  return errors;
}

export function getAboutErrors(about) {
  const errors = [];

  if (!about) {
    errors.push({
      prop: 'about',
      message: 'Please fill in the about section'
    });
  }

  return errors;
}

export function getBusinessNameErrors(businessName) {
  const errors = [];

  // if (!businessName) {
  //   errors.push({
  //     prop: 'about',
  //     message: 'Please enter a business name'
  //   });
  // }

  return errors;
}

export function getRegOwnerErrors(regOwner) {
  const errors = [];

  if (!regOwner) {
    errors.push({
      prop: 'about',
      message: "Please fill in the aircraft's registered owner"
    });
  }

  return errors;
}

export function getTailNumberErrors(tailNumber) {
  const errors = [];

  if (!tailNumber) {
    errors.push({
      prop: 'tailNumber',
      message: "Please enter your aircraft's tail number"
    });
  }

  return errors;
}

export function getTitleErrors(title) {
  const errors = [];

  if (!title) {
    errors.push({
      prop: 'about',
      message: 'Please add a title'
    });
  }

  return errors;
}

export function getDescriptionErrors(desc) {
  const errors = [];

  if (!desc) {
    errors.push({
      prop: 'desc',
      message: 'Please add a description'
    });
  }

  return errors;
}

export function getMakeErrors(make) {
  const errors = [];

  if (!make) {
    errors.push({
      prop: 'make',
      message: 'Please add a make'
    });
  }

  return errors;
}

export function getModelErrors(model) {
  const errors = [];

  if (!model) {
    errors.push({
      prop: 'model',
      message: 'Please add a model'
    });
  }

  return errors;
}

export function getYearErrors(year) {
  const errors = [];

  if (!year) {
    errors.push({
      prop: 'year',
      message: 'Please add a year'
    });
  } else if (!/[\d]+/.test(year)) {
    errors.push({
      prop: 'year',
      message: 'Year must be a positive integer'
    });
  } else if (parseInt(year) > new Date().getFullYear()) {
    errors.push({
      prop: 'year',
      message: 'Year cannot be in the future'
    });
  }

  return errors;
}

export function getEngineHoursErrors(hours) {
  const errors = [];

  if (!hours) {
    errors.push({
      prop: 'hours',
      message: 'Please enter engine hours'
    });
  } else if (!/[\d]+/.test(hours)) {
    errors.push({
      prop: 'hours',
      message: 'Engine hours must be a positive integer'
    });
  } else if (parseInt(hours) > Number.MAX_SAFE_INTEGER) {
    errors.push({
      prop: 'hours',
      message: `Engine hours cannot be greater than ${Number.MAX_SAFE_INTEGER}`
    });
  }

  return errors;
}

export function getEngineManufacturerErrors(manufacturer) {
  const errors = [];

  if (!manufacturer) {
    errors.push({
      prop: 'manufacturer',
      message: 'Please add an engine manufacturer'
    });
  }

  return errors;
}

export function getEngineModelErrors(model) {
  const errors = [];

  if (!model) {
    errors.push({
      prop: 'model',
      message: 'Please add an engine model'
    });
  }

  return errors;
}

export function getClassificationErrors(classification) {
  const errors = [];

  if (!classification) {
    errors.push({
      prop: 'classification',
      message: 'Please add a classification'
    });
  }

  return errors;
}

export function getCategoriesErrors(categories) {
  const errors = [];

  if (!categories) {
    errors.push({
      prop: 'categories',
      message: 'Please add categories'
    });
  }

  return errors;
}

export function getEquipmentErrors(equipment) {
  const errors = [];

  if (!equipment) {
    errors.push({
      prop: 'equipment',
      message: 'Please add an equipment description'
    });
  }

  return errors;
}

export function getExteriorErrors(exterior) {
  const errors = [];

  if (!exterior) {
    errors.push({
      prop: 'exterior',
      message: 'Please add an exterior description'
    });
  }

  return errors;
}

export function getInteriorErrors(interior) {
  const errors = [];

  if (!interior) {
    errors.push({
      prop: 'interior',
      message: 'Please add an interior description'
    });
  }

  return errors;
}

export function getCommentsErrors() {
  const errors = [];

  return errors;
}

export function getDetailsErrors() {
  const errors = [];

  return errors;
}

export function getMediaErrors(media) {
  const errors = [];

  if (!media || media.length === 0) {
    errors.push({
      prop: 'media',
      message: 'Please add media'
    });
  } else if (media.filter(item => item.type === 'image').length === 0) {
    errors.push({
      prop: 'media',
      message: 'Media must contain at least one image'
    });
  }

  return errors;
}

export function getDefaultMediaErrors(defaultMedia) {
  const errors = [];

  if (defaultMedia === null) {
    errors.push({
      prop: 'defaultMedia',
      message: 'Please select a default image'
    });
  }

  return errors;
}

export function getCostErrors(cost) {
  const errors = [];

  if (!cost) {
    errors.push({
      prop: 'cost',
      message: 'Please add a cost'
    });
  }

  if (cost < 0) {
    errors.push({
      prop: 'cost',
      message: 'Cost must be positive'
    });
  }

  return errors;
}

export function getDesiredNumberOfPilotsErrors(desiredNumberOfPilots) {
  const errors = [];

  if (!desiredNumberOfPilots) {
    errors.push({
      prop: 'desiredNumberOfPilots',
      message: 'Please add a desired number of pilots'
    });
  }

  if (desiredNumberOfPilots < 0) {
    errors.push({
      prop: 'desiredNumberOfPilots',
      message: 'Desired number of pilots must be positive'
    });
  }

  return errors;
}

export function getSharePercentOfferedErrors(sharePercentOffered) {
  const errors = [];

  if (!sharePercentOffered) {
    errors.push({
      prop: 'sharePercentOffered',
      message: 'Please add a share percent offered'
    });
  }

  if (sharePercentOffered.match(/[1-9]+\/[1-9]+/)) {
    errors.push({
      prop: 'sharePercentOffered',
      message: 'Share percent offered must be in the form #/#'
    });
  }

  return errors;
}

export function getPricePercentOfferedErrors(pricePercentOffered) {
  const errors = [];

  if (!pricePercentOffered) {
    errors.push({
      prop: 'pricePercentOffered',
      message: 'Please add a price percent offered'
    });
  }

  if (pricePercentOffered < 0) {
    errors.push({
      prop: 'pricePercentOffered',
      message: 'Price percent offered must be positive'
    });
  }

  return errors;
}

export function getRecaptchaError(code) {
  const errors = [];

  if (!code) {
    errors.push({
      prop: 'code',
      message: 'Must verify with reCAPTCHA'
    });
  }

  return errors;
}

export function getClubNameErrors(clubName) {
  const errors = [];

  if (!clubName) {
    errors.push({
      prop: 'clubName',
      message: 'Please add a club name'
    });
  }

  return errors;
}

export function getClubRepNameErrors(clubRepName) {
  const errors = [];

  if (!clubRepName) {
    errors.push({
      prop: 'clubRepName',
      message: 'Please add a club representative name'
    });
  }

  return errors;
}

export function getClubMemberSizeErrors(clubMemberSize) {
  const errors = [];

  if (clubMemberSize < 0) {
    try {
      errors.push({
        prop: 'clubMemberSize',
        message: 'Club member size cannot be negative'
      });
    } catch (err) {
      errors.push({
        prop: 'clubMemberSize',
        message: 'Club member size must be a number'
      });
    }
  }

  return errors;
}

export function getClubNumAircraftsErrors(clubNumAircrafts) {
  const errors = [];

  if (clubNumAircrafts < 0) {
    try {
      errors.push({
        prop: 'clubNumAircrafts',
        message: 'Number of aircrafts cannot be negative'
      });
    } catch (err) {
      errors.push({
        prop: 'clubNumAircrafts',
        message: 'Number of aircrafts must be a number'
      });
    }
  }

  return errors;
}

export function getClubWebsiteUrlErrors(clubWebsiteUrl) {
  const errors = [];

  if (!clubWebsiteUrl) {
    errors.push({
      prop: 'clubWebsiteUrl',
      message: 'Please add a club website url'
    });
  }

  return errors;
}

export function getClubEmailErrors(clubEmail) {
  const errors = [];

  if (!clubEmail) {
    errors.push({
      prop: 'clubEmail',
      message: 'A club email address is required'
    });
  } else if (!EMAIL_REGEX.test(clubEmail)) {
    errors.push({
      prop: 'clubEmail',
      message: 'Please enter a valid email address'
    });
  }

  return errors;
}

export function getClubPhoneErrors(clubPhone) {
  const errors = [];

  if (!clubPhone) {
    errors.push({
      prop: 'clubPhone',
      message: 'Please add a club phone number'
    });
  }

  return errors;
}

export function getUrlErrors(url) {
  const errors = [];

  if (url) {
    try {
      new URL(url); // eslint-disable-line no-new
    } catch (err) {
      errors.push({
        prop: 'url',
        message: 'Malformed URL'
      });
    }
  }

  return errors;
}
