<template>
  <div class="cirrus-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Cirrus partnership</h1>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <div class="row">
            <div class="col col-sm-8">
              <img
                src="@/assets/cirrus_partner_logo.png"
                class="shrink"
              >

              <form
                action="https://cirrusaircraft.com/"
                method="get"
                target="_blank"
              >
                <button
                  class="btn blue"
                >
                  VISIT CIRRUS
                </button>
              </form>
            </div>
            <div class="col col-sm-16">
              <p>Sharemyaircraft.com is happy to announce that we are working with Cirrus Aircraft to help form partnerships to buy a new or used Cirrus Aircraft...at your local airport. Starting in the Northeast, and through the use of our intelligent platform, we identify pilots who are interested in joining other pilots in a co-owner partnership, and then In addition to finding like-minded pilots, Sharemyaircraft.com will assist in helping the partnership obtain:</p>
              <p>
                <ul>
                  <li>Financing</li>
                  <li>Insurance</li>
                  <li>Training (CSIP and other)</li>
                  <li>Legal Co-owner Partnership Documents</li>
                </ul>
              </p>
              <p>By sharing an airplane, a pilot can enjoy all the benefits of single ownership with significantly reduced expenses, form camaraderie with other like-minded pilots, and own an airplane for a fraction of what it would normally cost.</p>
              <p>The key to a successful partnership is finding like-minded pilots with similar goals and we can help! But it does not stop there, once we find a group of pilots interested in purchasing a new plane, we will help you with the financing, insurance training requirements and even provide you with initial legal documents that can be modified to your specific needs.  In addition, through our partnership with COFLYT.COM, managing your new partnership is easier than ever!  Know exactly when a plane is scheduled, needs maintenance, has squawks, and communicates seamlessly with the other owners, all from the palm of your hand!</p>

              <router-link
                class="btn blue"
                :to=" { name: 'coflyt' }"
              >
                LEARN MORE
              </router-link>

              <p>The Cirrus is an incredible airplane. Multiple principles of Sharemyaircraft.com fly this beautiful plane and we are super excited to help more pilots meet others and get into this incredible plane for a fraction of what it would cost to own it alone!</p>
              <p>Please contact us directly if you have any questions Ryan is a Cirrus preferred Insurance broker and has the ability to work with many insurance shops finding you the best policy for your new or used plane purchase! (<a href="mailto:rkonrath@wingsinsurance.com">support@sharemyaicraft.com</a>), and look for a Cirrus Share at an airport near you!</p>
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-8">
              <!-- spacer -->
            </div>
            <div class="col col-sm-12">
              <div class="videos">
                <a
                  class="video"
                  @click="video(videoCirrus)"
                >
                  <span />
                  <img
                    src="https://i3.ytimg.com/vi/ko4AqKctWAU/maxresdefault.jpg"
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHOW_MODAL } from '@/store/action-types';

export default {
  name: 'SmaCirrus',

  components: {
    // SmaSidebar
  },

  data() {
    return {
      videoCirrus: 'https://www.youtube.com/embed/u5I06lktjkM?autoplay=1'
    };
  },

  methods: {
    video(btnId) {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'video',
        videoURL: btnId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.shrink {
  width: 90%;
  float: left;
  margin-top: 1.2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-weight: bold;
}

.btn {
  font-weight: bold;
  position: relative;
  // width: 50%;
  left: 25%;
  // margin-left: 12rem;
  margin-top: 4rem;
}

.videos img {
  width: 90%;
  height: auto;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

a.video {
  float: left;
  position: relative;
}

a.video span {
  width: 90%;
  height: 90%;
  position: absolute;
  background: url(https://img.icons8.com/color/480/000000/youtube-play.png) no-repeat;
  background-position: 50% 45%;
  background-size: 20%;
}

@media screen and (max-width: 480px) {
  a.video span {
    background-size: 20%;
  }
}
</style>
