<template>
  <div class="announcements-page info-page">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <h1>Announcements</h1>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <div class="row">
            <div class="col col-sm-4">
              <img
                src="@/assets/coflyt_logo.png"
                class="shrink"
              >
            </div>
            <div class="col col-sm-14">
              <p>Coflyt® is intelligent aircraft software that combines inspections and maintenance tracking, scheduling, and billing into a simple app, providing peace of mind for aircraft owners. Know exactly when aircraft maintenance is needed, streamline communication, and ensure compliance – all from the palm of your hand.</p>

              <router-link
                class="btn blue"
                :to=" { name: 'coflyt' }"
              >
                LEARN MORE
              </router-link>
            </div>

            <div class="col col-sm-1">
              <!-- spacer -->
            </div>
            <div class="col col-sm-5">
              <sma-sidebar />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-4">
              <img
                src="@/assets/cirrus_partner_logo.png"
                class="shrink"
              >
            </div>
            <div class="col col-sm-14">
              <p>We are working with Cirrus Aircraft to form partnerships for like-minded pilots at YOUR local airport. Set a listing alert at your favorites airports to be notified when an SR22, SR20 or Vision Jet partnership is being formed and own the best selling GA aircraft for a fraction of buying it alone! Co-Ownership with great pilots...it just makes sense!</p>

              <router-link
                class="btn blue"
                :to=" { name: 'cirrus' }"
              >
                LEARN MORE
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmaSidebar from '@/components/Sidebar';

export default {
  name: 'SmaAnnouncements',

  components: {
    SmaSidebar
  }
};
</script>

<style lang="scss" scoped>
.shrink {
  width: 100%;
  float: left;
  margin-top: 1.2rem;
  margin-right: 1rem;
  font-weight: bold;
}

.btn {
  font-weight: bold;
}
</style>
