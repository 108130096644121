import axios from './axios';

export default async function fetchPostPreviews({ page = 1, perPage = 20 } = {}) {
  const response = await axios({
    method: 'GET',
    url: 'wp/v2/posts/',
    params: {
      page,
      per_page: perPage,
      _fields: 'id,title,date,link,slug,_links.wp:featuredmedia',
      _embed: 'wp:featuredmedia'
    }
  });

  return {
    postPreviews: response.data,
    total: parseInt(response.headers['x-wp-total']),
    totalPages: parseInt(response.headers['x-wp-totalpages'])
  };
}
