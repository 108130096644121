import fetchApi from './fetch-api';

export default async function updateUserProfile(options) {
  const data = new FormData();
  const { avatar } = options;

  data.append('user', JSON.stringify(options));

  if (avatar) {
    data.append('avatar', avatar.file);
  }

  const response = await fetchApi({
    method: 'PATCH',
    path: '/user',
    data
  });

  return response.data;
}
