<template>
  <div class="post-location-page">
    <div class="container">
      <div class="row">
        <sma-progress
          class="col"
          :step="3"
          :steps="steps"
        />
      </div>
      <div class="row form">
        <div class="col">
          <h3>Preferred Flight Instructor (optional)</h3>
          <p>If you have a preferred flight instructor that you work with, please put down his/her name and email address here. This would be the person that a new Sharer would contact to get “signed off” on your plane (after you both have met and are in agreement on terms ) and this would be the person that confirms logbooks and experience etc. When you add a flight instructor, they will get an email letting them know you have added them to SMA, and they will have an ability to update their profile with a picture and additional information about themselves.</p>
        </div>
        <div class="col col-sm-12">
          <label
            class="label"
            for="make"
          >Name</label>
          <input
            id="make"
            v-model="form.preferredInstructorName"
            class="input text"
          >
        </div>
        <div class="col col-sm-12">
          <label
            class="label"
            for="make"
          >Email</label>
          <input
            id="make"
            v-model="form.preferredInstructorEmail"
            class="input text"
          >
        </div>
      </div>
      <div class="fail">
        <ul>
          <li
            v-for="(error,i) in errors"
            :key="i"
          >
            {{ error.message }}
          </li>
        </ul>
      </div>
      <div class="row form">
        <div class="col col-sm-12">
          <button
            class="btn yellow inv"
            @click="back"
          >
            Previous step
          </button>
        </div>
        <div class="col col-sm-12">
          <button
            class="btn yellow"
            @click="submit"
          >
            Next step
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

import SmaProgress from '@/components/Progress';
import { UPDATE_POST_IN_PROGRESS } from '@/store/action-types';

export default {
  name: 'SmaPostLocation',

  components: {
    SmaProgress
  },

  data() {
    return {
      steps: [
        ['General', 'Information'],
        ['Your plane', 'location'],
        ['Add Photos', '& Video'],
        ['Preferred Flight', 'Instructor'],
        ['Preview &', 'Confirm']
      ],
      form: {
        preferredInstructorName: '',
        preferredInstructorEmail: ''
      },
      errors: []
    };
  },

  created() {
    if (!isEmpty(this.$store.state.post.inProgress)) {
      Object.assign(this.form, this.$store.state.post.inProgress);
    } else {
      // this.$router.replace({ name: 'post' });
    }
  },

  methods: {
    back() {
      this.$router.replace({
        name: 'post-media'
      });
    },

    getErrors() {
      return [].concat(
      );
    },

    submit() {
      this.errors = this.getErrors();
      if (this.errors.length > 0) {
        return;
      }

      this.$store.dispatch(UPDATE_POST_IN_PROGRESS, this.form);

      this.$router.push({
        name: 'post-confirm'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.post-location-page {
  padding: 2rem;
}

.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  .input {
    border: 1px solid $sma-light-gray;
    border-radius: 0.25rem;

    &.text {
      padding: 0.25rem 0.5rem;
      font-weight: 600;

      &:focus {
        border-color: $sma-blue;
      }
    }
  }

  .button {
    align-self: center;
    margin-top: 5rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}
</style>
