<template>
  <div class="team-page info-page">
    <div class="container">
      <h1>Our Team</h1>
      <div class="team-members">
        <h2>Team Members</h2>
        <div class="row members">
          <!-- Raj Bhatia -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_raj.png">
            </div>
            <div class="member-name">
              Raj Bhatia
            </div>
            <div class="member-title">
              Founder
            </div>
            <div class="member-blurb">
              <p>Instrument-rated air and seaplane pilot</p>
              <p>Lehigh EE / Columbia Business School</p>
              <p>IBM / Real Estate Investment / Development/ Serial Entrepreneur</p>
              <p>Passionate about Flying, Golf, Piano and Sailing with Jill and our two boys</p>
              <p>Favorite flights: Katama Airpark, Martha’s Vineyard, Anywhere in my Husky</p>
            </div>
          </div>

          <!-- John Dayani -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_john.png">
            </div>
            <div class="member-name">
              John Dayani
            </div>
            <div class="member-title">
              President
            </div>
            <div class="member-blurb">
              <p>Instrument-rated Single Engine Land</p>
              <p>Vanderbilt EE / MBA</p>
              <p>Serial Entrepreneur / Technologist / Advisor / Strategy</p>
              <p>Married with 3 Boys</p>
              <p>Favorite flights: Over the Tampa Bay Bridge to Sarasota</p>
            </div>
          </div>

          <!-- Jack McGrann -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_jack.jpeg">
            </div>
            <div class="member-name">
              Jack McGrann
            </div>
            <div class="member-title">
              Co-Founder
            </div>
            <div class="member-blurb">
              <p>Instrument-Rated and Multi Engine Certified Flight Instructor with 1700 Flight Hours</p>
              <p>Masters Organizational Leadership Kansas University</p>
              <p>Bachelor of Science in Aviation Henderson State University</p>
              <p>Jack is a veteran, a retired Army Lieutenant Colonel, and UH-60 (Black Hawk) Helicopter Pilot who served in Iraq.</p>
              <p>Favorite flights: Landing at Stearman Field (1K1), Benton,KS or Beaumont, KS (07S) for lunch.</p>
            </div>
          </div>

          <!-- Jill Bhatia -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_jill.png">
            </div>
            <div class="member-name">
              Jill Sloane Bhatia
            </div>
            <div class="member-title">
              Co-Founder
            </div>
            <div class="member-blurb">
              <p>Co-pilot in the air and on the ground</p>
              <p>One of Manhattan’s top real estate agents for Brown Harris Stevens</p>
              <p>CT Real Estate Developer creating unique habitats in nature</p>
              <p>A CT farm girl with Willow Creek Equestrian Center, having 5 Goats, 2 Mini Donkeys and multiple barn cats</p>
              <p>Favorite Flights: Katama Airpark, Bennington Vermont and anywhere with Raj!</p>
            </div>
          </div>

          <!-- Nathan Berger -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_nate.jpg">
            </div>
            <div class="member-name">
              Nathan Berger
            </div>
            <div class="member-title">
              CTO
            </div>
            <div class="member-blurb">
              <p>Technology entrepreneur and creative agency founder</p>
              <p>User Experience (UX) and User Interface (UI) specialist</p>
              <p>Focus on ecommerce and customer engagement</p>
              <p>Masters in eMedia</p>
              <p>Favorite airport: TWEED in New Haven, CT</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row team-advisors">
        <h2>Advisors</h2>
        <div class="row members">
          <!-- Christian Bailey -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_christian.png">
            </div>
            <div class="member-name">
              Christian Bailey
            </div>
            <div class="member-blurb">
              <p>Certified Commercial pilot and Flight Instructor in Fixed-Wing and Helicopter</p>
              <p>University of Oxford / Fellow at MIT.</p>
              <p>Founder and Chief Scientist at Curated Innovations, an MIT-Fueled Invention Lab</p>
              <p>Favorite Flights: Landing my Robinson R 44 off-airport across New England at hotels, inns, and restaurants, and flying my experimental Berkut out of Boston</p>
            </div>
          </div>

          <!-- Kevin Rustagi -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_kevin.png">
            </div>
            <div class="member-name">
              Kevin Rustagi
            </div>
            <div class="member-blurb">
              <p>Private Pilot (280 hours, PPL)</p>
              <p>Degrees: MIT Mech Engineering, Stanford (MBA + Master in Mech Engineering)</p>
              <p>Apple (iPhone 5 speaker grille) / Ministry of Supply Co-Founder (Kickstarter Record)</p>
              <p>Lead Messaging/Strategy Consultant with Brandt &amp; Co.</p>
              <p>Favorite flights: SF Bay Area to Grand Canyon, Sunset over Lake Travis in Austin</p>
            </div>
          </div>

          <!-- Toby Cunningham -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_toby.png">
            </div>
            <div class="member-name">
              Toby Cunningham
            </div>
            <div class="member-blurb">
              <p>Product Development</p>
              <p>Wheels Up/Travelocity/Sabre</p>
              <p>U of Texas Fin. + Mktg / SMU MBA</p>
            </div>
          </div>

          <!-- Tal Clark -->
          <div class="col col-sm-8 member">
            <div class="member-avatar">
              <img src="@/assets/sma_tal.png">
            </div>
            <div class="member-name">
              Tal Clark
            </div>
            <div class="member-blurb">
              <p>Coflyt Founder - Leading aircraft management solution</p>
              <p>Retired Marine Captain</p>
              <p>Miss. State BS / U. of San Fran MBA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmaOurTeam'
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  width: 100%;
}

.member-avatar {
  position: relative;
  padding-top: (9 / 16) * 100%;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.member-name {
  color: $sma-blue;
  font-weight: 500;
  font-size: 22px;
}

.member-title {
  font-style: italic;
}

.member-blurb p {
  line-height: 1;
}
</style>
