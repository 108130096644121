import fetchApi from './fetch-api';

export default async function createSubscription({ priceId, paymentMethodId }) {
  await fetchApi({
    method: 'POST',
    path: '/user/subscription',
    json: {
      priceId,
      paymentMethodId
    }
  });
}
